import React, { useEffect, useState } from "react";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import { SEX_SELECT, ENROLLMENT_STATUS_SELECT } from '../../../../../constants/system/dataSelect';
import {classList} from "../../../../../utils/DOM/class";
import { DatePickerField, Input, Select } from "../../../../../_theme/_partials/controls";
import {SelectionSearch} from "../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {
  COUNTRY_RESOURCE,
  DEGREE_RESOURCE,
  PROGRAM_RESOURCE,
  ENROLLMENT_STATUS, CLASS_SCHEDULE_RESOURCE
} from "../../../../../constants/system/resource";
import {TextArea} from "../../../../../_theme/_partials/controls/forms/TextArea";
import * as requestFromServer from '../../../common/DataTable/_redux/entitiesCrud';

// Validation schema
const EditSchema = Yup.object().shape({
  name: Yup.string()
    .required("Tên là bắt buộc"),
  degree: Yup.string()
    .nullable()
    .required("Trình độ bắt buộc"),
  expire_time: Yup.string()
    .required("Hạn hợp đồng là bắt buộc"),
  nationality: Yup.string()
    .nullable()
    .required("Quốc tịch là bắt buộc"),
  email: Yup.string()
    .required("Email là bắt buộc")
    .email("Email sai định dạng"),
  programs: Yup.string()
    .nullable()
    .required("Chương trình có thể dạy là bắt buộc"),
  enrollment_status: Yup.string()
    .nullable()
    .required("Hình thức dạy là bắt buộc"),
  phone: Yup.string()
    .nullable()
    .min(7, "Số điện thoại ít nhất 7 chữ số")
    .max(15, "Số điện thoại nhiều nhất 15 chữ số"),
  teacher_certificate: Yup.string()
    .nullable()
    .required("Chứng chỉ sư phạm là bắt buộc"),
  specialized_certificate: Yup.string()
    .nullable()
    .required("Chứng chỉ chuyên ngành là bắt buộc"),
  max_hour_per_week: Yup.number()
    .nullable()
    .min(1, "Số giờ tối thiểu là 1 giờ")
    .required("Số giờ tối đa dạy/tuần là bắt buộc"),
  num_year_experience: Yup.number()
    .nullable()
    .min(0, "Số năm kinh nghiệm không được nhò hơn 0")
    .required("Số năm kinh nghiệm là bắt buộc")
});


export function TeacherEditForm({save, item, actionsLoading, onHide}) {
  const [enrollmentStatus, setEnrollmentStatus] = useState([]);

  // useEffect(() => {
  //   requestFromServer
  //     .get(ENROLLMENT_STATUS)
  //     .then(rs => {
  //       console.log(rs);
  //     })
  // }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        onSubmit={values => {
          const entity = {
            ...values,
            degree_code: values.degree.code,
            nationality: values.nationality.nationality,
            program_ids: values.programs.map(p => p.id)
          }
          save(entity);
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-sm-6">
                    <Field
                      name="name"
                      component={Input}
                      required
                      placeholder="Nhập tên"
                      label="Tên"
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="nationality"
                      required
                      component={SelectionSearch}
                      resource={COUNTRY_RESOURCE}
                      propertyName="nationality"
                      propertyValue="nationality"
                      placeholder="Chọn quốc tịch"
                      label="Quốc tịch"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="email"
                      component={Input}
                      required
                      placeholder="Nhập email vd: abc@gmail.com"
                      label="Email"
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="phone"
                      component={Input}
                      placeholder="Nhập sdt vd: 092xxxxxxx"
                      label="Số điện thoại"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12 col-md-4">
                    <Field
                      name="degree"
                      required
                      component={SelectionSearch}
                      resource={DEGREE_RESOURCE}
                      propertyName="name"
                      propertyValue="code"
                      placeholder="Chọn trình độ"
                      label="Trình độ"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <Field
                      name="programs"
                      required
                      component={SelectionSearch}
                      resource={PROGRAM_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      placeholder="Chọn chương trình"
                      label="Chương trình có thể dạy"
                      isMulti={true}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <DatePickerField
                      required
                      name="expire_time"
                      label="Hạn hợp đồng"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12 col-md-4">
                    <Field
                      name="num_year_experience"
                      type="number"
                      component={Input}
                      required
                      placeholder="Nhập số năm kinh nghiệm"
                      label="Số năm kinh nghiệm"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <Field
                      name="max_hour_per_week"
                      type="number"
                      component={Input}
                      required
                      placeholder="Nhập số giờ dạy"
                      label="Giờ dạy tối đa/tuần"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <Field
                      name="enrollment_status"
                      component={Select}
                      placeholder="Chọn hình thức dạy"
                      label="Hình thức dạy"
                      required
                      options={ENROLLMENT_STATUS_SELECT}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="other_contact"
                      component={TextArea}
                      placeholder="Nhập hình thức liên lạc khác"
                      label="Hình thức liên lạc khác"
                      rows="1"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="teacher_certificate"
                      component={TextArea}
                      required
                      placeholder="Nhập chứng chỉ sư phạm"
                      label="Chứng chỉ sư phạm"
                      rows="1"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="specialized_certificate"
                      component={TextArea}
                      required
                      placeholder="Nhập chứng chỉ chuyên ngành"
                      label="Chứng chỉ chuyên ngành"
                      rows="1"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
