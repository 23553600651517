import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {LoadingDialog as LoadingDialogControl} from "../../../../../../_theme/_partials/controls";

export function LoadingDialog() {
  const { isLoading } = useSelector(
    state => ({ isLoading: state.products.listLoading }),
    shallowEqual
  );
  useEffect(() => {}, [isLoading]);
  return <LoadingDialogControl isLoading={isLoading} text="Loading ..." />;
}
