import React from "react";
import {Modal} from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {classList} from "../../../../../../../utils/DOM/class";
import { DatePickerField } from "../../../../../../../_theme/_partials/controls";
import {SelectionSearch} from "../../../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {
  CANDIDATE_RESOURCE,
} from "../../../../../../../constants/system/resource";
import {TextArea} from "../../../../../../../_theme/_partials/controls/forms/TextArea";

// Validation schema
const EditSchema = Yup.object().shape({
  candidate: Yup.string()
    .nullable()
    .required("Bạn chưa chọn ứng viên"),
  start_time: Yup.string()
    .nullable()
    .required("Thời gian bắt đầu là bắt buộc"),
});

export function SignUp2LearnForm({save, item, actionsLoading, onHide}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        onSubmit={(values, formikHelpers) => {
          const entity = {
            ...values,
            candidate_id: item.id,
          }

          if(values.start_time !== item.start_time)
            entity.start_time = values.start_time;

          save(entity, formikHelpers);
        }}
      >
        {({handleSubmit, values}) => (
          <>
            <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="candidate"
                      required
                      component={SelectionSearch}
                      resource={CANDIDATE_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      placeholder="Chọn ứng viên"
                      label="Ứng viên"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-sm-12">
                    <DatePickerField
                      required
                      name="start_time"
                      label="Ngày bắt đầu"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="note"
                      component={TextArea}
                      placeholder="Nhập ghi chú"
                      label="Ghi chú"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
