import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import {Redirect, Route, Switch} from "react-router-dom";
import {ContentRoute, LayoutSplashScreen} from "../_theme/layout";
import { interpolationString } from '../utils/string';
import { isEmpty } from '../utils/typeof';
import { TeacherClassDetail } from './modules/Class/pages/list/id/TeacherClassDetail';
import TeacherListClassPage from './modules/Class/pages/list/TeacherListClassPage';
import PageTeacherStatisticCheckinDetail from './modules/Teacher/pages/PageTeacherStatisticCheckinDetail';
import ScheduleTeacher from './modules/Teacher/pages/schedule/ScheduleTeacher';
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import TeacherPage from "./modules/Teacher/pages/TeacherPage";
import UserPage from "./modules/user/UserPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const AdminPage = lazy(() =>
  import("./modules/Admin/pages/AdminPage")
);
const StudentPage = lazy(() =>
  import("./modules/Student/pages/StudentPage")
);
const LocationBranchPage = lazy(() =>
  import("./modules/LocationBranch/pages/LocationBranchPage")
);
const ManagePromotionPage = lazy(() =>
  import("./modules/ManagePromotion/pages/ManagePromotionPage")
);
const ClassPage = lazy(() =>
  import("./modules/Class/pages/ClassPage")
);
const CoursePage = lazy(() =>
  import("./modules/Course/pages/CoursePage")
);
const SystemPage = lazy(() =>
  import("./modules/System/pages/SystemPage")
);

function findFirstHref(menus) {
    let firstHrefOfMenu = '';
    menus.find(m => {
        if (m?.items?.length > 0) {
            let temp = findFirstHref(m?.items);
            if (temp) {
                firstHrefOfMenu = temp;
                return true;
            }
        } else if (m?.href) {
            firstHrefOfMenu = m?.href;
            return true;
        }
        return false;
    });
    return firstHrefOfMenu;
}

export default function BasePage() {
    const menus = useSelector(({ auth }) => auth?.user?.menus || []);
    const me = useSelector(({ auth }) => auth?.user || []);
    const urlDefault = useMemo(() => {
        let firstHrefOfMenu = findFirstHref(menus);
        if (firstHrefOfMenu) {
            const dataInterpolation = {
                me
            };
            return interpolationString(firstHrefOfMenu, dataInterpolation);
        } else {
            return '/dashboard';
        }
    }, [menus]);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to={urlDefault}/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/admin" component={AdminPage}/>
                <Route path="/student" component={StudentPage}/>
                <Route path="/location-branch" component={LocationBranchPage}/>
                <Route path="/manage-promotion" component={ManagePromotionPage}/>
                <Route path="/class" component={ClassPage}/>
                <Route path="/course" component={CoursePage}/>
                <Route path="/teacher" component={TeacherPage}/>
                <Route path="/system" component={SystemPage}/>
                <Route path="/user" component={UserPage}/>
                <Route path="/schedule" component={ScheduleTeacher}/>
                <Route path="/statistic-checkin" component={PageTeacherStatisticCheckinDetail}/>
                <Route path="/teacher-class/:classId" component={TeacherClassDetail}/>
                <Route path="/teacher-class-list" component={TeacherListClassPage}/>
                <Redirect to="/error/error-404"/>
            </Switch>
        </Suspense>
    );
}
