// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useIntl } from 'react-intl';
import * as Yup from "yup";
import {
  Input,
} from "../../../../../_theme/_partials/controls";
import { keyMessages } from '../../../../../_theme/i18n';

export function StudentEditNameESForm({
  save,
  item,
  actionsLoading,
  onHide
}) {
  const intl = useIntl();
  const messages = intl?.messages;

  // Validation schema
  const EditSchema = useMemo(() => {
    return Yup.object().shape({
      english_name: Yup.string()
        .required(messages[keyMessages['USER_CANDIDATE.FORM.REQUIRE_ENGLISH_NAME']]),
    });
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={EditSchema}
        onSubmit={(values, formikHelpers) => {
          save(values, formikHelpers);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body>
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="id"
                      disabled
                      component={Input}
                      label={messages[keyMessages['USER_CANDIDATE.FORM.ID']]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="name"
                      disabled
                      component={Input}
                      label={messages[keyMessages['USER_CANDIDATE.FORM.NAME']]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="english_name"
                      required
                      component={Input}
                      label={messages[keyMessages['USER_CANDIDATE.FORM.ENGLISH_NAME']]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="email"
                      disabled
                      component={Input}
                      label={messages[keyMessages['USER_CANDIDATE.FORM.EMAIL']]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="phone"
                      disabled
                      component={Input}
                      label={messages[keyMessages['USER_CANDIDATE.FORM.PHONE']]}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                {messages[keyMessages['FORM.CANCEL']]}
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? messages[keyMessages['FORM.SAVING']] : messages[keyMessages['FORM.SAVE']]}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
