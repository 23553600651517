import React from "react";
import * as requestFromServer from "../../../../common/DataTable/_redux/entitiesCrud";
import {TEACHER_RESOURCE} from "../../../../../../constants/system/resource";
import {DataTableUIProvider} from "../../../../common/DataTable/pages/DataTableUIContext";
import {DataTableProvider} from "../../../../common/DataTable/pages/DataTableContext";
import {LoadingDialog} from "../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {DataTableCard} from "../../../../common/DataTable/pages/DataTableCard";
import {DETAIL} from "../../../../../../constants/system/permission";
import {DATE_RANGE} from "../../../../../../constants/filterType";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../_theme/i18n";
import {Link} from "react-router-dom";

const StatisticCheckinPage = (props) => {
  const intl = useIntl();
  const messages = intl?.messages;
  const {
    teacherId,
    isTeacher,
    teacherInfomation,
    history
  } = props;

  const uiEvents = {
    clickViewDetailItem: data => {
      let url = `/class/${data.class_id}`;
      if (isTeacher) {
        url = `/teacher-class/${data.class_id}`;
      }

      history.push(url)
    },

    fetchData: query => {
      const url = TEACHER_RESOURCE + `/${teacherId}/checkin`;
      return requestFromServer.findEntities(url, query);
    }
  };

  const headers = [
    {
      dataField: "class.name",
      text: messages[keyMessages['CLASS.NAME']],
      sort: true,
      formatter: (cellContent, row) => {
        return(
          <Link to={"/teacher-class/" + row.class_id}>{cellContent}</Link>
        )
      }
    },
    {
      dataField: "num_lesson",
      text: messages[keyMessages['CLASS.NUMBER_LESSON']],
      sort: true
    },{
      dataField: "num_lesson_created",
      text: messages[keyMessages['CLASS.NUMBER_LESSON_CREATED']],
      sort: true
    },{
      dataField: "num_lesson_waiting",
      text: messages[keyMessages['CLASS.NUMBER_LESSON_WAITING']],
      sort: true
    },{
      dataField: "num_lesson_approve",
      text: messages[keyMessages['CLASS.NUMBER_LESSON_APPROVE']],
      sort: true
    },{
      dataField: "number_hour",
      text: messages[keyMessages['CLASS.NUMBER_HOUR']],
      sort: true
    },
    {
      dataField: "action",
      text: messages[keyMessages['DATATABLE.COLUMN.ACTION']],
      headerStyle: (column, colIndex) => {
        return { width: "120px", textAlign: "right" };
      },
      actions: [
        {
          color: "primary",
          icon: "remove_red_eye",
          label: "Detail",
          event: uiEvents.clickViewDetailItem,
          actionName: DETAIL,
        }
      ]
    }
  ];

  const filters = [
    {
      type: DATE_RANGE,
      label: "Khoảng thời gian",
      name: "checked_at"
    },
  ]

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      filters={filters}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
}

StatisticCheckinPage.propTypes = {};

StatisticCheckinPage.defaultProps = {};

export default StatisticCheckinPage;

