import React, { useEffect } from "react";
import { PaginationLinks } from './PaginationLinks';
import {PaginationToolbar} from "./PaginationToolbar";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
        <PaginationLinks paginationProps={paginationProps} pageNeighbours={1} />
      </div>
    </>
  );
}
