/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { subISOWeekYears } from "date-fns";
import { cloneDeep } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { ASIDE_MENU } from "../../../../../constants/system/menu";
import { VIEW } from '../../../../../constants/system/permission';
import { interpolationString } from '../../../../../utils/string';
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

const getDisplayMenus = (menus, permissions, parentName = "") => {
  return menus.filter(item => {
    const {
      permission = true,
    } = item;
    const routeName = parentName ? `${parentName}-${item.name}` : item.name;
    if (item.items) {
      item.items = getDisplayMenus(item.items, permissions || [], routeName);
      return item.items.length > 0;
    } else {
      if (permission) {
        return permissions?.includes(`${routeName}.${VIEW}`);
      }
      return true;
    }
  });
}

const getListIdByDeep = (menus, deep) => {
  let menuDeep = _.cloneDeep(menus);
  if (deep > 0) {
    [...Array(deep)].forEach((index) => {
      menuDeep = _.flattenDepth(menuDeep.filter(m => m?.items?.length > 0).map(m => m.items), 1);
    });
  }
  return menuDeep.map(m => m.id);
}

const getDeepMenu = (menus, deepInit = 0) => {
  if (!menus || menus.length === 0) {
    return deepInit;
  }

  return getDeepMenu(
    _.flattenDepth(menus
      .filter(m => m?.items?.length > 0)
      .map(m => m?.items), 1),
    deepInit + 1
  );
}

const convertListFlatToArray = (menus, menuProps, deep = 1) => {
  const deepCurrent = getDeepMenu(menus);
  if (!menuProps || menuIdsDeep?.length === 0 || deep > deepCurrent + 1) {
    return menus;
  }
  if (deep === 1) {
    menus = menuProps.filter(m => !m?.parent_id);
  } else {
    let temp = menus || [];
    for(let i = 1; i < deep - 1; ++i) {
      temp = _.flattenDepth(temp
        .filter(m => m?.items?.length > 0)
        .map(m => m?.items), 1)
    }
    console.log(temp);
    temp.forEach(m => {
      m.items = menuProps.filter(menuChild => menuChild?.parent_id === m.id);
    })
  }

  const menuIdsDeep = getListIdByDeep(menus, deep - 1);
  menuProps = menuProps.filter(m => !menuIdsDeep.includes(m?.id));
  return convertListFlatToArray(menus, menuProps, deep + 1);
}

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const locale = intl?.locale;
  const location = useLocation();
  const menus = useSelector(state => state.auth?.user?.menus || []);
  const me = useSelector(state => state.auth?.user || {});
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const dataInterpolation = {
    me
  };

  const permissions = useSelector(state => state.auth?.user?.permissions);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menus.map((item, index) => {
          const name = item.name;
          const key = `menu-item-${index}`;
          const hasSubmenu = item.items && item.items.length > 0;
          {
            /*begin::1 Level*/
          }
          const href = interpolationString(item.href, dataInterpolation);
          return (
            <li
              key={key}
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                href,
                hasSubmenu
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to={interpolationString(href, dataInterpolation)}>
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(`/media/svg/icons/${item.icon}.svg`)}
                  />
                </span>
                <span className="menu-text">{_.get(item, `languages.${locale}`) || item.title}</span>
                {hasSubmenu && <i className="menu-arrow" />}
              </NavLink>
              {hasSubmenu && (
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">{_.get(item, `languages.${locale}`) || item.title}</span>
                      </span>
                    </li>

                    {/* Inputs */}
                    {/*begin::2 Level*/}
                    {item.items.map((subItem, subIndex) => {
                      const subKey = `${key}-${subIndex}`;
                      const subName = `${name}-${subItem.name}`;
                      const subCode = `${subName}.${VIEW}`
                      const hasSubSubMenu =
                        subItem.items && subItem.items.length > 0;
                      const subHref = interpolationString(subItem.href, dataInterpolation)
                      return (
                        <li
                          key={subKey}
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            subHref,
                            hasSubSubMenu
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to={subHref}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{_.get(subItem, `languages.${locale}`) || subItem.title}</span>
                            {hasSubSubMenu && <i className="menu-arrow" />}
                          </NavLink>
                          {hasSubSubMenu && (
                            <div className="menu-submenu ">
                              <i className="menu-arrow" />
                              <ul className="menu-subnav">
                                {/*begin::3 Level*/}
                                {subItem.items((subSubItem, subSubIndex) => {
                                  const subSubHref = interpolationString(subSubItem.href, dataInterpolation)
                                  return (
                                    <li
                                      key={`${subKey}-${subSubIndex}`}
                                      className={`menu-item  ${getMenuItemActive(
                                        subSubHref
                                      )}`}
                                      aria-haspopup="true"
                                    >
                                      <NavLink
                                        className="menu-link"
                                        to={subSubHref}
                                      >
                                        <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                        </i>
                                        <span className="menu-text">
                                          {_.get(subSubItem, `languages.${locale}`) || subSubItem.title}
                                        </span>
                                      </NavLink>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
