import { cloneDeep } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import React, {
  createContext,
  useContext, useEffect,
  useMemo,
  useState
} from "react";
import { VIEW } from '../../constants/system/permission';
import { PAGE_RESOURCE } from '../../constants/system/resource';
import { TEACHER } from '../../constants/system/role';
import { processError } from '../../utils/axios';
import { isEmpty, isArray, isString } from '../../utils/typeof';
import { actions } from '../modules/Auth/_redux/authRedux';
import * as requestFromServer from '../modules/common/DataTable/_redux/entitiesCrud';

const PermissionContext = createContext({});

export function usePermissionContext() {
  return useContext(PermissionContext);
}

export const PermissionConsumer = PermissionContext.Consumer;

const findRouteNameByField = (menus, field, value, parentName = "") => {
  let routerNameRs = "";
  menus.find(item => {
    const {
      permission = true,
      items
    } = item;
    const routeName = item.name;
    if (items) {
      const rs = findRouteNameByField(items, field, value, routeName);
      if (rs) {
        routerNameRs = rs;
        return true;
      }
    } else {
      if (permission) {
        if (item[field]?.includes(value)) {
          routerNameRs = routeName;
          return true;
        }
      }
    }
    return false;
  });
  return routerNameRs;
}

const findRouteNameByPathname = (menus, pathname) => {
  return findRouteNameByField(menus, 'href', pathname);
}

const findRouteNameByPageName = (menus, pageName) => {
  return findRouteNameByField(menus, 'name', pageName);
}

export function PermissionProvider(props) {
  const { children } = props;
  const menuPermissions = useSelector(state => state.auth?.user?.permissions);
  const menusOfMe = useSelector(state => state.auth?.user?.menus || []);
  const roles = useSelector(state => state.auth?.user?.roles || []);
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken && auth.user != null && auth.user?.permissions,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [permissionPages, setPermissionPages] = useState({});
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (isAuthorized) {
      dispatch(actions.requestUser())
    }
  }, [])

  useEffect(() => {
    if (!isAuthorized) {
      setPermissionPages({});
    }
  }, [isAuthorized])

  const routeName = useMemo(() => {
    return findRouteNameByPathname(menusOfMe, pathname)
  }, [pathname]);

  useEffect(() => {
    const isAccess = menuPermissions?.includes(`${routeName}.${VIEW}`);
    // if (routeName && !isAccess) {
    //   if (menuPermissions) {
    //     history.replace('/error/error-404');
    //   }
    // }
  }, [pathname])

  useEffect(() => {
    if (isAuthorized && routeName && !permissionPages[routeName]) {
      fetchPermissions([routeName]);
      // requestFromServer
      //   .get(PAGE_RESOURCE, 'permission', { name: routeName })
      //   .then(rs => {
      //     if (rs.status === 200 && rs.data?.code === 0) {
      //       setPermissionPages({
      //         ...permissionPages,
      //         [routeName]: rs.data?.data || []
      //       })
      //     }
      //   }).catch(e => {
      //     processError(e);
      // })
    }
  }, [routeName, isAuthorized]);

  const fetchPermissions = (pages = []) => {
    if (isAuthorized && !isEmpty(pages)) {
      const pageNotExistPermissions = pages.filter(page => !permissionPages[routeName]);
      if (!isEmpty(pageNotExistPermissions)) {
        requestFromServer
          .get(PAGE_RESOURCE, 'permissions', { pages: pageNotExistPermissions })
          .then(rs => {
            if (rs.status === 200 && rs.data?.code === 0) {
              setPermissionPages({
                ...permissionPages,
                ...(rs.data?.data || {}),
              })
            }
          }).catch(e => {
          processError(e);
        })
      }
    }
  }

  const checkCanPage = (routerName, action) => {
    // const routerName = findRouteNameByPageName(ASIDE_MENU, pageName);
    const permissionPage = permissionPages[routerName] || [];
    return permissionPage.includes(`${routerName}.${action}`) || menuPermissions?.includes(`${routerName}.${action}`);
  }

  const checkRole = roleName => {
    if (isArray(roleName)) {
      return roles.some(role => roleName.includes(role?.name));
    } else if (isString(roleName)) {
      return roles.some(role => role.name === roleName);
    }
    return true;
  }

  const filterMenu = (menus) => {
    return menus.filter(item => {
      if (item.items) {
        item.items = filterMenu(item.items);
        return item.items.length > 0;
      } else {
        return item.title;
      }
    });
  };

  const flattenTree = (result, menu, depth = 0, parentName = "") => {
    menu.forEach(item => {
      const currentDepth = depth;
      let value = item.name;
      if (parentName) {
        value = `${parentName}-${value}`;
      }
      if (item.items) {
        result.push({
          value,
          text: item.title,
          href: item.href,
          depth: currentDepth,
          visible: !!item.href
        });

        item.items = flattenTree(
          result,
          item.items,
          currentDepth + 1,
          value
        );
      } else {
        result.push({
          value,
          href: item.href,
          text: item.title,
          depth: currentDepth,
          visible: !!item.href
        });
      }
    });

    return result.filter(menu => !!menu.href);
  }

  const menusShow = useMemo(() => {
    return flattenTree([], filterMenu(cloneDeep(menusOfMe)));
  }, [menusOfMe]);

  const value = {
    menus: menusShow,
    permissionPages,
    permissionPage: permissionPages[routeName] || [],
    checkRole,
    checkCanPage,
    fetchPermissions
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
}
