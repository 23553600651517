import React, {useEffect} from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import * as requestFromServer from "../../../../app/modules/common/DataTable/_redux/entitiesCrud";
import { isFunction } from '../../../../utils/typeof';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import {AsyncPaginate} from "react-select-async-paginate";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["select-wrapper"];
  if (errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function SelectionSearch(props) {
  const {
    form: { touched, errors, isValid } = {},
    value,
    field = {},
    label,
    labelFunction,
    propertyName,
    propertyValue,
    resource,
    placeholder,
    isMulti = false,
    required,
    withFeedbackLabel = false,
    customFeedbackLabel,
    type,
    params = {},
    options = null,
    formatLabel = undefined,
    customOption = undefined,
    classWrapper,
    onSelect,
    ...attrs
  } = props;

  const name = field.name;
  const onBlur = field.onBlur;
  const onFocus = field.onFocus;
  const [optionsState, setOptionsState] = React.useState([]);

  const loadData = (inputSearch, loadedOptions, { page }) => {
    const pageSize = 15;
    const queryParam = {
      keyword: inputSearch,
      page: page,
      page_size: pageSize
    };

    return requestFromServer
      .selectEntities(resource, {
        ...queryParam,
        ...params
      })
      .then(response => {
        const { total, data, code } = response.data;
        if (code === 0) {
          if(customOption){
            const dataCustom = customOption(data);
            setOptionsState(dataCustom);
            return {
              options: dataCustom,
              hasMore: total > page * pageSize,
              additional: {
                page: page + 1,
              }
            };
          }
          else{
            setOptionsState(data);
            return {
              options: data,
              hasMore: total > page * pageSize,
              additional: {
                page: page + 1,
              }
            };
          }
        }
      })
      .catch(error => console.log(error));
  };

  const onChange = obj => {
    if (isFunction(props?.form?.setFieldValue)) {
      props.form.setFieldValue(name, obj);
    }
    if (isFunction(onSelect)) {
      onSelect(obj);
    }
  };

  const styleControl = attrs.isDisabled ? { backgroundColor: '#F3F6F9', opacity: 1 } : {};

  return (
    <div className={classWrapper}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <AsyncPaginate
        // Tạm thời fix
        key={JSON.stringify(params)}
        isClearable
        defaultOptions
        loadOptions={loadData}
        styles={{ menu: styles => ({ ...styles, zIndex: 1000 }), control: styles => ({ ...styles, ...styleControl }) }}
        options={optionsState}
        getOptionLabel={e => formatLabel !== undefined ? formatLabel(e) :
            e?.[propertyName]}
        getOptionValue={e => e[propertyValue]}
        placeholder={placeholder}
        value={field?.value || value || ""}
        menuPlacement="auto"
        isMulti={isMulti}
        onChange={onChange}
        className={getFieldCSSClasses(touched?.[field?.name], errors?.[field?.name])}
        onBlur={onBlur}
        onFocus={onFocus}
        additional={{
          page: 1
        }}
        {...attrs}
      />

      {(withFeedbackLabel || errors?.[field?.name]) && (
        <FieldFeedbackLabel
          error={errors?.[field?.name]}
          touched={touched?.[field?.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}
