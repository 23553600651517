import { isEqual } from "lodash";
import React, { useMemo } from "react";
import { buildFilterToQuery } from '../../../../../../utils/filter';
import { useDataTableUIContext } from "../DataTableUIContext";
import { DataTableFilterDesktop } from './DataTableFilterDesktop';
import { DataTableFilterMobile } from './DataTableFilterMobile';

export function DataTableFilter({ listLoading }) {
  const dataTableUIContext = useDataTableUIContext();
  const dataTableUIProps = useMemo(() => {
    return {
      filters: dataTableUIContext.filters,
      toolbars: dataTableUIContext.toolbars,
      setQueryParams: dataTableUIContext.setQueryParams,
      queryParams: dataTableUIContext.queryParams,
      clickNewItem: dataTableUIContext.clickNewItem,
      clickImportData: dataTableUIContext.clickImportData
    };
  }, [dataTableUIContext]);

  const applyFilter = ({ searchText, filters }) => {
    const newQueryParams = buildFilterToQuery({
      filters,
      filtersParam: dataTableUIProps?.filters,
      searchText,
      queryParams: dataTableUIProps.queryParams
    })
    if (!isEqual(newQueryParams, dataTableUIProps.queryParams)) {
      newQueryParams.page = 1;
      dataTableUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <DataTableFilterDesktop
        filters={dataTableUIProps.filters}
        queryFilter={dataTableUIProps.queryParams?.filters || []}
        submitFilter={applyFilter}
      />
      <DataTableFilterMobile
        filters={dataTableUIProps.filters}
        submitFilter={applyFilter}
      />
    </>
  );
}
