import React, {useState} from "react";
import { ENROLLMENT_STATUS_SELECT } from '../../../../../constants/system/dataSelect';
import {
  COUNTRY_RESOURCE,
  DEGREE_RESOURCE,
  TEACHER_RESOURCE,
  USER_SYSTEM_RESOURCE
} from "../../../../../constants/system/resource";
import * as requestFromServer from "../../../common/DataTable/_redux/entitiesCrud";
import {DataTableCard} from "../../../common/DataTable/pages/DataTableCard";
import {DataTableProvider, useDataTableContext} from "../../../common/DataTable/pages/DataTableContext";
import {DataTableUIProvider} from "../../../common/DataTable/pages/DataTableUIContext";
import {DataTableDeleteDialog} from "../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog";
import {EditDialog} from "../../../common/DataTable/pages/edit-dialog/EditDialog";
import {LoadingDialog} from "../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import * as columnFormatters from "../../../common/DataTable/pages/table/column-formatters";
import {TeacherEditForm} from "./TeacherFormEdit";
import moment from "moment";
import {MMDDYYYY} from "../../../../../constants/system/dateformat";
import { SELECT_SOURCE, SELECT } from "../../../../../constants/filterType";
import {MAP_DEGREE} from "../../../../../constants/MapConvert";
import {CREATE_ACCOUNT, DELETE, DISABLE_ACCOUNT, EDIT} from "../../../../../constants/system/permission";
import {FormDialog} from "../../../common/DataTable/pages/form-dialog/FormDialog";
import {UserEditForm} from "../../../Admin/pages/user/UserEditForm";
import axios from "axios";
import {ConfirmDialog} from "./ConfirmDialog";

const ListTeacherPage = ({history}) => {
  const [selectedData, setSelectedData] = useState();
  const [showDialogFormTeacher, setDialogFormTeacher] = useState(false);
  const [showDialogDelete, setDialogDeleteTeacher] = useState(false);
  const [showDialogCreateAccount, setDialogCreateAccount] = useState(false);
  const [showDialogDisableAccount, setDialogDisableAccount] = useState(false);

  const openDialogFormTeacher = () => setDialogFormTeacher(true);
  const closeDialogFormTeacher = () => {
    setSelectedData(null);
    setDialogFormTeacher(false);
  };
  const openDialogDeleteTeacher = () => setDialogDeleteTeacher(true);
  const closeDialogDeleteTeacher = () => {
    setSelectedData(null);
    setDialogDeleteTeacher(false);
  };
  const openDialogCreateAccount = () => setDialogCreateAccount(true);
  const closeDialogCreateAccount = () => {
    setSelectedData(null);
    setDialogCreateAccount(false);
  };
  const openDialogDisableAccount = () => setDialogDisableAccount(true);
  const closeDialogDisableAccount = () => {
    setSelectedData(null);
    setDialogDisableAccount(false);
  };

  const uiEvents = {
    clickNewItem: () => {
      setSelectedData(null);
      openDialogFormTeacher(true);
    },
    clickEditItem: dataEdit => {
      console.log(dataEdit);
      dataEdit = {
        ...dataEdit,
        "nationality": {
          nationality: dataEdit.nationality
        }
      }
      openDialogFormTeacher();
      setSelectedData(dataEdit);
    },
    clickDeleteItem: dataDelete => {
      openDialogDeleteTeacher();
      setSelectedData(dataDelete);
    },
    clickViewDetailItem: dataEdit => {
      setSelectedData(dataEdit);
    },
    clickCreateAccount: (data) => {
      setSelectedData(data);
      openDialogCreateAccount();
    },

    clickDisableAccount: (data) => {
      setSelectedData(data);
      openDialogDisableAccount();
    },

    fetchData: query => {
      return requestFromServer.findEntities(TEACHER_RESOURCE, query);
    },
    newData: data => {
      return requestFromServer.createEntity(TEACHER_RESOURCE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(TEACHER_RESOURCE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(TEACHER_RESOURCE, id);
    },

    createAccount: data => {
      if (data && data.id) {
        if (!data.email) {
          alert("Giáo viên cần có email!");
          return;
        } else {
          const url = USER_SYSTEM_RESOURCE + "/link-teacher"
          return axios.post(url, {
            teacher_id: data.id
          }).then(res => {
            closeDialogCreateAccount();
          }).catch(err => console.log(err));
        }
      }
    },

    disableAccount: data => {
      if (data && data.id) {
        const url = TEACHER_RESOURCE + "/disable-account"
        return axios.post(url, {
          id: data.id
        }).then(res => {
          closeDialogDisableAccount();
        }).catch(err => console.log(err));
      }
    }
  };

  const headers = [
    {
      dataField: "code",
      text: "Code",
      sort: true
    },
    {
      dataField: "name",
      text: "Tên",
      sort: true
    },
    {
      dataField: "nationality",
      text: "Quốc tịch",
      sort: true
    },
    {
      dataField: "degree_code",
      text: "Trình độ",
      sort: true,
      formatter: (cellContent, row) => {
        return MAP_DEGREE[cellContent];
      }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true
    },
    {
      dataField: "phone",
      text: "Số điện thoại"
    },
    {
      dataField: "other_contact",
      text: "Hình thức liên lạc khác"
    },
    {
      dataField: "enrollment_status",
      text: "Hình thức dạy",
      sort: true,
      formatter: (cellContent, row) => {
        const enrollmentStatus = ENROLLMENT_STATUS_SELECT.find(item => item.value === cellContent);
        return enrollmentStatus?.text;
      }
    },
    {
      dataField: "max_hour_per_week",
      text: "Giờ dạy tối đa/tuần",
      sort: true
    },
    {
      dataField: "num_year_experience",
      text: "Số năm kinh nghiệm",
      sort: true
    },
    {
      dataField: "programs",
      text: "Chương trình có thể dạy",
      formatter: ((cellContent, row) => {
        return cellContent?.map(p => p.name).join(",")
      })
    },
    {
      dataField: "expire_time",
      text: "Hạn hợp đồng",
      sort: true,
      formatter: columnFormatters.DateTimeColumnFormatter
    },
    {
      dataField: "teacher_certificate",
      text: "Chứng chỉ sư phạm",
    },
    {
      dataField: "specialized_certificate",
      text: "Chứng chỉ chuyên ngành",
    },
    {
      dataField: "action",
      text: "Hành động",
      headerStyle: (column, colIndex) => {
        return {width: "120px", textAlign: "right"};
      },
      actions: [
        {
          color: "primary",
          icon: "person_add",
          label: "Tạo tài khoản",
          event: uiEvents.clickCreateAccount,
          actionName: CREATE_ACCOUNT,
          visible: (row) => row.item_permissions.includes(CREATE_ACCOUNT)
        },
        {
          color: "error",
          icon: "person_add_disabled",
          label: "Vô hiệu hóa tài khoản",
          event: uiEvents.clickDisableAccount,
          actionName: DISABLE_ACCOUNT,
          visible: (row) => row.item_permissions.includes(DISABLE_ACCOUNT)
        },
        {
          color: "primary",
          icon: "edit",
          label: "Chỉnh sửa",
          event: uiEvents.clickEditItem,
          actionName: EDIT
        },
        {
          color: "error",
          icon: "delete",
          label: "Xóa",
          event: uiEvents.clickDeleteItem,
          actionName: DELETE,
        }
      ]
    }
  ];

  const filters = [
    {
      type: SELECT_SOURCE,
      label: "Trình độ",
      name: "degree_code",
      resource: DEGREE_RESOURCE,
      propertyName: "code",
      propertyValue: "code",
      mapConvert: MAP_DEGREE
    },
    {
      type: SELECT,
      label: "Hình thức dạy",
      name: "enrollment_status",
      options: ENROLLMENT_STATUS_SELECT,
      propertyValue: "value",
      propertyName: "text"
    },
    {
      type: SELECT_SOURCE,
      label: "Quốc tịch",
      name: "nationality",
      resource: COUNTRY_RESOURCE,
      propertyName: "nationality",
      propertyValue: "nationality",
    }
  ];

  const initData = {
    id: undefined,
    name: "",
    degree: "",
    expire_time: moment(Date.now()).format(MMDDYYYY).toString(),
    "nationality": {
      nationality: "Vietnamese"
    },
    email: "",
    programs: "",
    teacher_certificate: "",
    specialized_certificate: "",
    max_hour_per_week: 1,
    num_year_experience: 0,
  };

  const createAccount = ({data}) => {
    console.log(data);
    return uiEvents.createAccount(data);
  };

  const disableAccount = ({data}) => {
    console.log(data);
    return uiEvents.disableAccount(data);
  };

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      initData={initData}
      filters={filters}
    >
      <DataTableProvider pageName="teacher-list">
        <LoadingDialog/>
        <EditDialog
          id={selectedData && selectedData.id}
          size="lg"
          show={showDialogFormTeacher}
          item={selectedData}
          title={
            selectedData
              ? `Sửa giáo viên: ${selectedData && selectedData.name}`
              : "Tạo mới giáo viên"
          }
          onHide={closeDialogFormTeacher}
          ComponentForm={TeacherEditForm}
        />
        <FormDialog
          show={showDialogCreateAccount}
          title={`Tạo tài khoản cho giáo viên: ${selectedData?.name}`}
          initData={{
            ...selectedData,
            content: `Bạn có chắc muốn tạo tài khoản cho giáo viên: ${selectedData?.name}`
          }}
          selectedData={selectedData}
          onHide={closeDialogCreateAccount}
          onSubmit={createAccount}
          ComponentForm={ConfirmDialog}
        />
        <FormDialog
          show={showDialogDisableAccount}
          title={`Vô hiệu hóa tài khoản: ${selectedData?.name}`}
          initData={{
            ...selectedData,
            content: `Bạn có chắc muốn vô hiệu hóa tài khoản của giáo viên: ${selectedData?.name}`
          }}
          selectedData={selectedData}
          onHide={closeDialogDisableAccount}
          onSubmit={disableAccount}
          ComponentForm={ConfirmDialog}
        />

        <DataTableDeleteDialog
          id={selectedData && selectedData.id}
          show={showDialogDelete}
          onHide={closeDialogDeleteTeacher}
          title="Xóa giáo viên"
          content={
            <div>
              Bạn chắc chắn muốn giáo viên &nbsp;
              <span className="text-danger">
                {selectedData && selectedData.name}
              </span>
            </div>
          }
        />
        <DataTableCard dataTableUIEvents={uiEvents}/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
};

ListTeacherPage.propTypes = {};

ListTeacherPage.defaultProps = {};

export default ListTeacherPage;
