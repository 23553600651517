/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Form, Formik, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from "react";
import "../../../../_theme/_assets/sass/pages/login/classic/login-1.scss";
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useLocation } from "react-router";
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Card, DatePickerField, Input } from '../../../../_theme/_partials/controls';
import { TextArea } from '../../../../_theme/_partials/controls/forms/TextArea';
import { HTTP_CODE_422 } from '../../../../constants/system/code';
import reactToastify from '../../../../plugin/react-toastify';
import { responseErrorToString } from '../../../../utils/array';
import { processErrorFormik } from '../../../../utils/axios';
import { classList } from '../../../../utils/DOM/class';
import { setError } from '../../../../utils/formik';
import { getUserByToken, getUserInfoByInviteToken, login, updatePasswordByToken } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

const initialValues = {
  passwordReplace: "",
  password: "",
};

function ForgotPasswordCallbackPage(props) {
  const location = useLocation() || {};
  const history = useHistory();
  const query = new URLSearchParams(location?.search);
  const authToken = useSelector(state => state?.auth?.authToken);
  const userIdCurrent = useSelector(state => state?.auth?.user?.id);

  const token = useMemo(() => {
    return query?.get('token');
  }, [query]);

  const error = useMemo(() => {
    return query?.get('error');
  }, [query]);

  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const enableLoading = () => {
    setLoading(true);
  }

  const disableLoading = () => {
    setLoading(true);
  }

  const errorPassword = "Password must be at least 8 characters, with at least 1 uppercase character and at least 1 special character($,~,!,#,^,(,),@,$,!,%,*,?,&)"

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, errorPassword)
      .required("Password must not be empty"),
    passwordReplace: Yup.string()
      .min(6, errorPassword)
      .required("Password must not be empty"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    if (token) {
      setLoadingCheck(true);
      setUser(null);
      getUserInfoByInviteToken(token)
      .then((data) => {
        setLoadingCheck(false);
        setUser(data);
      })
      .catch((error) => {
        setLoadingCheck(false);
        if (error?.response?.status === HTTP_CODE_422) {
          setErrorMessage(responseErrorToString(error.response.data.errors))
        } else if (!error.response) {
          setErrorMessage(error?.data?.message);
        } else {
          setErrorMessage(error?.response?.data?.message);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (authToken === token && user?.id === userIdCurrent && userIdCurrent) {
      history.push('/')
    }
  }, [authToken, token, user, userIdCurrent])

  const updatePassword = (password, formik) => {
    if (token) {
      formik.setSubmitting(true);
      enableLoading();
      updatePasswordByToken(token, password)
        .then((data) => {
          disableLoading();
          formik.setSubmitting(false);
          props.login(token);
        })
        .catch((error) => {
          disableLoading();
          formik.setSubmitting(false);
          if (error?.response?.status === HTTP_CODE_422) {
            processErrorFormik(error, formik);
          } else {
            formik.setStatus(error.response?.data?.message);
          }
        });
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, formik) => {
      if (values.password !== values.passwordReplace) {
        formik.setSubmitting(false);
        setError('passwordReplace', ["Retype Wrong password"], formik)
      } else {
        updatePassword(values.password, formik);
      }
    },
  });

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div
            className="content order-1 order-lg-2 d-flex flex-column w-100 h-100 pb-0"
            style={{backgroundColor: '#B1DCED'}}
          >
            <div className="d-flex flex-column justify-content-center text-center pt-lg-24 pt-md-8 pt-sm-8 px-lg-0 pt-18 px-7">
              {
                loadingCheck &&
                (
                  <Card className="container max-w-800 pt-8 min-h-150px">
                    <p className="font-size-h2-md font-size-lg text-dark opacity-70">
                      <div className={classList("overlay", "overlay-block")}>
                        <div className="overlay-layer zindex-2 bg-transparent flex-wrap">
                          <p className="w-100">
                            Đang kiểm tra mã cung cấp...
                          </p>
                          <div className="spinner-border text-success" />
                        </div>
                      </div>
                    </p>
                  </Card>
                )
              }
              {
                ((!loadingCheck &&
                  !user) || error) &&
                (
                  <Card className="container max-w-800">
                    <h3
                      className="display4 font-weight-normal my-7 text-dark"
                    >
                      {error ? "Lỗi" : "Mã không có hiệu lực"}
                    </h3>
                    <p className="font-size-h2-md font-size-lg text-danger opacity-70">
                      {errorMessage || error}
                    </p>
                    <p>
                      <a href="/" className="btn btn-primary font-size-h4 mt-3 mb-2">Trang chủ</a>
                    </p>
                  </Card>
                )
              }
              {
                !loadingCheck &&
                user &&
                (
                  <Card className="container max-w-800">
                    <h3
                      className="display4 font-weight-normal my-7 text-dark"
                    >
                      Reset password
                    </h3>
                    <p className="font-size-h2-md font-size-lg text-dark opacity-70">
                      Please enter new password for account {user?.username}
                      <br />
                    </p>
                    <div className="login-form login-signin m-auto text-left">
                      <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                      >
                        {formik.status ? (
                          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                          </div>
                        ) : (<div></div>)}

                        <div className="form-group fv-plugins-icon-container">
                          <input
                            placeholder="New Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                              "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                          <input
                            placeholder="Retype Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                              "passwordReplace"
                            )}`}
                            name="passwordReplace"
                            {...formik.getFieldProps("passwordReplace")}
                          />
                          {formik.touched.passwordReplace && formik.errors.passwordReplace ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.passwordReplace}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                          <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 m-auto`}
                          >
                            <span>Save</span>
                            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
                          </button>
                        </div>
                      </form>
                    </div>
                  </Card>
                )
              }
            </div>

            <div
              className="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{backgroundImage: 'url(/media/bg/demo3.svg)'}}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, auth.actions)(ForgotPasswordCallbackPage);
