import { isObject } from '../typeof';

export const addPrototypeCreate = () => {
  String.prototype.create = function(o) {
    if (isObject(o)) {
      try {
        const regexp = /{([^{]+)}/g;

        return this.replace(regexp, function(ignore, key){
          return (key = o[key]) == null ? '' : key;
        });
      } catch (e) {
        console.log(e);
      }
    }
    return this;
  }
};
