import React, {useMemo} from "react";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {useSelector} from 'react-redux';
import * as Yup from "yup";
import {Admin, PROGRAM_DESIGNER} from '../../../../../constants/system/role';
import {classList} from "../../../../../utils/DOM/class";
import {SelectionSearch} from "../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {
    CLASS_RESOURCE,
    LOCATION_BRANCH_RESOURCE,
    ROOM_RESOURCE,
    TEACHER_RESOURCE
} from "../../../../../constants/system/resource";
import {usePermissionContext} from '../../../../components/PermissionContext';
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../_theme/i18n";
import axios from "axios";
import reactToastify from "../../../../../plugin/react-toastify";
import {processError} from "../../../../../utils/axios";

// Validation schema
const EditSchema = Yup.object().shape({
    class: Yup.object()
        .nullable()
        .required("Lớp là bắt buộc"),
    location_branch: Yup.object()
        .nullable(),
    room: Yup.object()
        .nullable()
});

export function ScheduleEditForm({save, item, actionsLoading, onHide}) {
    const intl = useIntl();
    const messages = intl?.messages;
    const roles = useSelector(state => state.auth?.user?.roles || []);
    const permissionContext = usePermissionContext();
    const isNotProgramDesigner = useMemo(() => {
        return !permissionContext.checkRole([PROGRAM_DESIGNER, Admin])
    }, [roles])

    const isSentMail = () => {
        return item.is_notification_email_to_teacher === 1;
    }

    const sendMail = () => {
        axios.post(`/api/teacher/notify-email-lesson-change-schedule`, {
            "lesson_id": item.id
        }).then(rs => {
            if (rs?.data?.code === 0) {
                reactToastify.success("Gửi mail thành công !!!");
            } else {
                reactToastify.actionFail();
            }
        }).catch(e => {
            processError(e)
        })
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={item}
                validationSchema={EditSchema}
                onSubmit={(value, formik) => {
                    save(value, formik);
                }}
            >
                {({handleSubmit}) => (
                    <>
                        <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    {/* First Name */}
                                    <div className="col-sm-12">
                                        <Field
                                            name="class"
                                            component={SelectionSearch}
                                            resource={CLASS_RESOURCE}
                                            propertyName="name"
                                            propertyValue="id"
                                            isDisabled={isNotProgramDesigner}
                                            placeholder="Chọn lớp"
                                            label="Lớp"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12 col-md-6">
                                        <Field
                                            name="location_branch"
                                            component={SelectionSearch}
                                            resource={LOCATION_BRANCH_RESOURCE}
                                            isDisabled={isNotProgramDesigner}
                                            propertyName="name"
                                            propertyValue="id"
                                            placeholder="Choose location"
                                            label="Location"
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <Field
                                            name="room"
                                            component={SelectionSearch}
                                            resource={ROOM_RESOURCE}
                                            isDisabled={isNotProgramDesigner}
                                            propertyName="name"
                                            propertyValue="id"
                                            placeholder="Choose classroom"
                                            label="Classroom"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Field
                                            name="teacher"
                                            component={SelectionSearch}
                                            resource={TEACHER_RESOURCE}
                                            isDisabled={isNotProgramDesigner}
                                            propertyName="name"
                                            propertyValue="id"
                                            placeholder="Chọn giáo viên"
                                            label="Giáo viên"
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                {messages[keyMessages["FORM.CANCEL"]]}
                            </button>
                            <> </>
                            {!isNotProgramDesigner && <button
                                type="submit"
                                disabled={actionsLoading}
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                {actionsLoading ? messages[keyMessages["FORM.SAVING"]] : messages[keyMessages["FORM.SAVE"]]}
                            </button>}
                            {!isNotProgramDesigner && <button
                                type="submit"
                                disabled={actionsLoading}
                                onClick={() => sendMail()}
                                className="btn btn-danger btn-elevate"
                            >
                                {isSentMail() ? "Resend Email" : "Send email"}
                            </button>}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}
