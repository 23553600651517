/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { injectIntl, useIntl } from 'react-intl';
import { Redirect, Switch, Route } from "react-router-dom";
import { useLocation } from "react-router";
import { shallowEqual, useSelector, connect } from "react-redux";
import { keyMessages } from '../_theme/i18n';
import { Layout } from "../_theme/layout";
import { FCM_RESOURCE } from '../constants/system/resource';
import { isFunction } from '../utils/typeof';
import BasePage from "./BasePage";
import { PermissionProvider } from './components/PermissionContext';
import { updateDayOfWeek } from '../constants/date';
import { Logout, AuthPage } from "./modules/Auth";
import * as ui from './modules/common/UI/_redux/uiRedux';
import ChangeEmailCallbackPage from './modules/Auth/pages/ChangeEmailCallbackPage';
import ForgotPasswordCallbackPage from './modules/Auth/pages/ForgotPasswordCallbackPage';
import SocialCallbackPage from './modules/Auth/pages/SocialCallbackPage';
import * as requestFromServer from './modules/common/DataTable/_redux/entitiesCrud';
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { requestFirebaseNotificationPermission } from './modules/Firebase/firebaseInit';

function Routes(props) {
  const intl = useIntl();
  const messages = intl?.messages;

  useEffect(() => {
    updateDayOfWeek([
      {
        value: 1,
        code: "Mon",
        name: messages[keyMessages["MONDAY"]]
      },
      {
        value: 2,
        code: "Tue",
        name: messages[keyMessages["TUESDAY"]]
      },
      {
        value: 3,
        code: "Wed",
        name: messages[keyMessages["WEDNESDAY"]]
      },
      {
        value: 4,
        code: "Thu",
        name: messages[keyMessages["THURSDAY"]]
      },
      {
        value: 5,
        code: "Fri",
        name: messages[keyMessages["FRIDAY"]]
      },
      {
        value: 6,
        code: "Sat",
        name: messages[keyMessages["SATURDAY"]]
      },
      {
        value: 0,
        code: "Sun",
        name: messages[keyMessages["SUNDAY"]]
      }
    ]);
  }, [messages])

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken && auth.user != null && auth.user?.permissions,
    }),
    shallowEqual
  );
  const updateDimensions = () => {
    props.resize();
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, []);


  useEffect(() => {
    if (isAuthorized && isFunction(requestFirebaseNotificationPermission)) {
      try {
        requestFirebaseNotificationPermission()
          .then((firebaseToken) => {
            // eslint-disable-next-line no-console
            if (firebaseToken) {
              requestFromServer.post(FCM_RESOURCE, 'save-token', { token: firebaseToken }).then(resp => {
              });
            }
          })
          .catch((err) => {
            console.log(err)
            return err;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [isAuthorized])

  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path="/auth/social-callback" component={SocialCallbackPage}/>
      <Route path="/auth/forgot-password-callback" component={ForgotPasswordCallbackPage}/>
      <Route path="/auth/change-email-callback" component={ChangeEmailCallbackPage}/>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path="/auth" component={AuthPage} />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/"/>
      )}
      <Route path="/error" component={ErrorsPage}/>
      <Route path="/logout" component={Logout}/>

      {!isAuthorized && pathname !== "/social-callback" ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login"/>
      ) : (
        <Layout>
          <BasePage/>
        </Layout>
      )}
    </Switch>
  );
}

export default connect(null, ui.actions)(Routes);
