import React, { useMemo } from "react";
import Nav from "react-bootstrap/Nav";
import { useIntl } from 'react-intl';
import { keyMessages } from "../../../../../../_theme/i18n";
import { classList } from "../../../../../../utils/DOM/class";
import { isEmpty } from '../../../../../../utils/typeof';
import DropdownFilter from "./DropdownFilter";
import ToolBar from "./ToolBar";

const DataTableFilterShort = props => {
  const intl = useIntl();
  const messages = intl?.messages;

  const {
    filters,
    valueFilter,
    queryFilter,
    isOpenDetailFilter,
    searchText,

    openDetailFilter,
    setAnchorElPopper,
    setFilterSelected,
    openPopperFilter,
    onChangeTextSearch
  } = props;

  const isFilterEmpty = useMemo(() => {
    return isEmpty(filters)
  }, [filters]);

  const isFilterSelectedEmpty = useMemo(() => {
    return isEmpty(valueFilter)
  }, [valueFilter]);

  const filterSelectedStr = useMemo(() => {
    return queryFilter
      .map(f => {
        const { operator, name, text } = f;
        let operatorStr = ` ${operator} `;
        if (operator === "like") {
          operatorStr = ": ";
        } else if (operator === "eq") {
          operatorStr = " = ";
        } else if (operator === 'gte') {
          operatorStr = " >= ";
        } else if (operator === 'lte') {
          operatorStr = " <= ";
        }
        return `${name}${operatorStr}<b>${text}</b>`;
      })
      .join("; ");
  }, [queryFilter])

  const handleClickDropdownFilter = ({ target, filterSelected, targetDropdown }) => {
    setAnchorElPopper(targetDropdown);
    setFilterSelected(filterSelected);
    openPopperFilter();
  }

  if (isOpenDetailFilter) {
    return null;
  }

  return (
    <div className="row mx-0">
      <div className="d-none d-md-inline-flex">
        {!isFilterEmpty && (
          <div className="d-flex align-items-center h-100">
            <span className="svg-icon menu-icon">
              <i
                className={classList(
                  "fas fa-filter",
                  !isFilterSelectedEmpty && "text-primary"
                )}
              />
            </span>
          </div>
        )}
        {filterSelectedStr && (
          <Nav.Link
            className="d-inline-flex align-items-center mb-0-5"
            onClick={openDetailFilter}
          >
            <div
              className="html-content truncate max-w-500 text-primary"
              dangerouslySetInnerHTML={{ __html: filterSelectedStr }}
            />
          </Nav.Link>
        )}
        {!isFilterEmpty && (
          <DropdownFilter
            filters={filters}
            clickDropdown={handleClickDropdownFilter}
          />
        )}
      </div>
      <div
        className="d-inline-flex align-items-center min-w-md-200px max-sm-w-100 px-1 py-1 py-md-0"
        style={{ marginLeft: "auto" }}
      >
        <div className="input-icon w-100">
          <input
            type="text"
            className="form-control"
            name="searchText"
            placeholder={messages[keyMessages["DATATABLE.TOOLBAR.SEARCH"]]}
            value={searchText}
            onChange={onChangeTextSearch}
          />
          <span>
            <i className="flaticon2-search-1 text-muted" />
          </span>
        </div>
      </div>
      <ToolBar />
    </div>
  );
};

export default DataTableFilterShort;
