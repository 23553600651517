import React from "react";
import UploadFile from "../../../../app/components/UploadFile";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

import { getIn, useField } from "formik";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export const isRequiredField = ({ validationSchema }, name) =>
  !!getIn(validationSchema.describe().fields, name).tests.find(
    testName => testName === "required"
  );

export function Upload(props2) {
  const {
    formik,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, isValid }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = false,
    customFeedbackLabel,
    type = "text",
    required,
    ...props
  } = props2;
  const { name, value, onBlur } = field || {};

  const onChange = url => {
    props2.form.setFieldValue(field.name, url);
  };

  return (
    <>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <UploadFile
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
        onChange={onChange}
        onBlur={onBlur}
      />
      {(withFeedbackLabel || errors[field.name]) && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
