import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik, useFormikContext} from "formik";
import {useIntl} from 'react-intl';
import {v4} from 'uuid';
import * as Yup from "yup";
import {keyMessages} from '../../../../../_theme/i18n';
import {classList} from "../../../../../utils/DOM/class";
import {DatePickerField, Input} from "../../../../../_theme/_partials/controls";
import {SelectionSearch} from "../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {
    CANDIDATE_RESOURCE,
    CLASS_RESOURCE,
    LOCATION_BRANCH_RESOURCE,
    PROGRAM_RESOURCE,
    PROMOTION_RESOURCE
} from "../../../../../constants/system/resource";
import {TextArea} from "../../../../../_theme/_partials/controls/forms/TextArea";
import {toCurrency, toNumber} from '../../../../../utils/filter';
import {FieldInputCurrency} from "../../../../../_theme/_partials/controls/forms/FieldInputCurrency";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import {MMDDYYYY} from "../../../../../constants/system/dateformat";
import { isEmpty } from '../../../../../utils/typeof';

const FormContent = (props) => {
    const {
        isStudent
    } = props;
    const intl = useIntl();
    const messages = intl?.messages;
    const formikContext = useFormikContext();
    const {
        initialValues,
        values,
        submitForm,
        setFieldValue,
        setErrors,
        setFieldTouched
    } = formikContext;
    const {
        location_branch,
        program,
        class: classStudent,
        start_time,
        fee,
        document_cost,
        discount,
        debt
    } = values || {};
    const filterClass = {
        filter_location_branch_id: location_branch?.id,
        filter_program_id: program?.id
    };

    useEffect(() => {
        if (!initialValues.class) {
            setFieldValue('class', null, false);
        }
    }, [location_branch, program])

    useEffect(() => {
        if (classStudent && classStudent?.start_time && classStudent?.num_lesson) {
            setFieldValue('start_time', classStudent?.start_time, false);
            setFieldValue('num_period_study', classStudent?.num_lesson, false);
        }
    }, [classStudent])

    useEffect(() => {
        if (program && program?.fee && program?.document_cost) {
            setFieldValue('fee', program?.fee, true);
            setFieldValue('document_cost', program?.document_cost, true);
        }
    }, [program])

    const labelFunction = (student) => {
        if(student['english_name']){
            return student["name"] + "(" + student['english_name'] + ")";
        }

        return student['name'];
    }

    return (
        <Form className="form form-label-right">
            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <Field
                        name={isStudent ? "student" : "candidate"}
                        required
                        isDisabled={isStudent ? !!initialValues.student : !!initialValues.candidate}
                        component={SelectionSearch}
                        formatLabel={labelFunction}
                        resource={CANDIDATE_RESOURCE}
                        propertyName="english_name"
                        propertyValue="id"
                        placeholder={messages[keyMessages['SIGN_UP.FORM.CANDIDATE.PLACEHOLDER']]}
                        label={messages[keyMessages['SIGN_UP.FORM.CANDIDATE.NAME']]}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <Field
                        name="location_branch"
                        required
                        isDisabled={!!initialValues.location_branch}
                        component={SelectionSearch}
                        resource={LOCATION_BRANCH_RESOURCE}
                        propertyName="name"
                        propertyValue="id"
                        placeholder={messages[keyMessages['SIGN_UP.FORM.LOCATION_BRANCH.PLACEHOLDER']]}
                        label={messages[keyMessages['SIGN_UP.FORM.LOCATION_BRANCH.NAME']]}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <Field
                        name="program"
                        required
                        isDisabled={!!initialValues.program}
                        component={SelectionSearch}
                        resource={PROGRAM_RESOURCE}
                        propertyName="name"
                        propertyValue="id"
                        placeholder={messages[keyMessages['SIGN_UP.FORM.PROGRAM.PLACEHOLDER']]}
                        label={messages[keyMessages['SIGN_UP.FORM.PROGRAM.NAME']]}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <Field
                        name="class"
                        required
                        isDisabled={!!initialValues.class}
                        component={SelectionSearch}
                        resource={CLASS_RESOURCE}
                        propertyName="name"
                        propertyValue="id"
                        placeholder={messages[keyMessages['SIGN_UP.FORM.CLASS.PLACEHOLDER']]}
                        label={messages[keyMessages['SIGN_UP.FORM.CLASS.PLACEHOLDER']]}
                        params={filterClass}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <Field
                        type="number"
                        name="num_period_study"
                        required
                        component={Input}
                        placeholder={messages[keyMessages['SIGN_UP.FORM.NUM_PERIOD_STUDY.PLACEHOLDER']]}
                        label={messages[keyMessages['SIGN_UP.FORM.NUM_PERIOD_STUDY.NAME']]}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <DatePickerField
                        required
                        name="start_time"
                        label={messages[keyMessages['SIGN_UP.FORM.START_TIME.NAME']]}
                    />
                </div>
            </div>
            <hr/>

            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <Field
                      type="number"
                      name="fee"
                      required
                      component={FieldInputCurrency}
                      placeholder={messages[keyMessages['SIGN_UP.FORM.FEE.PLACEHOLDER']]}
                      label={messages[keyMessages['SIGN_UP.FORM.FEE.NAME']]}
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <Field
                      type="number"
                      name="document_cost"
                      required
                      component={FieldInputCurrency}
                      placeholder={messages[keyMessages['SIGN_UP.FORM.DOCUMENT_COST.PLACEHOLDER']]}
                      label={messages[keyMessages['SIGN_UP.FORM.DOCUMENT_COST.NAME']]}
                    />
                </div>
            </div>
            <div className={"row form-group"}>
                <div className="col-md-6 col-sm-12">
                    <Field
                      type="number"
                      name="discount"
                      component={FieldInputCurrency}
                      placeholder={messages[keyMessages['SIGN_UP.FORM.DISCOUNT.PLACEHOLDER']]}
                      label={messages[keyMessages['SIGN_UP.FORM.DISCOUNT.NAME']]}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <DatePickerField
                      name="fee_deadline"
                      label="Hạn đóng tiền"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <Field
                      type="number"
                      name="num_period"
                      required
                      component={Input}
                      label="Tổng số buổi đóng tiền"
                    />
                </div>

            </div>

            <div className="form-group row">
                <div className="col-md-6">
                    <label>
                        {messages[keyMessages['SIGN_UP.FORM.TOTAL']]}:
                        <Badge variant={"success"}>{toCurrency(toNumber(fee || 0) + toNumber(document_cost || 0) - toNumber(discount || 0))}</Badge>
                    </label>
                </div>

            </div>
            <div className={"form-group row"}>
                <div className="col-sm-12">
                    <Field
                      name="promotions"
                      isMulti
                      component={SelectionSearch}
                      resource={PROMOTION_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      placeholder={messages[keyMessages['SIGN_UP.FORM.PROMOTION.PLACEHOLDER']]}
                      label={messages[keyMessages['SIGN_UP.FORM.PROMOTION.NAME']]}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-12">
                    <Field
                      name="fee_description"
                      component={TextArea}
                      placeholder={messages[keyMessages['SIGN_UP.FORM.NOTE.PLACEHOLDER']]}
                      label={messages[keyMessages['SIGN_UP.FORM.NOTE.NAME']]}
                    />
                </div>
            </div>
        </Form>
    )
}

export function SignUpLearnForm({save, item, actionsLoading, onHide, isStudent, initData}) {
    const intl = useIntl();
    const messages = intl?.messages;
    const [key, setKey] = useState(v4())
    const EditSchema = useMemo(() => {
        setKey(v4());
        if (isStudent) {
            return Yup.object().shape({
                student: Yup.string()
                    .nullable()
                    .required('Bạn chưa chọn ứng viên'),
                location_branch: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.LOCATION_BRANCH.REQUIRE']]),
                program: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.PROGRAM.REQUIRE']]),
                class: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.CLASS.REQUIRE']]),
                num_period_study: Yup.number()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.NUM_PERIOD_STUDY.REQUIRE']]),
                document_cost: Yup.number()
                    .nullable(true)
                    .required(messages[keyMessages['SIGN_UP.FORM.DOCUMENT_COST.REQUIRE']]),
                fee: Yup.number()
                    .nullable(true)
                    .required(messages[keyMessages['SIGN_UP.FORM.FEE.REQUIRE']]),
                start_time: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.START_TIME.REQUIRE']]),
                num_period: Yup.number()
                    .required("Tổng sô buổi không được để trống")
                    .min(1, "Tổng số buổi > 0"),
            });
        } else {
            return Yup.object().shape({
                candidate: Yup.string()
                    .nullable()
                    .required('Bạn chưa chọn ứng viên'),
                location_branch: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.LOCATION_BRANCH.REQUIRE']]),
                program: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.PROGRAM.REQUIRE']]),
                class: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.CLASS.REQUIRE']]),
                document_cost: Yup.number()
                    .nullable(true)
                    .required(messages[keyMessages['SIGN_UP.FORM.DOCUMENT_COST.REQUIRE']]),
                fee: Yup.number()
                    .nullable(true)
                    .required(messages[keyMessages['SIGN_UP.FORM.FEE.REQUIRE']]),
                start_time: Yup.string()
                    .nullable()
                    .required(messages[keyMessages['SIGN_UP.FORM.START_TIME.REQUIRE']]),
            });
        }
    }, [isStudent, item]);

    const generateInitData = (item) => {
        if (item?.class_fee) {
            return {
                ...item,
                ...item.class_fee,
                fee_deadline: moment(Date.now()).format(MMDDYYYY).toString(),
                id: item.id
            }
        }

        return item;
    }
    return (
        <>
            <Formik
                key={`sadsad-${key}`}
                enableReinitialize={true}
                initialValues={generateInitData(item) || initData}
                validationSchema={EditSchema}
                onSubmit={values => {
                    let entity;

                    if (values.num_period <= 0) {
                    }
                    if (isStudent) {
                        entity = {
                            ...values,
                            program_id: values.program?.id,
                            location_branch_id: values.location_branch?.id,
                            candidate_id: values.student.id,
                            class_id: values.class?.id,
                            promotion_ids: (values.promotions || []).map(p => p.id),
                            student_id: values.student.id
                        }
                    } else {
                        entity = {
                            ...values,
                            program_id: values.program?.id,
                            location_branch_id: values.location_branch?.id,
                            candidate_id: values.candidate.id,
                            class_id: values.class?.id,
                            promotion_ids: (values.promotions || []).map(p => p.id),
                            student_id: values.candidate.id
                        }
                    }

                    if (values.start_time !== item.start_time)
                        entity.start_time = values.start_time;
                    save(entity);
                }}
            >
                {({handleSubmit, values}) => (
                    <>
                        <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                            <FormContent key={key} isStudent={isStudent}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                {messages[keyMessages['FORM.CANCEL']]}
                            </button>
                            <> </>
                            <button
                                type="submit"
                                disabled={actionsLoading}
                                onClick={handleSubmit}
                                className="btn btn-primary btn-elevate"
                            >
                                {actionsLoading ? messages[keyMessages['FORM.SAVING']] : messages[keyMessages['FORM.SAVE']]}
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}
