import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import axios from "axios";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {Field, Form, Formik, useFormikContext} from "formik";
import $ from "jquery";
import React, {useEffect, useState, useMemo} from "react";
import {Modal} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import * as Yup from "yup";
import {DatePickerField} from "../../../../../../../_theme/_partials/controls";
import {FieldInputCurrency} from '../../../../../../../_theme/_partials/controls/forms/FieldInputCurrency';
import {SelectionSearch} from "../../../../../../../_theme/_partials/controls/forms/SelectionSearch";
import {TextArea} from "../../../../../../../_theme/_partials/controls/forms/TextArea";
import {CODE_SUCCESS} from '../../../../../../../constants/system/code';
import {DDMMYYYY} from "../../../../../../../constants/system/dateformat";
import {
    CLASS_RESOURCE,
    STUDENT_PAYMENT_CLASS_RESOURCE,
    STUDENT_PAYMENT_RESOURCE
} from "../../../../../../../constants/system/resource";
import reactToastify from "../../../../../../../plugin/react-toastify";
import {processError} from '../../../../../../../utils/axios';
import {classList} from "../../../../../../../utils/DOM/class";
import {toDatetime, toDateTime} from '../../../../../../../utils/filter';
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import {dowloadFile} from "../../../../../common/DataTable/_redux/FileUtils";
import {useDataTableContext} from '../../../../../common/DataTable/pages/DataTableContext';
import {DateTimeFormatterNoneHtml} from "../../../../../common/DataTable/pages/table/column-formatters/DateTimeColumnFormatter";
import {formatMoney} from "../../../../../common/DataTable/pages/table/column-formatters/PriceColumnFormatter";

// Validation schema
const EditSchema = Yup.object().shape({
    amount: Yup.number()
        .min(0, "Số tiền nộp phải lớn hơn 0")
});

const applyMoreBill = (classStudentInfo) => {
    return classStudentInfo?.debt > 0;
}

const generateFeeInfo = (classStudentInfo) => {
    return (<>
            <div>
            <span
                className="font-weight-bold font-size-lg">Tiền học phí: </span> {formatMoney(classStudentInfo.fee, "VNĐ")}
                <span className="float-right">
                <span
                    className="font-weight-bold font-size-lg">Tiền tài liệu: </span> {formatMoney(classStudentInfo.document_cost, "VNĐ")}
              </span>
            </div>
            <div>
              <span
                  className="font-weight-bold font-size-lg">Miễn Giảm: </span> {formatMoney(classStudentInfo.discount, "VNĐ")}
                <span className="float-right">
                <span
                    className="font-weight-bold font-size-lg">Đã nộp: </span> {formatMoney(classStudentInfo.paid, "VNĐ")}
              </span>
            </div>
            <div>
            <span
                className="font-weight-bold font-size-lg">Số tiền phải đóng: </span>
                <Badge variant={'primary'}>{formatMoney(classStudentInfo.must_pay, "VNĐ")}</Badge>
            </div>
            <div>
            <span
                className="font-weight-bold font-size-lg">Số tiền còn nợ: </span>
                <Badge variant={'danger'}>{formatMoney(classStudentInfo.debt, "VNĐ")}</Badge>
            </div>
            <div>
                    <span
                        className="font-weight-bold font-size-lg">Diễn giải học phí: </span> {classStudentInfo.fee_description}
            </div>
        </>
    )
}

const ModelDetailContent = (props) => {
    const {
        classId,
        studentId,
        loadStudentPaymentHistory,
        paymentHistories,
        exportBill,
        getPaymentDetail,
        deletePayment,
        item,
        classStudentInfo,
        saveChooseBills
    } = props;

    const [billSelected, setBillSelect] = useState([]);

    const formikContext = useFormikContext();

    const {
        values,
    } = formikContext;

    const {
        bills
    } = values || {};

    useEffect(() => {
        if (bills) {
            setBillSelect(bills.map(item => item.id));
        }
    }, [bills]);


    return (
        <div>
            <div>
                    <span
                        className="font-weight-bold font-size-lg">Học sinh: </span> {item?.student && (item?.student.name)}
            </div>
            <div>
                    <span
                        className="font-weight-bold font-size-lg">Lớp học: </span> {item?.class && (item?.class?.name)}
            </div>
            {generateFeeInfo(classStudentInfo)}
            <hr/>
            {applyMoreBill(classStudentInfo) && <div className="mt-5">
                <div className="form-group row">
                    <div className="col-sm-10">
                        <Field
                            key={`bills-${studentId}`}
                            required
                            name="bills"
                            component={SelectionSearch}
                            resource={`/api/student/${studentId}/payment-available`}
                            propertyName="lableDisplay"
                            propertyValue="id"
                            placeholder="Chọn phiếu thu còn dư"
                            label="Chọn phiếu thu còn dư"
                            isMulti={true}
                            customOption={options => {
                                return options.map(option => {
                                    const lableDisplay = `Hóa đơn ${DateTimeFormatterNoneHtml(option.created_at, DDMMYYYY)} (Dư ${formatMoney(option.remain, "VNĐ")})`

                                    return {
                                        ...option,
                                        lableDisplay: lableDisplay
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="col-sm-2 mt-9">
                        <Button variant="contained" color="secondary">
                            <span onClick={() => saveChooseBills(billSelected)}>Lưu</span>
                        </Button>
                    </div>
                </div>
            </div>}
            <div>
                <Table bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Số tiền</th>
                        <th>Ngày</th>
                        <th className="w-50">Ghi chú</th>
                        <th className="text-right">Hành động</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        paymentHistories.map((payment, index) => {
                            return (
                                <tr key={`payment-${index}-${payment?.id}`}>
                                    <td>{index + 1}</td>
                                    <td>{formatMoney(payment.amount, "VNĐ")}</td>
                                    <td>{toDateTime(payment.created_at)}</td>
                                    <td>{payment.note}</td>
                                    <td className="text-right">
                                        <Tooltip title="Phiếu thu">
                                            <div
                                                onClick={(e) => {
                                                    exportBill(payment.id)
                                                }}
                                                className="d-inline-block pointer px-1"
                                            >
                                                <ReceiptIcon color="primary"/>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Xóa">
                                            <div
                                                onClick={(e) => {
                                                    if (window.confirm("Bạn có muốn xóa bản ghi học phí này!"))
                                                        deletePayment(payment.id);
                                                }}
                                                className="d-inline-block pointer px-1"
                                            >
                                                <DeleteIcon color="error"/>
                                            </div>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td>Tổng</td>
                        <td>{formatMoney(paymentHistories.map(item => item.amount).reduce((a, b) => a + b, 0), "VNĐ")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </Table>

            </div>
        </div>
    )
}

export function StudentPayFeeForm({item, onHide}) {
    const dataTableContext = useDataTableContext();
    const dataTableProps = useMemo(() => {
        return {
            reloadPage: dataTableContext?.reloadPage,
        };
    }, [dataTableContext]);

    const [paymentHistories, setPaymentHistory] = useState([]);
    const [classStudentInfo, setClassStudentInfo] = useState({});
    const [editData, setEditData] = useState({});
    const [isInitLoading, setInitLoading] = useState(false);
    const [isLoadingPaymentFee, setLoadingPaymentFee] = useState(false);
    const [isLoadingDetail, setLoadingDetail] = useState(false);
    const [isLoadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
    const [isShowModalPaymentFee, setShowModalPaymentFee] = useState(false);
    const [reRender, setReRender] = useState(false);
    const studentId = item?.student_id;
    const classId = item?.class_id;
    const showDialogAdd = item?.is_add;

    useEffect(() => {
        setInitLoading(true);
    }, [studentId])

    useEffect(() => {
        if (showDialogAdd) {
            showModalPaymentFee();
        } else {
            showModalDetail();
        }
        if (studentId) {
            loadStudentPaymentHistory(item.class_id, item.student_id);
            loadStudent(item.class_id, item.student_id);
            setEditData({
                amount: 1,
                created_at: Date.now()
            });
        }
    }, [studentId, reRender]);


    const loadStudentPaymentHistory = (classId, studentId) => {
        const url = CLASS_RESOURCE + `/${classId}/student/${studentId}/payment-history`;
        setLoadingPaymentHistory(true);
        axios.get(url)
            .then(response => {
                setPaymentHistory(response.data.data);
                setLoadingPaymentHistory(false);
                setInitLoading(false);
            }).catch(error => {
            console.log(error);
            setInitLoading(false);
            setLoadingPaymentHistory(false);
        });
    }

    const generateStudentInfo = (item) => {
        return {
            ...item,
            ...item.class_fee,
            id: item.id
        }
    }

    const loadStudent = (classId, studentId) => {
        const url = CLASS_RESOURCE + `/${classId}/student/${studentId}/detail`;
        setLoadingDetail(true);
        axios.get(url)
            .then(response => {
                setClassStudentInfo(generateStudentInfo(response.data.data));
                setLoadingDetail(false);
                setInitLoading(false);
            }).catch(error => {
            setLoadingDetail(false);
            setInitLoading(false);
            console.log(error);
        });
    }

    const insertPayment = (data) => {
        const url = STUDENT_PAYMENT_RESOURCE + "/store"
        setLoadingPaymentFee(true);
        axios.post(url, data)
            .then(response => {
                setLoadingPaymentFee(false);
                showModalDetail();
                setReRender(!reRender);
                if (dataTableProps?.reloadPage) {
                    dataTableProps.reloadPage();
                }

                if (response.data?.code !== CODE_SUCCESS) {
                    if (response.data?.message) {
                        reactToastify.error(response.data?.message);
                    } else {
                        reactToastify.actionFail();
                    }
                }
            }).catch(error => {
            setLoadingPaymentFee(false);
            processError(error);
        });
    }

    const updatePayment = (data) => {
        const url = STUDENT_PAYMENT_RESOURCE + "/update"
        setLoadingPaymentFee(true);
        axios.post(url, data)
            .then(response => {
                setReRender(!reRender);
                setLoadingPaymentFee(false);
                if (dataTableProps?.reloadPage) {
                    dataTableProps.reloadPage();
                }

                if (response.data?.code !== CODE_SUCCESS) {
                    if (response.data?.message) {
                        reactToastify.error(response.data?.message);
                    } else {
                        reactToastify.actionFail();
                    }
                }
            }).catch(error => {
            setLoadingPaymentFee(false);
            processError(error);
        });

    }

    const getPaymentDetail = (paymentId) => {
        const url = STUDENT_PAYMENT_RESOURCE + "/detail?id=" + paymentId;

        axios.get(url)
            .then(response => {
                setEditData(response.data.data)
                const payment = response.data.data.payment_method;
                $("input[value=" + payment + "]").prop("checked", true);
                showModalPaymentFee();
            }).catch(error => console.log(error));
    }

    const deletePayment = (paymentId) => {
        setLoadingPaymentFee(true);
        requestFromServer.deleteEntity(STUDENT_PAYMENT_CLASS_RESOURCE, paymentId)
            .then(response => {
                setLoadingPaymentFee(false);
                reactToastify.success("Xóa thành công!!!");
                setReRender(!reRender);
            }).catch(err => {
            console.log(err);
            setLoadingPaymentFee(false);
            reactToastify.error("Xóa thất bại!!!");
        });
    }

    const exportBill = (paymentId) => {
        const url = "/api/export/student-payment/" + paymentId;
        return dowloadFile(url);
    }

    const saveChooseBills = (payment_ids) => {
        if (payment_ids.length === 0) {
            reactToastify.error("Bạn chưa chọn hóa đơn!");
            return;
        }

        const url = "api/student-payment/link-class";
        const data = {
            "student_id": studentId,
            "class_id": classId,
            "payment_ids": payment_ids,
            "class_fee_id": item?.class_fee_id
        };
        axios.post(url, data)
            .then(res => {
                const {total, data, code} = res.data;
                if (code === 0) {
                    reactToastify.success("Cập nhật thành công!!!");
                    setReRender(!reRender);
                } else {
                    reactToastify.error(data.message);
                }
            }).catch(err => {
            reactToastify.error("Đã có lỗi xảy ra!!!");
            console.log(err);
        });
    };

    const showModalDetail = () => {
        setShowModalPaymentFee(false);
    }

    const showModalPaymentFee = () => {
        setShowModalPaymentFee(true);
    }

    const billInputForm = () => {

        return (<Form className="form form-label-right">
            <div className="form-group row">
                <div className="col-sm-6">
                    <Field
                        name="amount"
                        required
                        component={FieldInputCurrency}
                        placeholder="Nhập số tiền"
                        label="Số tiền nộp(VNĐ)"
                    />
                </div>
                <div className="col-sm-6">
                    <DatePickerField
                        timePicker
                        required
                        name="created_at"
                        label="Ngày nộp tiền"
                        placeholder="hh:mm dd/mm/yyyy"
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-12 row">
                    <span className="col-md-3">Hình thức: </span>

                    <div className="col-md-4">
                        <input name="payment_method" type="radio" value="bank_transfer"
                               defaultChecked={true}/> Chuyển
                        khoản
                    </div>
                    <div className="col-md-3">
                        <input name="payment_method" type="radio" value="cash"/> Tiền mặt
                    </div>
                    <div className="col-md-2">
                        <input name="payment_method" type="radio" value="pos"/> POS
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-12">
                    <Field
                        name="note"
                        component={TextArea}
                        placeholder="Nhập nội dung thanh toán"
                        label="Nội dung thanh toán"
                        rows="3"
                    />
                </div>
            </div>
        </Form>)
    }

    if (isInitLoading) {
        return (
            <div className="zindex-2 min-h-130px d-flex align-items-center justify-content-center m-auto">
                <div className="spinner-border text-success"/>
            </div>
        )
    }
    const isLoading = isLoadingDetail || isLoadingPaymentHistory;

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={editData}
                validationSchema={EditSchema}
                onSubmit={(values, formikHelpers) => {
                    const method = $("input[name='payment_method']:checked").val();
                    const entity = {
                        payment_method: method,
                        amount: values.amount,
                        student_id: classStudentInfo.student_id,
                        class: [{
                            class_id: classStudentInfo.class_id,
                            amount: values.amount
                        }],
                        id: values.id,
                        note: values.note,
                        created_at: toDatetime(values?.created_at),
                        class_fee_id: classStudentInfo?.class_fee_id
                    };

                    if (entity.id) {
                        updatePayment(entity);
                    } else {
                        insertPayment(entity);
                    }
                }}
            >
                {({handleSubmit}) => (
                    <>
                        <Modal.Body className={classList("overlay")}>
                            {
                                !isShowModalPaymentFee &&
                                (
                                    <div className={classList("overlay", isLoading && "overlay-block")}>
                                        {isLoading && (
                                            <div className="overlay-layer zindex-2 bg-transparent">
                                                <div className="spinner-border text-success"/>
                                            </div>
                                        )}
                                        <ModelDetailContent
                                            studentId={studentId}
                                            classId={classId}
                                            loadStudentPaymentHistory={loadStudentPaymentHistory}
                                            paymentHistories={paymentHistories}
                                            exportBill={exportBill}
                                            getPaymentDetail={getPaymentDetail}
                                            deletePayment={deletePayment}
                                            item={item}
                                            classStudentInfo={classStudentInfo}
                                            reRender={reRender}
                                            saveChooseBills={saveChooseBills}
                                        />
                                    </div>
                                )
                            }

                            {
                                isShowModalPaymentFee &&
                                (
                                    <div
                                        className={classList("overlay", isLoadingPaymentFee && "overlay-block")}
                                    >
                                        {isLoadingPaymentFee && (
                                            <div className="overlay-layer zindex-2 bg-transparent">
                                                <div className="spinner-border text-success"/>
                                            </div>
                                        )}
                                        <div>
                                            <div>
                    <span
                        className="font-weight-bold font-size-lg">Học sinh: </span> {item?.student && (item?.student?.name)}
                                            </div>
                                            <div>
                                                <span className="font-weight-bold font-size-lg">Lớp học: </span>
                                                {item?.class && (item?.class?.name)}
                                            </div>
                                            {classStudentInfo?.class_fee && generateFeeInfo(classStudentInfo)}
                                        </div>
                                        <br/>
                                        {billInputForm()}
                                    </div>
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                !isShowModalPaymentFee &&
                                (
                                    <button
                                        type="button"
                                        onClick={onHide}
                                        className="btn btn-light btn-elevate modal-detail"
                                    >
                                        Đóng
                                    </button>
                                )
                            }
                            {
                                isShowModalPaymentFee &&
                                (
                                    <button
                                        type="button"
                                        disabled={isLoadingPaymentFee}
                                        onClick={() => showModalDetail()}
                                        className="btn btn-light btn-elevate modal-payment-fee"
                                    >
                                        Danh sách giao dịch
                                    </button>
                                )
                            }
                            {
                                applyMoreBill(classStudentInfo) &&
                                !isShowModalPaymentFee &&
                                (
                                    <button
                                        className="btn btn-dark btn-elevate modal-detail"
                                        onClick={() => showModalPaymentFee()}
                                    >
                                        Đóng học phí
                                    </button>
                                )
                            }
                            {
                                isShowModalPaymentFee &&
                                (
                                    <button
                                        type="submit"
                                        disabled={isLoadingPaymentFee}
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary btn-elevate"
                                    >
                                        {isLoadingPaymentFee ? "Đang lưu..." : "Lưu lại"}
                                    </button>
                                )
                            }
                        </Modal.Footer>
                    </>)}
            </Formik>
        </>
    );
}
