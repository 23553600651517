import { URL_AUDIO_REGEX, URL_IMAGE_REGEX, URL_VIDEO_REGEX } from './regex';

export const getFileNameByUrl = (url) => {
  if (url) {
    const m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1 && m[1]) {
      return m[1];
    } else {
      const pathArray = url.split("/");
      return pathArray[pathArray?.length - 1]
    }
  }
  return "";
};

export const getFileTypeByUrl = (url) => {
  if (!url) {
    return "other";
  } else if (URL_IMAGE_REGEX.test(url)) {
    return "image";
  } else if (URL_VIDEO_REGEX.test(url)) {
    return "video";
  } else if (URL_AUDIO_REGEX.test(url)) {
    return "audio";
  } else {
    return "other";
  }
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
