import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../../_theme/_partials/controls";
import { USER_STATUS } from '../../../../../constants/system/dataSelect';
import AnimateLoading from '../../../../components/AnimateLoading';
import { DataTableFilter } from './filter/DataTableFilter';
import { useDataTableUIContext } from './DataTableUIContext';
import { DataTableGrouping } from './grouping/DataTableGrouping';

import { DataTableCustom } from './table/DataTableCustom';
import {DataTableHeaderButton} from "./DataTableHeaderButton";

export function DataTableCard(props) {
  const {
    children
  } = props;
  const dataTableUIContext = useDataTableUIContext();
  const dataTableUIProps = useMemo(() => {
    return {
      ids: dataTableUIContext.ids,
      headers: dataTableUIContext.headers || [],
      queryParams: dataTableUIContext.queryParams,
      setQueryParams: dataTableUIContext.setQueryParams,
      clickNewItem: dataTableUIContext.clickNewItem,
      clickDeleteItem: dataTableUIContext.clickDeleteItem,
      clickEditItem: dataTableUIContext.clickEditItem,
      clickViewDetailItem: dataTableUIContext.clickViewDetailItem,
    };
  }, [dataTableUIContext]);

  return (
    <Card>
      {children}
      {/*<CardHeader>*/}
        {/*<CardHeaderToolbar>*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="btn btn-primary"*/}
        {/*    onClick={dataTableUIProps.clickNewItem}*/}
        {/*  >*/}
        {/*    Thêm mới*/}
        {/*  </button>*/}
        {/*</CardHeaderToolbar>*/}
      {/*</CardHeader>*/}
      <CardBody className="px-0 py-0 data-table-paging">
        <DataTableFilter />
        {/*<Autocomplete*/}
        {/*  className="mobile-sort px-4 pb-2"*/}
        {/*  options={dataTableUIProps.headers}*/}
        {/*  getOptionLabel={(option) => option.text}*/}
        {/*  onChange={(e, item) => {*/}
        {/*    console.log(e, item);*/}
        {/*    dataTableUIProps.setQueryParams({*/}
        {/*      ...dataTableUIProps.queryParams,*/}
        {/*      sorts: [{*/}
        {/*        property: item.text,*/}
        {/*        direction: "asc"*/}
        {/*      }]*/}
        {/*    })*/}
        {/*  }}*/}
        {/*  debug*/}
        {/*  renderInput={(params) => <TextField {...params} label="Trường sắp xếp" placeholder="Chọn trường sắp xếp" margin="normal" />}*/}
        {/*/>*/}
        {/*<DataTableHeaderButton />*/}

        {/*{dataTableUIProps.ids.length > 0 && (*/}
        {/*  <>*/}
        {/*    <DataTableGrouping />*/}
        {/*  </>*/}
        {/*)}*/}
        <DataTableCustom />
      </CardBody>
    </Card>
  );
}
