import {amber, deepPurple, green} from '@material-ui/core/colors';
import React, {useMemo, useState} from "react";
import {Badge} from 'react-bootstrap';
import {DATE_RANGE, SELECT_SOURCE} from "../../../../../../../constants/filterType";
import {SCHEDULE_CLASS_STATUS} from '../../../../../../../constants/system/dataSelect';
import {RESULT_EXAM} from '../../../../../../../constants/system/permission';
import {
  CLASS_RESOURCE,
  CLASS_SCHEDULE_OFF_RESOURCE,
  CLASS_SCHEDULE_RESOURCE,
  ROOM_RESOURCE,
  TEACHER_RESOURCE,
} from "../../../../../../../constants/system/resource";
import {Admin, PROGRAM_DESIGNER, STAFF, TEACHER} from '../../../../../../../constants/system/role';
import {toDateSrt, toDateTime, toTimeSrt} from '../../../../../../../utils/filter';
import {processHeaderTable} from '../../../../../../../utils/table';
import {usePermissionContext} from '../../../../../../components/PermissionContext';
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import {DataTableCard} from "../../../../../common/DataTable/pages/DataTableCard";
import {DataTableProvider} from "../../../../../common/DataTable/pages/DataTableContext";
import {DataTableUIProvider} from "../../../../../common/DataTable/pages/DataTableUIContext";
import {DataTableDeleteDialog} from "../../../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog";
import {EditDialog} from "../../../../../common/DataTable/pages/edit-dialog/EditDialog";
import {LoadingDialog} from "../../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {ExamResultEditForm} from '../examination/ExamResultEditForm';
import {ClassScheduleForm} from './ClassScheduleForm';
import StudentCheckinForm from "../lesson/StudentCheckinForm";
import {useSelector} from "react-redux";
import {ConfirmDialog} from "../../../../../Teacher/pages/list/ConfirmDialog";
import {FormDialog} from "../../../../../common/DataTable/pages/form-dialog/FormDialog";
import axios from "axios";
import {DateTimeFormatterNoneHtml} from "../../../../../common/DataTable/pages/table/column-formatters/DateTimeColumnFormatter";
import {DDMMYYYY} from "../../../../../../../constants/system/dateformat";
import DescriptionColumnFormatter
  from "../../../../../common/DataTable/pages/table/column-formatters/DescriptionColumnFormatter";
import { HistorySendMailForm } from './HistorySendMailForm';
import {SendMailScheduleOfClass} from './SendMailScheduleOfClass';
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";

const ScheduleOfClass = (props) => {
  const intl = useIntl();
  const messages = intl?.messages;
  const dayOfWeek = [
    messages[keyMessages["SUNDAY"]],
    messages[keyMessages["MONDAY"]],
    messages[keyMessages["TUESDAY"]],
    messages[keyMessages["WEDNESDAY"]],
    messages[keyMessages["THURSDAY"]],
    messages[keyMessages["FRIDAY"]],
    messages[keyMessages["SATURDAY"]]

  ];

  const {
    actions,
    renderCell,
    classId,
    classInfo,
    startTimeClass
  } = props;
  const permissionContext = usePermissionContext();
  const {
    checkRole,
  } = permissionContext || {};

  const isUpdateLesson = checkRole([Admin, PROGRAM_DESIGNER, STAFF]);

  const [selectedData, setSelectedData] = useState();
  const [showDialogForm, setDialogForm] = useState(false);
  const [showDialogUnapproved, setDialogUnapproved] = useState(false);
  const [isShowLogSendMail, setShowLogSendMail] = useState(false);
  const [showDialogDelete, setDialogDelete] = useState(false);
  const [showDialogStudentCheckin, setDialogStudentCheckin] = useState(false);
  const [showDialogApproveCheckin, setDialogApproveCheckin] = useState(false);
  const [showDialogExamResult, setShowDialogExamResult] = useState(false);
  const [showDialogScheduleOff, setDialogScheduleOff] = useState(false);
  const [showDialogSendEmail, setShowDialogSendEmail] = useState(false);
  const {user} = useSelector(state => state.auth);
  const [indexLesson, setIndexLesson] = useState(1);

  const openDialogLogSendMail = () => setShowLogSendMail(true);
  const closeDialogLogSendMail = () => {
    setSelectedData(null);
    setShowLogSendMail(false);
  };

  const openDialogUnapproved = () => setDialogUnapproved(true);
  const closeDialogUnapproved = () => {
    setSelectedData(null);
    setDialogUnapproved(false);
  };

  const openDialogApproveCheckin = () => setDialogApproveCheckin(true);
  const closeDialogApproveCheckin = () => {
    setSelectedData(null);
    setDialogApproveCheckin(false);
  };

  const openExamResult = (data) => {
    setSelectedData(data);
    setShowDialogExamResult(true);
  }
  const closeExamResult = () => {
    setSelectedData(null);
    setShowDialogExamResult(false);
  }

  const openDialogSendEmail = (data) => {
    setSelectedData(data);
    setShowDialogSendEmail(true);
  }
  const closeDialogSendEmail = () => {
    setSelectedData(null);
    setShowDialogSendEmail(false);
  }

  const openDialogFormStudentCheckin = () => setDialogStudentCheckin(true);
  const closeDialogStudentCheckin = () => {
    setSelectedData(null);
    setDialogStudentCheckin(false);
  };

  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };
  const openDialogDelete = () => setDialogDelete(true);
  const closeDialogDelete = () => {
    setSelectedData(null);
    setDialogDelete(false);
  };

  const openDialogScheduleOff = () => setDialogScheduleOff(true);
  const closeDialogScheduleOff = () => {
    setSelectedData(null);
    setDialogScheduleOff(false);
  };

  const uiEvents = {

    clickEditItem: dataEdit => {
      openDialogForm();
      setSelectedData(dataEdit);
    },
    clickApprovalLesson: dataEdit => {
      openDialogFormStudentCheckin();
      setSelectedData(dataEdit);
    },
    clickDeleteItem: dataDelete => {
      openDialogDelete();
      setSelectedData(dataDelete);
    },
    clickApproveItem: data => {
      openDialogApproveCheckin();
      setSelectedData(data);
    },
    clickUnapproveItem: data => {
      openDialogUnapproved();
      setSelectedData(data);
    },
    clickOffSchedule: data => {
      openDialogScheduleOff();
      setSelectedData({
        ...data,
        start_time: DateTimeFormatterNoneHtml(data?.start_time, DDMMYYYY)
      });
    },
    clickSendEmail: data => {
      openDialogSendEmail(data);
    },
    fetchData: query => {
      return requestFromServer.post(CLASS_RESOURCE, `${classId}/schedule/search`, query);
    },
    newData: data => {
      return requestFromServer.createEntity(CLASS_SCHEDULE_RESOURCE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(CLASS_SCHEDULE_RESOURCE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(CLASS_SCHEDULE_RESOURCE, id);
    },
    clickViewExamResult: data => {
      openExamResult();
      setSelectedData(data);
    },
    clickShowLogSendMail: data => {
      openDialogLogSendMail();
      setSelectedData(data);
    },
    setScheduleOff: data => {
      if (data && data.id) {
        return axios.post(CLASS_SCHEDULE_OFF_RESOURCE, {
          id: data.id
        });
      }
    },
  };

  if(!permissionContext.checkRole([TEACHER])){
    uiEvents.clickNewItem = () => {
      setSelectedData(null);
      openDialogForm(true);
    }
  }


  let headers = useMemo(() => {
    let headersRaw = [
      {
        dataField: "start_time",
        text: "Date",
        sort: true,
        formatter: (cellContent) => {
          return dayOfWeek[(new Date(cellContent)).getDay()] + " " + toDateSrt(cellContent)
        },
        headerStyle: (column, colIndex) => {
          return {minWidth: "120px", maxWidth: "120px"};
        },
      },
      {
        dataField: "",
        text: messages[keyMessages["TEACHER.CLASS.DETAIL.SCHEDULE"]],
        headerStyle: (column, colIndex) => {
          return {minWidth: "150px", maxWidth: "150px"};
        },
        formatter: ((cellContent, row) => {
          return toTimeSrt(row.start_time) + " -> " + toTimeSrt(row.end_time);
        })
      },
      {
        dataField: "teacher.name",
        text: messages[keyMessages["TEACHER.CLASS.DETAIL.TEACHER"]],
        headerStyle: (column, colIndex) => {
          return {minWidth: "150px", maxWidth: "150px"};
        },
      },
      {
        dataField: "require_teacher_comment",
        text: messages[keyMessages["TEACHER.CLASS.SCHEDULE.REQUIRE_TEACHER_COMMENT"]],
        formatter: cellContent => {
          if(cellContent){
            return (
              <Badge variant="warning" className="px-2 mx-1 mt-1">
                {messages[keyMessages["YES"]]}
              </Badge>
            );
          }else{
            return (
              <Badge variant="secondary" className="px-2 mx-1 mt-1">
                {messages[keyMessages["NO"]]}
              </Badge>
            );
          }
        }
      },
      {
        dataField: "checkin_status",
        text: messages[keyMessages["TEACHER.CLASS.DETAIL.STATUS"]],
        headerStyle: (column, colIndex) => {
          return {minWidth: "150px", maxWidth: "150px"};
        },
        formatter: (cellContent) => {
          if (cellContent) {
            const status = SCHEDULE_CLASS_STATUS.find(({value}) => value === cellContent);
            if (status) {
              return (
                <Badge variant={status?.color} className="px-2 mx-1 mt-1">
                  {status?.text}
                </Badge>
              );
            } else {
              return (
                <Badge variant="secondary" className="px-2 mx-1 mt-1">
                  {cellContent}
                </Badge>
              );
            }
          }
          return null;
        }
      },
      {
        dataField: "lesson_content",
        text: messages[keyMessages["TEACHER.CLASS.SCHEDULE.CONTENT"]],
        headerStyle: (column, colIndex) => {
          return {minWidth: "200px", maxWidth: "200px"};
        },
        formatter: ((cellContent, row) => {
          return <DescriptionColumnFormatter shortNumWord={20} content={cellContent}/>
        })
      },
      {
        dataField: "lesson_homework",
        text: messages[keyMessages["TEACHER.CLASS.SCHEDULE.HOMEWORK"]],
        headerStyle: (column, colIndex) => {
          return {minWidth: "200px", maxWidth: "200px"};
        },
        formatter: ((cellContent, row) => {
          return <DescriptionColumnFormatter shortNumWord={20} content={cellContent}/>
        })
      },
      {
        dataField: "room.name",
        text: messages[keyMessages["TEACHER.CLASS.SCHEDULE.ROOM"]],
        headerStyle: (column, colIndex) => {
          return {minWidth: "150px", maxWidth: "150px"};
        },
      },
      {
        dataField: "action",
        text: "Action",
        headerStyle: (column, colIndex) => {
          return {width: "120px", textAlign: "right"};
        },
        actions: [
          {
            color: "primary",
            icon: "beenhere",
            label: "Check Attendance",
            event: uiEvents.clickApprovalLesson,
            visible: (row) => {
              return ['created', 'waiting', 'approve'].includes(row?.checkin_status);
            },
          },
          {
            color: green[500],
            icon: "spellcheck",
            label: "Duyệt buổi học",
            event: uiEvents.clickApproveItem,
            visible: (row) => {
              return ['waiting'].includes(row?.checkin_status);
            },
          },
          {
            color: "error",
            icon: "close",
            label: "Bỏ phê duyệt buổi học",
            event: uiEvents.clickUnapproveItem,
            visible: (row) => {
              return ['approve'].includes(row?.checkin_status);
            },
          },
          {
            color: amber.A700,
            icon: "assignment",
            actionName: RESULT_EXAM,
            visible: (row) => {
              return row?.examinations?.length > 0
            },
            label: messages[keyMessages["TEACHER.CLASS.SCHEDULE.MARK"]],
            event: uiEvents.clickViewExamResult
          },
          {
            color: "primary",
            icon: "edit",
            label: "Chỉnh sửa",
            event: uiEvents.clickEditItem,
            visible: (row) => {
              return isUpdateLesson && ['created', 'waiting', 'approve'].includes(row?.checkin_status);
            },
          },
          {
            color: "error",
            icon: "power_settings_new",
            label: "Thiết lập nghỉ học",
            event: uiEvents.clickOffSchedule,
            visible: (row) => {
              return ['created', 'waiting'].includes(row?.checkin_status);
            },
          },
          {
            color: amber.A700,
            icon: "history",
            label: "Lịch sử gửi mail",
            event: uiEvents.clickShowLogSendMail
          },
          {
            color: deepPurple[700],
            icon: "email",
            label: "Gửi email",
            event: uiEvents.clickSendEmail,
            visible: (row) => {
              return ['approve', 'lesson_off'].includes(row?.checkin_status);
            }
          },
          {
            color: "error",
            icon: "delete",
            label: "Xóa",
            event: uiEvents.clickDeleteItem,
            visible: (row) => {
              return row?.checkin_status === 'created' || row?.checkin_status === 'waiting';
            }
          }
        ]
      }
    ];

    headersRaw = processHeaderTable(headersRaw, {
      actions,
      renderCell
    })

    return headersRaw;
  }, [actions])

  const filters = [
    {
      type: DATE_RANGE,
      label: "Ngày học",
      name: "start_time"
    },
    {
      type: SELECT_SOURCE,
      label: "Phòng học",
      name: "room_id",
      resource: ROOM_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
    {
      type: SELECT_SOURCE,
      label: "Giáo viên",
      name: "teacher_id",
      resource: TEACHER_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
  ];

  const initData = {
    id: null,
    index: null,
    checkin_status: null,
    class: null,
    class_id: classId,
    start_time: null,
    end_time: null,
    lesson_content: "",
    lesson_homework: "",
    lesson_name: "",
    require_take_care: 0,
    room: null,
    room_id: null,
    teacher: null,
    teacher_id: null,
    require_teacher_comment: false
  };

  const onSubmitScheduleOff = ({data}) => {
    return uiEvents.setScheduleOff(data);
  }

  const onSubmitUnapproved = ({data}) => {
    if (data && data.id) {
      return axios.post(`${CLASS_SCHEDULE_RESOURCE}/unapproved`, {
        id: data.id
      });
    }
  }

  const onSubmitSendEmail = ({data}) => {
    return requestFromServer.post(CLASS_SCHEDULE_RESOURCE, 'send-email', data);
  }
  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      initData={initData}
      initialFilter={{
        filters: [],
        sorts: [],
        page: 1,
        page_size: -1
      }}
      sizePerPageList={[
        { text: "Tất cả", value: -1 },
      ]}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <FormDialog
          show={showDialogUnapproved}
          title={`Bỏ phê duyệt buổi học`}
          initData={{
            ...selectedData,
            content: `Bạn có chắc muốn bỏ phê duyệt buổi học vào lúc: ${toDateTime(selectedData?.start_time)}`
          }}
          messageSuccess="Bỏ phê duyệt buổi học thành công"
          selectedData={selectedData}
          onHide={closeDialogUnapproved}
          onSubmit={onSubmitUnapproved}
          ComponentForm={ConfirmDialog}
        />
        <FormDialog
          size="xl"
          show={isShowLogSendMail}
          title={`Lịch sử gửi mail của buổi học ${selectedData && dayOfWeek[(new Date(selectedData?.start_time)).getDay()] + " " + toDateSrt(selectedData?.start_time)} ${selectedData && toTimeSrt(selectedData?.start_time)} -> ${selectedData && toTimeSrt(selectedData?.end_time)}`}
          scheduleId={selectedData?.id}
          messageSuccess="Bỏ phê duyệt buổi học thành công"
          selectedData={selectedData}
          onHide={closeDialogLogSendMail}
          ComponentForm={HistorySendMailForm}
        />
        <EditDialog
          id={selectedData && selectedData.id}
          show={showDialogForm}
          item={selectedData}
          classInfo={classInfo}
          title={
            selectedData ? "Cập nhật thông tin lịch học" : "Thêm lịch học cho lớp"
          }
          classId={classId}
          startTimeClass={startTimeClass}
          onHide={closeDialogForm}
          ComponentForm={ClassScheduleForm}
        />
        <EditDialog
          id={selectedData && selectedData.id}
          show={showDialogStudentCheckin}
          onHide={closeDialogStudentCheckin}
          isEditCheckin
          item={selectedData && {
            ...selectedData,
            urlApi: CLASS_SCHEDULE_RESOURCE + `/${selectedData.id}/student-checkin/update-all`
          }}
          title={`Class: ${selectedData?.class?.name}`}
          ComponentForm={StudentCheckinForm}
          size='xl'
        />
        <FormDialog
          id={selectedData?.id}
          size="xl"
          classId={classId}
          show={showDialogSendEmail}
          item={{
            lesson_id: selectedData?.id,
            mail_type: "",
          }}
          title={`Gửi email cho buổi học ${toDateTime(selectedData?.start_time)}`}
          ComponentForm={SendMailScheduleOfClass}
          onHide={closeDialogSendEmail}
          onSubmit={onSubmitSendEmail}
        />
        <FormDialog
          show={showDialogScheduleOff}
          title={`Thiết lập nghỉ học`}
          initData={{
            ...selectedData,
            content: `Bạn có chắc muốn thiết lập nghỉ học vào lúc: ${toDateTime(selectedData?.start_time)}`
          }}
          messageSuccess="Thiết lập nghỉ học thành công"
          selectedData={selectedData}
          onHide={closeDialogScheduleOff}
          onSubmit={onSubmitScheduleOff}
          ComponentForm={ConfirmDialog}
        />
        <DataTableDeleteDialog
          id={selectedData && selectedData.id}
          show={showDialogDelete}
          onHide={closeDialogDelete}
          title="Xóa lịch học"
          content={
            <div>
              Bạn chắc chắn muốn xóa buổi học&nbsp;
              <span className="text-danger">
                {selectedData && selectedData.lesson_name}
              </span>
            </div>
          }
        />
        <EditDialog
          size={"lg"}
          id={selectedData?.id}
          isReadOnly={['lesson_off'].includes(selectedData?.checkin_status)}
          show={showDialogExamResult}
          onHide={closeExamResult}
          item={selectedData}
          title={`Chấm bài kiểm tra lớp ${selectedData?.class.name}`}
          ComponentForm={ExamResultEditForm}
        />
        <EditDialog
          id={selectedData?.id}
          show={showDialogApproveCheckin}
          onHide={closeDialogApproveCheckin}
          item={{
            ...selectedData,
            urlApi: CLASS_SCHEDULE_RESOURCE + `/approve`
          }}
          title={`Duyệt buổi học lớp: ${selectedData?.class?.name}`}
          ComponentForm={StudentCheckinForm}
          size="lg"
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
};

ScheduleOfClass.propTypes = {};

ScheduleOfClass.defaultProps = {};

export default ScheduleOfClass;
