import {useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {classList} from "../../../../../../../utils/DOM/class";
import {DateTimeFormatter} from "../../../../../common/DataTable/pages/table/column-formatters/DateTimeColumnFormatter";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {DDMMYYYY} from "../../../../../../../constants/system/dateformat";
import Table from "react-bootstrap/Table";
import {EXAMINATION_RESOURCE} from "../../../../../../../constants/system/resource";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import axios from "axios";
import reactToastify from "../../../../../../../plugin/react-toastify";
import {CODE_SUCCESS} from "../../../../../../../constants/system/code";
import {uploadFile} from "../../../../../common/DataTable/_redux/FileUtils";
import {isEmpty, isString} from '../../../../../../../utils/typeof';
import ExamStudent from './ExamStudent';
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";

const EditSchema = Yup.object().shape({});


export function ExamResultEditForm({save, item, actionsLoading, onHide, isReadOnly}) {
    const intl = useIntl();
    const messages = intl?.messages;

    const {classId} = useParams();
    const [studentResults, setStudentResult] = useState([]);
    const [skills, setSkill] = useState([]);
    const [isDeteled, setIsDeleted] = useState(false);

    const [examinations, setExaminations] = useState(null);
    const [examination, setExamination] = useState(null);

    useEffect(() => {
        if (item) {
            if (item?.examinations) {
                setExaminations(item.examinations);
                setExamination(item.examinations[0]);
            } else {
                setExaminations(null);
                setExamination(item);
            }
        }
    }, [item])

    let inputFile = React.createRef();

    useEffect(() => {
        if (examination && examination?.id) {
            loadStudentResult();
            setSkill(examination?.skills || []);
        }
    }, [examination?.id]);

    const loadStudentResult = () => {
        const url = EXAMINATION_RESOURCE + `/${examination?.id}`;
        requestFromServer.findEntities(url, {
            page: 1,
            page_size: -1
        })
            .then(res => {
                let data = res.data.data;
                if (data) {
                    data = data.map(studentResult => {
                        if (isString(studentResult?.result) && studentResult?.result) {
                            try {
                                const studentSkillResult = JSON.parse(studentResult?.result);
                                studentSkillResult.forEach(studentSkill => {
                                    studentResult[studentSkill.code] = studentSkill.score;
                                })
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        return studentResult;
                    })
                    setStudentResult(data);
                }
            })
            .catch(err => console.log(err));
    }

    const updateResult = () => {
        const url = EXAMINATION_RESOURCE + `/${examination?.id}/update-result`;

        const results = studentResults.map((studentResult) => {
            return {
                ...studentResult,
                id: studentResult?.id,
                media: studentResult?.media,
                result: studentResult?.examination?.skills.map(skill => ({
                    "code": skill?.code,
                    "score": parseInt(studentResult?.[skill?.code]),
                    "num_question": skill?.num_question
                }))
            }
        })

        const body = {
            examination_id: examination?.id,
            results: results
        };

        axios.post(url, body)
            .then(res => {
                reactToastify.success("Success!!!");
                onHide();
            })
            .catch(err => console.log(err));
    }

    const onChangeResult = (studentId, e, code) => {
        const indexStudent = studentResults.findIndex(student => student?.student?.id === studentId)
        if (indexStudent !== -1) {
            setStudentResult([
                ...studentResults.slice(0, indexStudent),
                {
                    ...studentResults[indexStudent],
                    [code]: e.target.value
                },
                ...studentResults.slice(indexStudent + 1),
            ])
        }
    }

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const onUploadFileResult = async (studentId, e) => {
        e.stopPropagation();
        e.preventDefault();
        const file = e.target.files[0];
        const indexStudent = studentResults.findIndex(studentResult =>
            studentResult?.student?.id == studentId)

        if (indexStudent !== -1) {
            setStudentResult([
                ...studentResults.slice(0, indexStudent),
                {
                    ...studentResults[indexStudent],
                    mediaUploading: true
                },
                ...studentResults.slice(indexStudent + 1),
            ])

            uploadFile(file).then(response => {
                if (response.data?.code === CODE_SUCCESS) {
                    const urlFile = response.data?.data?.url;

                    setStudentResult([
                        ...studentResults.slice(0, indexStudent),
                        {
                            ...studentResults[indexStudent],
                            mediaUploading: false,
                            media: urlFile
                        },
                        ...studentResults.slice(indexStudent + 1),
                    ])
                } else {
                    reactToastify.error("Upload file fail !!!");
                }
            });
        }
    }

    const onRemoveFileResult = (studentId, e) => {
        if (!window.confirm("Do you want to delete this file ?"))
            return;

        const indexStudent = studentResults.findIndex(student => student?.student?.id === studentId)
        if (indexStudent !== -1) {
            setStudentResult([
                ...studentResults.slice(0, indexStudent),
                {
                    ...studentResults[indexStudent],
                    mediaUploading: false,
                    media: null
                },
                ...studentResults.slice(indexStudent + 1),
            ])
        }
    }

    const onChangeExam = (e) => {
        const examSelected = examinations.find(exam => exam?.id === parseInt(e.target.value, 10));
        setExamination(examSelected);
    }
    console.log(item)

    return (
        <>
            <Modal.Body className={classList("overlay")}>
                <div>
                    <div className="row">
                    </div>
                    <div className="row">
                        <div className="col-md-3">{messages[keyMessages["TEACHER.EXAM_FORM.TITLE"]]}</div>
                        <div className="col-md-9">
                            {
                                !isEmpty(examinations) &&
                                (
                                    <select
                                        name="choice-exam"
                                        className={"select"}
                                        value={examination?.id}
                                        style={{width: '100%'}}
                                        onChange={onChangeExam}
                                    >
                                        {
                                            examinations.map((examinationOption, index) => {
                                                return (
                                                    <option
                                                        key={`examination-option-${examinationOption?.id}-${index}`}
                                                        value={examinationOption?.id}
                                                        defaultValue={examination?.id}
                                                    >
                                                        {examinationOption?.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                )
                            }
                            {
                                isEmpty(examinations) && examination?.name
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            {messages[keyMessages["TEACHER.EXAM_FORM.TYPE"]]}:
                        </div>
                        <div className="col-md-9">
                        <span
                            className="font-weight-bold font-size-lg">{examination?.type} </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">{messages[keyMessages["TEACHER.EXAM_FORM.DATE"]]}:</div>
                        <div className="col-md-9">
                        <span
                            className="font-weight-bold font-size-lg">{DateTimeFormatter(examination?.start_time, DDMMYYYY)}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">{messages[keyMessages["TEACHER.EXAM_FORM.ATTACH"]]}</div>
                        <div className="col-md-12">
                            {
                                examination?.media?.map((url, index) => {
                                    return (<a href={url}>{url}</a>)
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="overflow-auto border-secondary border mt-3">
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th style={{minWidth: '80px', maxWidth: '80px'}}></th>
                            <th style={{minWidth: '170px', maxWidth: '170px'}}>{messages[keyMessages["TEACHER.EXAM_FORM.TABLE.STUDENT"]]}</th>
                            <th style={{minWidth: '170px', maxWidth: '170px'}}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.STUDENT_NAME_ES"]]}</th>
                            {
                                skills?.map(s => {
                                    return (
                                        <th
                                            key={`skill-${s?.id}`}
                                            className="text-right white-space-pre-wrap"
                                            style={{
                                                width: '100px',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {s.name || s.code}
                                        </th>
                                    )
                                })
                            }
                            <th style={{minWidth: '270px', maxWidth: '270px'}}>{messages[keyMessages["TEACHER.EXAM_FORM.TABLE.COMMENT"]]}</th>
                            <th style={{minWidth: '100px', maxWidth: '120px'}}>{messages[keyMessages["TEACHER.EXAM_FORM.TABLE.TOTAL"]]}</th>
                            <th style={{minWidth: '100px', maxWidth: '150px'}} className="text-right">{messages[keyMessages["TEACHER.EXAM_FORM.ATTACH"]]}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            studentResults.map((studentResult, index) => {
                                let studentId = studentResult.student.id;
                                return (
                                    <ExamStudent
                                        key={`exam-student-${studentId}-${index}`}
                                        index={index}
                                        isReadOnly={isReadOnly}
                                        studentResult={studentResult}
                                        skills={skills}
                                        onUploadFileResult={onUploadFileResult}
                                        onRemoveFileResult={onRemoveFileResult}
                                        onChangeResult={onChangeResult}
                                    />
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    isReadOnly &&
                    (
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-primary btn-elevate"
                        >
                            {messages[keyMessages["TEACHER.EXAM_FORM.TABLE.CLOSE"]]}
                        </button>
                    )
                }
                {
                    !isReadOnly &&
                    (
                        <>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Hủy
                            </button>
                            <button
                                type="submit"
                                disabled={actionsLoading}
                                onClick={() => updateResult()}
                                className="btn btn-primary btn-elevate"
                            >
                                {actionsLoading ? messages[keyMessages["FORM.SAVING"]] :
                                    messages[keyMessages["FORM.SAVE"]]}
                            </button>
                        </>
                    )
                }
            </Modal.Footer>
        </>

    );
}
