import { cloneDeep } from "lodash";
import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import { processError } from "../../../../../utils/axios";
import { useDataTableUIContext } from "./DataTableUIContext";
export const CALL_TYPES = {
  list: "list",
  action: "action"
};

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  lastError: null
};

const DataTableContext = createContext();

export function useDataTableContext() {
  return useContext(DataTableContext);
}

export const DataTableUIConsumer = DataTableContext.Consumer;

export function DataTableProvider(props) {
  const { children, pageName } = props;
  const fetchId = useRef();
  const [entitiesSlice, setEntitiesSlice] = useState(initialState);
  const dataTableUIContext = useDataTableUIContext();
  const dataTableUIProps = useMemo(() => {
    return {
      queryParams: dataTableUIContext.queryParams,
      fetchData: dataTableUIContext.fetchData
    };
  }, [dataTableUIContext]);
  const action = (process, action) => {
    const state = cloneDeep(entitiesSlice);
    setEntitiesSlice(process(state, action));
  };

  const reducers = {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === CALL_TYPES.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      return state;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === CALL_TYPES.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
      return state;
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === CALL_TYPES.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      return state;
    },
    // findPermissions
    entitiesFetched: (state, action) => {
      const { totalCount, entities, page, pageSize } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities.map((entity, index) => {
        return {
          ...entity,
          _index: ((page || 1) - 1) * (pageSize || 10) + index + 1
        }
      });
      state.totalCount = totalCount;
      return state;
    },
    // createProduct
    entityCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
      reloadPage();
      return state;
    },
    entityUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const entitiesUpdated = state.entities.map(entity => {
        if (entity.id === action.payload.data.id) {
          console.log(action.payload.data);
          return action.payload.data;
        }
        return entity;
      });
      reloadPage();
      return {
        ...state,
        entities: entitiesUpdated
      };
    },
    entityDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
      reloadPage();
      return state;
    },
    entitiesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
      reloadPage();
      return state;
    }
  };

  const startAction = () => {
    action(reducers.startCall, {
      payload: {}
    });
  };
  const endAction = () => {
    action(reducers.endCall, {
      payload: {}
    });
  };
  const startListLoading = () => {
    action(reducers.startCall, {
      payload: {
        callType: CALL_TYPES.list
      }
    });
  };
  const endListLoading = () => {
    action(reducers.endCall, {
      payload: {
        callType: CALL_TYPES.list
      }
    });
  };
  const catchError = data => {
    action(reducers.catchError, {
      payload: data
    });
  };

  const reloadPage = () => {
    fetchId.current = (fetchId.current || 0) + 1;
    const fetchIdCurr = fetchId.current;
    // server call by queryParams
    startListLoading();
    dataTableUIProps
      .fetchData(dataTableUIProps.queryParams)
      .then(response => {
        if (fetchIdCurr === fetchId.current) {
          const { total, data } = response.data;
          action(reducers.entitiesFetched, {
            payload: { totalCount: total, entities: data }
          });
        }
      })
      .catch(error => {
        if (fetchId === fetchId.current) {
          processError(error);
        }
      });
  };

  const value = {
    pageName,
    state: entitiesSlice,
    reducers,
    action,
    startAction,
    endAction,
    startListLoading,
    endListLoading,
    reloadPage,
    catchError
  };

  return (
    <DataTableContext.Provider value={value}>
      {children}
    </DataTableContext.Provider>
  );
}
