export const MAP_DEGREE = {
  "associate": "Liên kết",
  "bachelor": "Cử nhân",
  "master": "Chuyên gia",
  "doctoral": "Tiến sĩ"
};

export const MAP_CLASS_STATUS = {
  "waiting": "DATA_SELECT.MAP_CLASS_STATUS.WAITING",
  "running": "DATA_SELECT.MAP_CLASS_STATUS.RUNNING",
  "stop": "DATA_SELECT.MAP_CLASS_STATUS.STOP"
};

export const MAP_STUDENT_CHANGE_STATUS = {
  "transfer": "Chuyển đến",
  "study": "Đăng ký học theo học",
  "move_out": "Chuyển đi",
  "reserve": "Bảo lưu",
  "move_in": "Chuyển đến"
};

export const MAP_CANDIDATE_STATUS = {
  "not_take_care": "Chưa chăm sóc",
  "potential": "Tiềm năng(đã chăm sóc và có thể chốt đơn)",
  "not_potential": "Chưa chăm sóc",
  "enrollment_test": "Chưa chăm sóc",
  "test_input": "Chưa chăm sóc",
  "overall_evaluation": "Chưa chăm sóc"
};

export const MAP_FILE_IMPORT_STATUS = {
  "done": "Hoàn thành",
  "importing": "Đang thực hiện",
  "error": "Lỗi",
}

export const MAP_CHECKIN_STATUS = {
  "created": "được tạo",
  "waiting": "Chờ duyệt",
  "approve": "Đã duyệt",
  "lesson_off": "buổi học được nghỉ"
}

export const MAP_METHOD_PAY_FEE = {
  "bank_transfer": "Chuyển khoản ngân hàng",
  "cash": "Tiền mặt",
  "pos": "POS"
}

export const MAP_STUDY_TYPE = {
  "trial_learning": "Học thử",
  "real_learning": "Học thật"
}
