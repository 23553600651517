import { useSelector } from 'react-redux';
import React, {useEffect, useState} from "react";
import {TEACHER_RESOURCE} from "../../../../constants/system/resource";
import axios from "axios";
import {MAP_CLASS_STATUS} from "../../../../constants/MapConvert";
import TabsCustom from "../../../components/Tabs";
import StatisticCheckinPage from "./statistic/checkin/StatisticCheckinPage";
import $ from "jquery";
import {TeacherEditForm} from "./list/TeacherFormEdit";
import {FormDialog} from "../../../components/form-dialog/FormDialog";
import * as requestFromServer from "../../common/DataTable/_redux/entitiesCrud";
import reactToastify from "../../../../plugin/react-toastify";
import {keyMessages} from "../../../../_theme/i18n";
import {useIntl} from "react-intl";

export default function PageTeacherStatisticCheckinDetail({history}) {
  const intl = useIntl();
  const messages = intl?.messages;
  const teacherOfMe = useSelector(state => state?.auth?.user?.teacher);
  const teacherId = teacherOfMe?.id;
  const [teacherInfo, setTeacherInfo] = useState({});
  const [showDialogEdit, setShowDialogEdit] = React.useState(false);
  const openDialogEdit = () => setShowDialogEdit(true);
  const closeDialogEdit = () => {
    setShowDialogEdit(false);
  };

  useEffect(() => {
    if (teacherId) {
      let url = TEACHER_RESOURCE + "/detail?id=" + teacherId;
      axios.get(url)
        .then(res => {
          let data = res.data.data;
          data = {
            ...data,
            "nationality": {
              nationality: data.nationality
            }
          }
          setTeacherInfo(data);
        }).catch(err => console.log(err));

      $(".tab-content").addClass("w-full p-5")
    }
  }, [teacherId]);

  const tabs = [
    {
      key: 'statistic-checkin',
      title: messages[keyMessages['TEACHER.STATISTIC.ACTIVITIES']],
      content: <StatisticCheckinPage teacherId={teacherId} teacherInfo={teacherInfo} history={history} isTeacher/>
    },
    // {
    //   key: 'salary-detail',
    //   title: 'Chi tiết nhận lương',
    //   content: <StatisticCheckinPage teacherId={teacherId}/>
    // }
  ]

  const formatStatus = (status) => {
    let cssClass;
    switch (status) {
      case "waiting":
        cssClass = "mr-1 badge badge-warning";
        break;
      default:
        cssClass = "mr-1 badge badge-primary";
    }

    return (
      <span className={cssClass}>
            {messages[MAP_CLASS_STATUS[status]]}
      </span>
    );
  }

  const updateTeacherInfomation = ({data}) => {
    requestFromServer.updateEntity(TEACHER_RESOURCE, data)
      .then(res => {
        let data = res.data.data;
        data = {
          ...data,
          "nationality": {
            nationality: data.nationality
          }
        }
        setTeacherInfo(data);
        reactToastify.success("Cập nhật thông tin giáo viên thành công !!!");
        closeDialogEdit();
      })
      .catch(err => {
        console.log(err);
        reactToastify.error("Cập nhật thông tin giáo viên thất bại !!!");
      })
  }

  return (
    <div>
      <FormDialog
        show={showDialogEdit}
        size="lg"
        title={'Cập nhật thông tin giáo viên'}
        initData={teacherInfo}
        onHide={closeDialogEdit}
        onSubmit={updateTeacherInfomation}
        ComponentForm={TeacherEditForm}
      />
      <div className="bg-white border">
        <div className="row">
          <div className="col-md-6">
            <h4 className="pl-4 pt-3">{messages[keyMessages['TEACHER.STATS.TITLE']]}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 pl-8 pr-5 pb-5 pt-2">
            <div>
              <b>{messages[keyMessages['TEACHER.STATS.NAME']]}: </b> {teacherInfo.name}
            </div>
            <div>
              <b>{messages[keyMessages['TEACHER.STATS.NATIONALITY']]}: </b> {teacherInfo.nationality && teacherInfo.nationality.nationality}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border">
        <TabsCustom
          tabs={tabs}
          idActive={0}
        />
      </div>
    </div>
  );
}
