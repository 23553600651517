import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMI from "@material-ui/core/Select";
import React from "react";
import BootstrapInput from './BootstrapInput';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function Select({
  label,
  withFeedbackLabel = false,
  type = "text",
  customFeedbackLabel,
  children,
  placeholder,
  form: { touched, errors, isValid },
  field,
  options = [],
  required,
  ...props
}) {

  return (
    <>
      {label && <label>{label}{required &&<span className="text-danger">*</span>}</label>}
      <SelectMI
        {...props}
        {...field}
        placeholder={placeholder}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        input={<BootstrapInput placeholder={placeholder}/>}
        value={field.value + '' || ''}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {
          (!options || options.length === 0) &&
          (
            <ListSubheader>
              Không có dữ liệu
            </ListSubheader>
          )
        }
        {options.map(({ value, text, isGroup = false }, index) => {
          if (isGroup) {
            return (
              <ListSubheader key={`menu-item-group-${text}`}>
                {text}
              </ListSubheader>
            );
          }

          return (
            <MenuItem key={`menu-item-option-${text}`} value={value}>
              {text}
            </MenuItem>
          );
        })}
      </SelectMI>
      {(withFeedbackLabel || errors?.[field?.name]) && (
        <FieldFeedbackLabel
          error={errors?.[field?.name]}
          touched={touched?.[field?.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
