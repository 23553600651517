/* eslint-disable no-unused-vars */
import MenuItem from '@material-ui/core/MenuItem';
import SelectMI from '@material-ui/core/Select';
import React from "react";
import {PaginationTotalStandalone} from "react-bootstrap-table2-paginator";
import BootstrapInput from '../forms/BootstrapInput';

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange
  } = paginationProps;
  const style = {
    width: "75px"
  };

  const onSizeChange = event => {
    const newSize = +event.target.value;
    onSizePerPageChange(newSize);
  };

  const pageSizeNotExist = !sizePerPageList.find(s => s?.value === sizePerPage)

  return (
    <div className="d-flex align-items-center py-3 px-3">
      <SelectMI
        disabled={totalSize === 0}
        value={sizePerPage}
        onChange={onSizeChange}
        input={<BootstrapInput classes={{input: "py-2"}} />}
      >
        {sizePerPageList.map(({ value, text }, index) => {
          return (
            <MenuItem key={`menu-item-option-${value}-${index}`} value={value}>
              {text}
            </MenuItem>
          );
        })}
        {
          pageSizeNotExist &&
          (
            <MenuItem value={sizePerPage}>
              {sizePerPage}
            </MenuItem>
          )
        }
      </SelectMI>
      {/*<select*/}
      {/*  disabled={totalSize === 0}*/}
      {/*  className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${totalSize ===*/}
      {/*    0 && "disabled"}`}*/}
      {/*  onChange={onSizeChange}*/}
      {/*  value={sizePerPage}*/}
      {/*  style={style}*/}
      {/*>*/}
      {/*  {sizePerPageList.map(option => {*/}
      {/*    const isSelect = sizePerPage === `${option.page}`;*/}
      {/*    return (*/}
      {/*      <option*/}
      {/*        key={option.text}*/}
      {/*        value={option.page}*/}
      {/*        className={`btn ${isSelect ? "active" : ""}`}*/}
      {/*      >*/}
      {/*        {option.text}*/}
      {/*      </option>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</select>*/}
      <PaginationTotalStandalone className="text-muted" {...paginationProps} />
      {/*{isLoading && (*/}
      {/*  <div className="d-flex align-items-center">*/}
      {/*    <div className="mr-2 text-muted">Loading...</div>*/}
      {/*    <div className="spinner spinner-primary mr-10"></div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
}
