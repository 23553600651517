import {Formik} from "formik";
import {Modal} from "react-bootstrap";
import { keyMessages } from '../../../../../../../_theme/i18n';
import reactToastify from '../../../../../../../plugin/react-toastify';
import { classList } from '../../../../../../../utils/DOM/class';
import { isEmpty, isFunction } from '../../../../../../../utils/typeof';
import { useDataTableContext } from '../../../../../common/DataTable/pages/DataTableContext';
import { useDataTableUIContext } from '../../../../../common/DataTable/pages/DataTableUIContext';
import React from "react";

export function ConfirmSendEmailDialog({save, item, actionsLoading, onHide, onSuccess}) {
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();
  const {
    state
  } = dataTableContext;
  const {
    ids
  } = dataTableUIContext;
  if (isEmpty(ids) || isEmpty(state?.entities)) return null;

  const studentsSelected = state?.entities.filter(student => ids.includes(student?.id));

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, formikHelpers) => {
          const studentIdsActive = studentsSelected.filter(student => student?.email).map(student => student?.id);
          if (isEmpty(studentIdsActive)) {
            reactToastify.error("Tất cả học sinh kích hoạt đều không có email")
          } else {
            save({
              student_ids: studentsSelected.map(student => student.student_id)
            });
          }
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay")}>
              <div>
                <div>
                  Bạn có muốn tạo tài khoản và gửi email kích hoạt cho những học sinh dưới đây:
                </div>
                <div className="my-2">
                  {
                    studentsSelected.map((student, index) => {
                      return (
                        <div key={`student-${index}`}>
                          {student?.student_name} - <span className={`${student?.email ? "text-success" : "text-danger"}`} > ({student?.email || "Chưa cập nhật email"})
                        </span>
                        </div>
                      )
                    })
                  }
                </div>
                <div>
                  Lưu ý: <span className="text-danger">Những học sinh không có email sẽ không kích hoạt</span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang gửi..." : "Tạo tài khoản"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
