import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import { AccountCircle } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { keyMessages } from '../../../_theme/i18n';
import { classList } from "../../../utils/DOM/class";
import { toEnglish } from "../../../utils/filter";
import { usePermissionContext } from "../PermissionContext";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

export default function InputSearch(props) {
  const intl = useIntl();
  const messages = intl?.messages;
  const history = useHistory();
  const perfectScrollbarRef = React.useRef();
  const permissionContext = usePermissionContext();
  const menus = permissionContext?.menus || [];
  const [textSearch, setTextSearch] = useState("");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const scrollToTop = () => {
    const _ps = perfectScrollbarRef.current?._ps;
    if (_ps) {
      if (_ps.element) {
        _ps.element.scrollTop = 0;
        _ps.update();
      }
    }
  };

  const menuShow = useMemo(() => {
    scrollToTop();
    return menus.filter(e => {
      return (
        toEnglish(e.text)
          .toLowerCase()
          .includes(toEnglish(textSearch).toLowerCase()) && e.visible !== false
      );
    });
  }, [menus, textSearch]);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <Paper className={classList(classes.root, "my-1")}>
        <InputBase
          ref={anchorRef}
          value={textSearch}
          onChange={e => {
            setTextSearch(e.target.value);
            setOpen(true);
          }}
          className={classes.input}
          placeholder={messages[keyMessages['TOPBAR.SEARCH.PLACEHOLDER']]}
          inputProps={{ "aria-label": messages[keyMessages['TOPBAR.SEARCH.PLACEHOLDER']] }}
        />
        <IconButton
          className={classList(classes.iconButton, "px-1 py-1")}
          aria-label="search"
          disabled
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <Popper
        open={open}
        className={"zindex-2"}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => {
          if (menuShow.length > 0) {
            return(
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "start top" : "start bottom"
                }}
              >
                <Paper className="min-w-300">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList onKeyDown={handleListKeyDown}>
                      <PerfectScrollbar
                        ref={perfectScrollbarRef}
                        options={perfectScrollbarOptions}
                        className="scroll"
                        style={{ maxHeight: "20rem", position: "relative" }}
                      >
                        {menuShow.map((item, index) => {
                          return (
                            <MenuItem
                              key={`menu-search-${index}`}
                              onClick={e => {
                                handleClose(e);
                                setTextSearch(item.text);
                                history.push(item.href);
                              }}
                            >
                              {item.text}
                            </MenuItem>
                          );
                        })}
                      </PerfectScrollbar>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )
          }
        }}
      </Popper>
    </>
  );
}
