import React from "react";
import {Form} from "react-bootstrap";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function TextArea({
                           field, // { name, value, onChange, onBlur }
                           form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                           label,
                           withFeedbackLabel = false,
                           customFeedbackLabel,
                           type = "text",
                           required,
                           rows = "3",
                           disabled = false,
                           ...props
                         }) {
  return (
    <Form.Group controlId="exampleForm.ControlTextarea1">
      {label && <label>{label}{required && <span className="text-danger">*</span>}</label>}
      <Form.Control
        as="textarea"
        rows={rows}
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
        disabled={disabled}
      />
      {(withFeedbackLabel || errors[field.name]) && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </Form.Group>
  );
}
