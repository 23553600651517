import React from "react";
import * as requestFromServer from "../../../common/DataTable/_redux/entitiesCrud";
import {TEACHER_CHECKIN_RESOURCE, TEACHER_RESOURCE} from "../../../../../constants/system/resource";
import * as columnFormatters from "../../../common/DataTable/pages/table/column-formatters";
import {DETAIL} from "../../../../../constants/system/permission";
import {DATE_RANGE, SELECT_SOURCE} from "../../../../../constants/filterType";
import {DataTableUIProvider} from "../../../common/DataTable/pages/DataTableUIContext";
import {DataTableProvider} from "../../../common/DataTable/pages/DataTableContext";
import {LoadingDialog} from "../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {DataTableCard} from "../../../common/DataTable/pages/DataTableCard";
import {Link} from "react-router-dom";
import {keyMessages} from "../../../../../_theme/i18n";
import {useIntl} from "react-intl";

const ListTeacherStatisticPage = ({history}) => {
  const intl = useIntl();
  const messages = intl?.messages;
  const uiEvents = {
    clickViewDetailItem: data => {
      console.log(data);
      const url = `/teacher/${data.teacher_id}/statistic-checkin`;
      history.push(url)
    },
    fetchData: query => {
      return requestFromServer.findEntities(TEACHER_CHECKIN_RESOURCE, query);
    },
  };

  const headers = [
    {
      dataField: "teacher.name",
      text: messages[keyMessages['TEACHER.INFOMATION.FULL_NAME']],
      sort: true
    },
    {
      dataField: "class.name",
      text: messages[keyMessages['CLASS.NAME']],
      sort: true,
      formatter: (cellContent, row) => {
        return(
          <Link to={"/class/" + row.class_id}>{cellContent}</Link>
        )
      }
    },
    {
      dataField: "num_lesson",
      text: messages[keyMessages['CLASS.NUMBER_LESSON']],
      sort: true
    },{
      dataField: "num_lesson_created",
      text: messages[keyMessages['CLASS.NUMBER_LESSON_CREATED']],
      sort: true
    },{
      dataField: "num_lesson_waiting",
      text: messages[keyMessages['CLASS.NUMBER_LESSON_WAITING']],
      sort: true
    },{
      dataField: "num_lesson_approve",
      text: messages[keyMessages['CLASS.NUMBER_LESSON_APPROVE']],
      sort: true
    },{
      dataField: "number_hour",
      text: messages[keyMessages['CLASS.NUMBER_HOUR']],
      sort: true
    },
    {
      dataField: "checked_at",
      text: messages[keyMessages['CLASS.LAST_CHECKED_AT']],
      formatter: columnFormatters.DateTimeColumnFormatter
    },

    {
      dataField: "action",
      text: "Hành động",
      headerStyle: (column, colIndex) => {
        return {width: "120px", textAlign: "right"};
      },
      actions: [
        {
          color: "primary",
          icon: "remove_red_eye",
          label: "Detail",
          event: uiEvents.clickViewDetailItem,
          actionName: DETAIL,
        }
      ]
    }
  ];


  const filters = [

    {
      type: SELECT_SOURCE,
      label: "Giáo viên",
      name: "teacher_id",
      resource: TEACHER_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
    {
      type: DATE_RANGE,
      label: "Thời gian trữ lượng",
      name: "checked_at"
    },
  ];

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      filters={filters}
    >
      <DataTableProvider pageName="teacher-statistic-checkin">
        <LoadingDialog/>
        <DataTableCard dataTableUIEvents={uiEvents}/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
};

ListTeacherStatisticPage.propTypes = {};

ListTeacherStatisticPage.defaultProps = {};

export default ListTeacherStatisticPage;