// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../_theme/_partials/controls";
import { SelectionSearch } from '../../../../../_theme/_partials/controls/forms/SelectionSearch';
import { LOCATION_BRANCH_RESOURCE, ROLE_RESOURCE } from '../../../../../constants/system/resource';

const phoneRegExp = /^([0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10})$/

const CreateSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email là bắt buộc")
    .email(),
  full_name: Yup.string()
    .min(6, "Tối thiểu 6 ký tự")
    .max(100, "Tối đa 100 ký tự")
    .required("Tên là bắt buộc"),
  phone_number: Yup.string()
    .nullable()
    .typeError('Bạn phải nhập một số')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
  password: Yup.string()
    .min(6, "Tối thiểu 6 ký tự")
    .max(100, "Tối đa 100 ký tự")
    .required("Mật khẩu là bắt buộc"),
  roles: Yup.array()
    .required("Bạn chưa chọn quyền"),
  location_branches: Yup.array()
    .required("Bạn chưa chi nhánh"),
});

// Validation schema
const EditSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email là bắt buộc")
    .email(),
  full_name: Yup.string()
    .min(6, "Tối thiểu 6 ký tự")
    .max(100, "Tối đa 100 ký tự")
    .required("Tên là bắt buộc"),
  phone_number: Yup.string()
    .nullable()
    .typeError('Bạn phải nhập một số')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
  roles: Yup.array()
    .required("Bạn chưa chọn quyền"),
  location_branches: Yup.array()
    .required("Bạn chưa chi nhánh"),
});

export function UserEditForm({
  save,
  item,
  actionsLoading,
  onHide
}) {
  const isEdit = item.id;
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={isEdit ? EditSchema : CreateSchema}
        onSubmit={(values, formikHelpers) => {
          const data = {
            ...values,
            role_ids: values.roles.map(r => r.id),
            location_branch_ids: values.location_branches.map(l => l.id),
          }
          save(data, formikHelpers);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body>
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-sm-12">
                    <Field
                      name="email"
                      component={Input}
                      required
                      placeholder="Nhập email của bạn"
                      label="Email"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="full_name"
                      required
                      component={Input}
                      placeholder="Nhập tên người dùng"
                      label="Tên người dùng"
                    />
                  </div>
                </div>
                {
                  !isEdit &&
                  (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <Field
                          name="password"
                          type="password"
                          component={Input}
                          required
                          placeholder="Nhập mật khẩu"
                          label="Mật khẩu"
                        />
                      </div>
                    </div>
                  )
                }
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="phone_number"
                      component={Input}
                      placeholder="Nhập số điện thoại"
                      label="Số điện thoại"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="roles"
                      component={SelectionSearch}
                      resource={ROLE_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      required
                      isMulti
                      placeholder="Chọn quyền"
                      label="Quyền"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="location_branches"
                      component={SelectionSearch}
                      resource={LOCATION_BRANCH_RESOURCE}
                      propertyName="name"
                      propertyValue="id"
                      required
                      isMulti
                      placeholder="Chọn chi nhánh"
                      label="Chi nhánh"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang lưu..." : "Lưu lại"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
