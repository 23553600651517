// Validation schema
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {Modal} from "react-bootstrap";
import {classList} from "../../../utils/DOM/class";
import {Input} from "../../../_theme/_partials/controls";
import React from "react";
import {EditDialogHeader} from "../common/DataTable/pages/edit-dialog/EditDialogHeader";
import axios from "axios";
import {USER_SYSTEM_RESOURCE} from "../../../constants/system/resource";
import reactToastify from "../../../plugin/react-toastify";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../_theme/i18n";

function getEditSchema (messages){
  return Yup.object().shape({
    old_password: Yup.string()
        .required("Old password is required"),
    password: Yup.string()
        .required("New password is required")
        .min(6, messages[keyMessages["PROFILE.NEW_PASSWORD.REQUIRED"]])
        .matches(/[A-Z]/, messages[keyMessages["PROFILE.NEW_PASSWORD.REQUIRED"]])
        .matches(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, messages[keyMessages["PROFILE.NEW_PASSWORD.REQUIRED"]]),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Confirm password is not matched')
        .required("Confirm password is required")
  });
}


export function ChangePasswordForm({dataInit, actionsLoading, onHide, isShow}) {

  const intl = useIntl();
  const messages = intl?.messages;
  const changePassword = (entity) => {
    const url = USER_SYSTEM_RESOURCE + "/change-password";

    axios.post(url, entity)
      .then(res => {
        reactToastify.success(messages[keyMessages["FORM.SUCCESS"]]);
        onHide();
      }).catch(err => {
      reactToastify.error(messages[keyMessages["FORM.FAIL"]]);
    });
  }

  return (
    <Modal
      // size={size}
      backdrop="static"
      show={isShow}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <EditDialogHeader
        title={messages[keyMessages["PROFILE.CHANGE_PASSWORD"]]}
        actionsLoading={actionsLoading}
      />
      <>
        <Formik
          enableReinitialize={true}
          initialValues={dataInit}
          validationSchema={getEditSchema(messages)}
          onSubmit={values => {
            console.log(values);
            changePassword({
              id: values.id,
              old_password: values.old_password,
              password: values.password
            });
          }}
        >
          {({handleSubmit}) => (
            <>
              <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success"/>
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <Field
                        type="password"
                        name="old_password"
                        component={Input}
                        required
                        label={messages[keyMessages["PROFILE.OLD_PASSWORD"]]}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <Field
                        type="password"
                        name="password"
                        component={Input}
                        required
                        label={messages[keyMessages["PROFILE.NEW_PASSWORD"]]}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-sm-12">
                      <Field
                        type="password"
                        name="confirm_password"
                        component={Input}
                        required
                        label={messages[keyMessages["PROFILE.RETYPE_PASSWORD"]]}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >{messages[keyMessages["FORM.CANCEL"]]}</button>
                <> </>
                <button
                  type="submit"
                  disabled={actionsLoading}
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  {actionsLoading ? messages[keyMessages["FORM.SAVING"]] : messages[keyMessages["FORM.SAVE"]]}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    </Modal>
  );
}