import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../../../utils/DOM/class';
import { getPagesCount } from '../../../_helpers';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const defaultFunction = () => null;

class PaginationLinks extends Component {
  constructor(props) {
    super(props);
    const { pageNeighbours = 0 } = props;

    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.state = { currentPage: 1 };
  }

  static propTypes = {
    paginationProps: PropTypes.object.isRequired,
    pageNeighbours: PropTypes.number,
  };

  static defaultProps = {
    pageNeighbours: 1,
  };

  componentDidMount() {
    this.gotoPage(1);
  }

  getTotalPage = () => {
    const paginationProps = this.props.paginationProps || {};
    const { totalSize, sizePerPage } = paginationProps;
    if (sizePerPage === -1) {
      return 1;
    } else {
      return getPagesCount(totalSize, sizePerPage);
    }
  }

  gotoPage = page => {
    const paginationProps = this.props.paginationProps || {};
    const { onPageChange = defaultFunction } = paginationProps;
    const pagesCount = this.getTotalPage();
    const currentPage = Math.max(1, Math.min(page, pagesCount));

    this.setState({ currentPage }, () => onPageChange(currentPage));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.getTotalPage();
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    const totalPages = this.getTotalPage();
    if (totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();
    const disabledClass = totalPages > 1 ? "" : "disabled";
    const isLastPage = totalPages === currentPage;
    const isFirstPage = 1 === currentPage;

    return (
      <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
        <a
          onClick={e => this.handleClick(1, e)}
          className={classList(
            "btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1",
            isFirstPage && "disabled"
          )}
        >
          <i className="ki ki-bold-double-arrow-back icon-xs" />
        </a>
        <a
          onClick={e => this.handleClick(currentPage - 1, e)}
          className={classList(
            "btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1",
            isFirstPage && "disabled"
          )}
        >
          <i className="ki ki-bold-arrow-back icon-xs" />
        </a>

        {
          pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <a
                  key={`page-dot-${index}`}
                  onClick={this.handleMoveLeft}
                  className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                >
                  ...
                </a>
              );
            }
            if (page === RIGHT_PAGE) {
              return (
                <a
                  key={`page-dot-${index}`}
                  onClick={this.handleMoveRight}
                  className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
                >
                  ...
                </a>
              );
            }
            return (
              <a
                key={`page-${page}`}
                onClick={e => this.handleClick(page, e)}
                className={`btn btn-icon btn-sm border-0 btn-light ${
                  page === currentPage ? " btn-hover-primary active" : ""
                } mr-2 my-1`}
              >
                {page}
              </a>
            );
          })
        }

        <a
          onClick={e => this.handleClick(currentPage + 1, e)}
          className={classList(
            "btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1",
            isLastPage && "disabled"
          )}
        >
          <i className="ki ki-bold-arrow-next icon-xs"></i>
        </a>
        <a
          onClick={e => this.handleClick(totalPages, e)}
          className={classList(
            "btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1",
            isLastPage && "disabled"
          )}
        >
          <i className="ki ki-bold-double-arrow-next icon-xs"></i>
        </a>
      </div>
    )
  }
}

export { PaginationLinks };
