import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  CODE_SUCCESS,
  HTTP_CODE_422
} from "../../../../../../constants/system/code";
import reactToastify from "../../../../../../plugin/react-toastify";
import { responseErrorToString } from "../../../../../../utils/array";
import { processError, processErrorFormik } from "../../../../../../utils/axios";
import { classList } from "../../../../../../utils/DOM/class";
import { useDataTableContext } from "../DataTableContext";
import { useDataTableUIContext } from "../DataTableUIContext";
import { FormDialogHeader } from "./FormDialogHeader";

const optionsScroll = {
  wheelSpeed: 2,
  wheelPropagation: false,
  suppressScrollX: true
};

export function FormDialog({
  title,
  show,
  onHide,
  autoHide = true,
  initData,
  onSubmit,
  ComponentForm,
  size = "md",
  messageSuccess,
  noShowMessage = false,
  backdrop = 'static',
  centered = false,
  ...props
}) {
  // Permissions UI Context
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();
  const dataTableUIProps = useMemo(() => {
    return {
      newData: dataTableUIContext.newData,
      editData: dataTableUIContext.editData
    };
  }, [dataTableUIContext]);

  const actionsLoading = dataTableContext.state.actionsLoading;

  // server request for saving permission
  const saveData = async (data, formik, callbackSuccess) => {
    const { setErrors } = formik || {};
    try {
      dataTableContext.startAction();
      const response = await onSubmit({
        data,
        dataTableContext,
        dataTableUIContext
      });
      dataTableContext.endAction();
      if (response.data?.code === CODE_SUCCESS) {
        if (!noShowMessage) {
          if (messageSuccess) {
            reactToastify.success(messageSuccess)
          } else {
            reactToastify.editSuccess();
          }
        }

        dataTableContext.reloadPage();

        if (formik) {
          formik.resetForm();
        }

        if (callbackSuccess) {
          callbackSuccess(response.data?.data)
        }
        if (autoHide) {
          onHide(response.data?.data);
        }
      } else {
        reactToastify.actionFail();
      }
    } catch (error) {
      processErrorFormik(error, formik);
      dataTableContext.endAction();
    }
  };
  return (
    <Modal size={size} show={show} onHide={onHide}>
      <FormDialogHeader
        item={initData}
        title={title}
        onHide={onHide}
        backdrop={backdrop}
        centered={centered}
        actionsLoading={actionsLoading}
      />
      <div className={classList("overlay", actionsLoading && "overlay-block")}>
        {actionsLoading && (
          <div className="overlay-layer zindex-2 bg-transparent">
            <div className="spinner-border text-success" />
          </div>
        )}
        {/*<PerfectScrollbar*/}
        {/*  options={optionsScroll}*/}
        {/*  className="navi navi-hover scroll"*/}
        {/*  style={{ maxHeight: "600px" }}*/}
        {/*>*/}
          <ComponentForm
            save={saveData}
            actionsLoading={actionsLoading}
            item={initData}
            onHide={onHide}
            {...props}
          />
        {/*</PerfectScrollbar>*/}
      </div>
    </Modal>
  );
}
