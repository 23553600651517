import React, { useState, useEffect } from "react";
import { isFunction, isEmpty } from '../../../../utils/typeof';

export function InputCurrency(props) {
  const {
    value,
    onChange,
    onBlur,
    onKeyUp
  } = props;

  const refInput = React.useRef();
  const [valueShow, setValueShow] = useState(null);

  const formatNumber = (n) => {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const valueShowToNumber = (n) => {
    if (!isEmpty(n)) {
      return Number.parseInt(n.replace(/\D/g, ""));
    }
    return null;
  }

  const formatCurrency = (input, isBlur = false) => {
    // appends đ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    let input_val = input.value;
    if (input_val === valueShow) {
      return;
    }
    // don't validate empty input
    if (input_val === "") {
      setValueShow("")
      updateValueShowToValue(null);
      return;
    }

    // original length
    const original_len = input_val.length;

    // initial caret position
    let caret_pos = refInput?.current?.selectionStart;

    // check for decimal
    if (input_val.indexOf(".") >= 0) {

      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      const decimal_pos = input_val.indexOf(".");

      // split number by decimal point
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // On blur make sure 2 numbers after decimal
      if (isBlur) {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      // right_side = right_side.substring(0, 2);

      // join number by .
      // input_val = "đ" + left_side + "." + right_side;
      input_val = left_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
      // input_val = "đ" + input_val;
    }
    // send updated string to input
    setValueShow(input_val);
    updateValueShowToValue(input_val);

    // put caret back in the right position
    const updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    if (refInput?.current) {
      refInput.current.setSelectionRange(caret_pos, caret_pos);
    }
  }

  const onChangeInput = (e) => {
    formatCurrency(e.target);
  }

  const onKeyupInput = (e) => {
    if (isFunction(onKeyUp)) {
      onKeyUp(e);
    }

    formatCurrency(e.target);
  }

  const onBlurInput = (e) => {
    if (isFunction(onBlur)) {
      onBlur(e);
    }

    formatCurrency(e.target, true);
  }

  const updateValueShowToValue = (valueShowNew) => {
    if (!isEmpty(valueShowNew)) {
      const newNumber = valueShowToNumber(`${valueShowNew}`);
      if (newNumber !== value) {
        onChange(newNumber);
      }
    } else if (value !== null){
      onChange(null);
    }
  }

  useEffect(() => {
    if (!isEmpty(value)) {
      const newValueShow = formatNumber(`${value}`);
      if (!isEmpty(valueShow)) {
        const oldValue = valueShowToNumber(`${valueShow}`);
        if (oldValue !== value) {
          setValueShow(newValueShow);
        }
      } else {
        if (newValueShow !== valueShow) {
          setValueShow(newValueShow);
        }
      }
    } else if (valueShow !== null) {
      setValueShow(null);
    }
  }, [value]);

  return (
    <div className="input-group">
      {/*<div className="input-group-prepend">*/}
      {/*  <span className="input-group-text">đ</span>*/}
      {/*</div>*/}
      <input
        ref={refInput}
        {...props}
        type="text"
        onChange={onChangeInput}
        onBlur={onBlurInput}
        onKeyUp={onKeyupInput}
        value={valueShow}
        autoComplete="off"
      />
      <div className="input-group-append">
        <span className="input-group-text">VNĐ</span>
      </div>
    </div>
  );
}
