import React, { useEffect, useRef } from "react";
import {toAbsoluteUrl} from '../../../../../../../_theme/_helpers';
import {CLASS_RESOURCE, CLASS_SCHEDULE_RESOURCE} from "../../../../../../../constants/system/resource";
import { getNow } from '../../../../../../../utils/filter';
import {isArray, isEmpty} from '../../../../../../../utils/typeof';
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import Table from "react-bootstrap/Table";
import {DateTimeFormatter} from "../../../../../common/DataTable/pages/table/column-formatters/DateTimeColumnFormatter";
import {DDMMYYYY} from "../../../../../../../constants/system/dateformat";
import Select from "react-select";
import { Field, Formik, useFormikContext } from "formik";
import {Modal} from "react-bootstrap";
import {classList} from "../../../../../../../utils/DOM/class";
import {TextArea} from "../../../../../../../_theme/_partials/controls/forms/TextArea";
import axios from "axios";
import reactToastify from "../../../../../../../plugin/react-toastify";
import {useDataTableContext} from "../../../../../common/DataTable/pages/DataTableContext";
import * as Yup from "yup";
import {MAP_CHECKIN_STATUS} from "../../../../../../../constants/MapConvert";
import {Input} from "../../../../../../../_theme/_partials/controls";
import Rating from "@material-ui/lab/Rating";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";
import Badge from "react-bootstrap/Badge";

const generateEditSchemaForTeacher = ({ messages, isEditCheckin, item }) => {
    if (!item?.next_schedule) {
        return Yup.object().shape({
            lesson_content: Yup.string()
              .nullable()
              .required(messages[keyMessages["TEACHER.CHECKIN_FORM.VALIDATOR.REQUIRED_CONTENT"]]),
        });
    }

    return Yup.object().shape({
        lesson_content: Yup.string()
            .nullable()
            .required(messages[keyMessages["TEACHER.CHECKIN_FORM.VALIDATOR.REQUIRED_CONTENT"]]),
        next_lesson_content: Yup.string()
            .nullable()
            .required(messages[keyMessages["TEACHER.CHECKIN_FORM.VALIDATOR.REQUIRED_NEXT_CONTENT"]]),
    });
}

const EditSchemaForStaff = Yup.object().shape({
    lesson_content: Yup.string()
        .nullable(),
        // .required("Nội dung là bắt buộc"),
    next_lesson_content: Yup.string()
      .nullable(),
      // .required("Kế hoạch dạy buổi tới là bắt buộc"),
    real_duration: Yup.number()
        .nullable()
        .min(1, "Số giờ thực dạy phải lớn hơn 0")
        .required("Số giờ thực dạy của giáo viên là bắt buộc"),
});

const AutoSubmit = ({ studentCheckins, submit }) => {
    const formikContext = useFormikContext();
    const refTimeSave = useRef(getNow());
    const {
        isValid,
        values
    } = formikContext;
    useEffect(() => {
        let timeInterval;
        if (isValid) {
            const now = getNow();
            if (now - refTimeSave.current >= 60000) {
                refTimeSave.current = now;
                submit(values, false)
            }
            timeInterval = setInterval(() => {
                refTimeSave.current = now;
                submit(values, false)
            }, 30000);
        }

        return () => {
            clearInterval(timeInterval);
        }
    }, [isValid, studentCheckins, values])
    return null;
}

const StudentCheckinForm = ({save, item, actionsLoading, onHide, isEditCheckin}) => {

    const intl = useIntl();
    const messages = intl?.messages;

    const classId = item.class_id;
    const nameAction = !isEditCheckin ? messages[keyMessages["TEACHER.CHECKIN_FORM.CHECKING"]]
        : messages[keyMessages["TEACHER.CHECKIN_FORM.UPDATE"]]

    const [optionSchedule, setOptionsState] = React.useState([]);
    const [scheduleSelected, setScheduleSelected] = React.useState({});
    const [studentCheckins, setStudentCheckin] = React.useState([]);
    const [isEdit, setIsEdit] = React.useState(true);
    const [reRender, setRerender] = React.useState(false);

    const dataTableContext = useDataTableContext();

    useEffect(() => {
    }, [reRender]);

    useEffect(() => {
        if (classId) {
            loadDataSchedule();
            setScheduleSelected({
                ...item,
                label: `Date: ` + DateTimeFormatter(item.start_time, DDMMYYYY).props.children,
                value: item.id,
                next_lesson_content: item?.next_schedule?.lesson_content
            });

            setIsEdit(item.checkin_status === "waiting"
                || item.checkin_status === "created" || !isEditCheckin);
        }
    }, [classId]);

    useEffect(() => {
        if (scheduleSelected) {
            loadDataStudentReview(scheduleSelected.id)
        }
    }, [scheduleSelected])

    const isTeacherPage = () => {
        return !isEditCheckin;
    }

    const loadDataSchedule = () => {
        const queryParam = {
            page: 1,
            page_size: -1
        };

        const urlClassSchedule = CLASS_RESOURCE + `/${classId}/schedule`;
        requestFromServer
            .selectEntities(urlClassSchedule, queryParam)
            .then(response => {
                const {total, data, code} = response.data;
                if (code === 0) {
                    setOptionsState(data.map((cs, index) => {
                        return {
                            ...cs,
                            label: `Date: ` + DateTimeFormatter(cs.start_time, DDMMYYYY).props.children,
                            value: cs.id,
                            next_lesson_content: item?.next_schedule?.lesson_content
                        }
                    }));
                }
            })
            .catch(error => console.log(error));
    };

    const loadDataStudentReview = (scheduleId) => {
        if (scheduleId) {
            const queryParam = {
                page: 1,
                page_size: -1
            };
            const urlStudentCheckin = CLASS_SCHEDULE_RESOURCE + `/${scheduleId}/student-checkin`;

            requestFromServer.findEntities(urlStudentCheckin, queryParam)
                .then(response => {
                    const {total, data, code} = response.data;
                    if (isArray(data)) {
                        setStudentCheckin(data.map(studentCheckin => ({
                            ...studentCheckin,
                            is_checked: studentCheckin?.is_checked || 0
                        })));
                    } else {
                        setStudentCheckin([]);
                    }
                })
                .catch(err => console.log(err));
        }
    }

    const updateStudentCheckin = (studentCheckin, e) => {
        const field = e.target.name;
        studentCheckin[field] = field !== "is_checked" ? e.target.value : e.target.checked ? 1 : 0;
        studentCheckins.forEach(s => {
            if (s.id === studentCheckin.id) {
                s = studentCheckin;
            }
        });

        setRerender(!reRender);
    }

    const updateRating = (id, value) => {
        const indexUpdate = studentCheckins.findIndex(studentCheckin => studentCheckin?.id === id || (isEmpty(studentCheckin?.id) && studentCheckin?.student_id === id));
        if (indexUpdate !== -1) {
            const updatingStudentCheckin = studentCheckins[indexUpdate] || {};
            setStudentCheckin([
                ...studentCheckins?.slice(0, indexUpdate),
                {
                    ...updatingStudentCheckin,
                    positive: parseInt(value)
                },
                ...studentCheckins?.slice(indexUpdate + 1)
            ])
        }
    }

    const onChangeSchedule = schedule => {
        item = schedule;
        schedule = {
            ...schedule,
            lesson_content: schedule.lesson_content ? schedule.lesson_content : "",
            lesson_homework: schedule.lesson_homework ? schedule.lesson_homework : "",
            next_lesson_content: schedule.next_lesson_content ? schedule.next_lesson_content : "",
            note: schedule.note ? schedule.note : "",
        };
        setScheduleSelected(schedule);
        loadDataStudentReview(schedule.id);
    }

    const saveData = (lessonInfoUpdate, isSuccessHidePopup = true) => {
        if (item.urlApi) {
            const dataStudentCheckinUpdate = {
                class_schedule_id: lessonInfoUpdate.id,
                student_checkins: studentCheckins,
                lesson_content: lessonInfoUpdate.lesson_content,
                lesson_homework: lessonInfoUpdate.lesson_homework,
                next_lesson_content: lessonInfoUpdate.next_lesson_content,
                note: lessonInfoUpdate.note
            };

            if (!isEditCheckin) {
                dataStudentCheckinUpdate.real_duration = lessonInfoUpdate.real_duration;
                dataStudentCheckinUpdate.comment_teacher = lessonInfoUpdate.comment_teacher;
            }
            axios.post(item.urlApi, dataStudentCheckinUpdate)
                .then(r => {
                    if(r.data && r.data.code === 0) {
                        if (isSuccessHidePopup) {
                            reactToastify.success("Success !!!");
                            dataTableContext.reloadPage();
                            onHide();
                        }
                    }else{
                        reactToastify.error("You must comment for all students");
                    }
                })
                .catch(e => {
                    reactToastify.error("Failed !!!")
                });
        } else {
            reactToastify.error(nameAction + " failed !!!");
        }
    }

    const showStatus = (checkin_status) => {
        let cssClass;
        switch (checkin_status) {
            case "waiting":
                cssClass = "mr-1 badge badge-warning";
                break;
            case "approve":
                cssClass = "mr-1 badge badge-success";
                break;
            default:
                cssClass = "mr-1 badge badge-primary";
        }

        return (
            <span className={cssClass}>
        {MAP_CHECKIN_STATUS[checkin_status]}
      </span>
        );
    }

    const generateDetailTable = () => {
        return <div className="row overflow-auto border-secondary" style={{height: "250px"}}>
            <div className={"col-sm-12"}>
                {item.require_teacher_comment &&
                <Badge variant={"danger"}>You must comment for all students</Badge>
                }

                <Table bordered hover className="table-sticky-header v-data-table-fixed-left">
                    <thead>
                    <tr>
                        <th style={{
                            width: '50px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.ORDER"]]}</th>
                        <th style={{
                            width: '100px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.STUDENT_NAME"]]}</th>
                        <th style={{
                            width: '100px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.STUDENT_NAME_ES"]]}</th>
                        <th style={{
                            width: '80px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.AVATAR"]]}</th>
                        <th style={{
                            width: '80px'
                        }}> {messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.ATTENDANCE"]]}</th>
                        <th style={{
                            width: '120px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.EAGER"]]}</th>
                        <th style={{
                            width: '150px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.HOMEWORK_COMMENT"]]}</th>
                        <th style={{
                            width: '150px',
                        }}>{messages[keyMessages["TEACHER.CHECKIN_FORM.TABLE.COMMENT"]]}</th>
                        {isTeacherPage() &&
                        <th style={{width: '150px'}}>Bản dịch</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        studentCheckins.map((studentCheck, index) => {
                            const studentCheckId = studentCheck?.id || studentCheck?.student_id;
                            return (
                                <tr key={`student-checkins-${studentCheckId}-${index}`}>
                                    <td>{index + 1}</td>
                                    <td>{studentCheck?.student?.name}</td>
                                    <td>{studentCheck?.student?.english_name}</td>
                                    <td>
                                        <img
                                            src={toAbsoluteUrl(studentCheck?.student?.avatar_url || "/media/users/default.jpg")}
                                            alt=""
                                            style={{
                                                borderRadius: '50%',
                                                height: "44px",
                                                width: "44px"
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input type="checkbox" defaultChecked={studentCheck.is_checked}
                                               name="is_checked"
                                               onClick={(e) => updateStudentCheckin(studentCheck, e)}
                                               disabled={!isEdit}
                                        />
                                    </td>
                                    <td>
                                        <Rating
                                            key={`student-checkins-positive-${studentCheckId}-${index}`}
                                            name={`student-checkins-positive-${studentCheckId}-${index}`}
                                            value={studentCheck.positive || 0}
                                            onChange={(event, newValue) => {
                                                updateRating(studentCheckId, newValue);
                                            }}
                                        />
                                    </td>
                                    <td><textarea
                                        style={{width: '150px'}}
                                        className="form-control form-control-sm" rows="2"
                                                  defaultValue={studentCheck.homework_reviews}
                                                  name="homework_reviews"
                                                  onBlur={(e) => updateStudentCheckin(studentCheck, e)}
                                                  disabled={!isEdit}/>
                                    </td>
                                    <td><textarea
                                        style={{width: '150px'}}
                                        className="form-control form-control-sm" rows="2"
                                                  defaultValue={studentCheck.comment}
                                                  name="comment"
                                                  onBlur={(e) => updateStudentCheckin(studentCheck, e)}
                                                  disabled={!isEdit}/>
                                    </td>
                                    {isTeacherPage() &&
                                    <td><textarea
                                        style={{width: '150px'}}
                                        className="form-control form-control-sm" rows="2"
                                                  defaultValue={studentCheck.translation}
                                                  name="translation"
                                                  onBlur={(e) => updateStudentCheckin(studentCheck, e)}
                                                  disabled={!isEdit}
                                    /></td>}
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </Table>
            </div>
        </div>

    }

    const generateJudgeForm = () => {
        return isTeacherPage() ?
            (<div>
                <div className="row mt-5">
                    <div className="col-md-3 ">
                        <Field
                            name="real_duration"
                            component={Input}
                            required
                            type="number"
                            placeholder="Nhập số giờ giáo viên thực dạy"
                            label="Số giờ thực dạy (giờ)"
                            disabled={!isEdit}
                        />
                    </div>
                    <div className="col-md-9 ">
                        <Field
                            name="comment_teacher"
                            component={TextArea}
                            required
                            placeholder="Nhập nhận xét giáo viên"
                            label="Nhận xét giáo viên"
                            rows="1"
                            disabled={!isEdit}
                        />
                    </div>
                </div>
            </div>) : null;
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={scheduleSelected}
                validationSchema={generateEditSchemaForTeacher({
                    messages,
                    isEditCheckin,
                    item
                })}
                onSubmit={values => {
                    saveData(values);
                }}
            >
                {({handleSubmit}) => (
                    <>
                        {/*{item?.id && <AutoSubmit key={`auto-submit-class-lesson-${item?.id}`} studentCheckins={studentCheckins} submit={saveData} />}*/}
                        <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                            <div className="row font-size-sm">
                                <div className="col-md-8">
                                    {messages[keyMessages["TEACHER.CHECKIN_FORM.CHOOSE_PERIOD"]]}:
                                    <Select
                                        className="pb-3 w-50"
                                        classNamePrefix={messages[keyMessages["TEACHER.CHECKIN_FORM.CHOOSE_PERIOD"]]}
                                        value={scheduleSelected}
                                        name="lesson"
                                        options={optionSchedule}
                                        onChange={onChangeSchedule}
                                        isDisabled={!isEdit}
                                    />
                                </div>
                                {isTeacherPage() && <div className="col-md-4  p-8 text-right">
                                    Trạng thái: {item && showStatus(item.checkin_status)}
                                </div>}
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Field
                                        name="lesson_content"
                                        component={TextArea}
                                        required
                                        placeholder={messages[keyMessages["TEACHER.CHECKIN_FORM.LESSON_CONTENT"]]}
                                        label={messages[keyMessages["TEACHER.CHECKIN_FORM.LESSON_CONTENT"]]}
                                        rows="8"
                                        disabled={!isEdit}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="next_lesson_content"
                                        component={TextArea}
                                        required={!!item?.next_schedule}
                                        placeholder={messages[keyMessages["TEACHER.CHECKIN_FORM.NEXT_LESSON_CONTENT"]]}
                                        label={messages[keyMessages["TEACHER.CHECKIN_FORM.NEXT_LESSON_CONTENT"]]}
                                        rows="8"
                                        disabled={!isEdit}
                                    />
                                </div>

                            </div>
                            <div className={"row"}>
                                <div className="col-md-6 ">
                                    <Field
                                        name="lesson_homework"
                                        component={TextArea}
                                        required
                                        placeholder={messages[keyMessages["TEACHER.CHECKIN_FORM.HOMEWORK"]]}
                                        label={messages[keyMessages["TEACHER.CHECKIN_FORM.HOMEWORK"]]}
                                        rows="3"
                                        disabled={!isEdit}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="note"
                                        component={TextArea}
                                        placeholder={messages[keyMessages["TEACHER.CHECKIN_FORM.NOTE"]]}
                                        label={messages[keyMessages["TEACHER.CHECKIN_FORM.NOTE"]]}
                                        rows="3"
                                        disabled={!isEdit}
                                    />
                                </div>
                            </div>
                            {generateDetailTable()}
                            {generateJudgeForm()}
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >{messages[keyMessages["TEACHER.CHECKIN_FORM.CLOSE"]]}
                            </button>
                            <> </>
                            {isEdit &&
                            <button
                                type="submit"
                                disabled={actionsLoading}
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >{nameAction}</button>}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}

StudentCheckinForm.propTypes = {};

StudentCheckinForm.defaultProps = {};

export default StudentCheckinForm;
