/* eslint-disable jsx-a11y/anchor-is-valid */
import { green } from '@material-ui/core/colors';
import { Field, Form, Formik, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from "react";
import "../../../../_theme/_assets/sass/pages/login/classic/login-1.scss";
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useLocation } from "react-router";
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Card, DatePickerField, Input } from '../../../../_theme/_partials/controls';
import { TextArea } from '../../../../_theme/_partials/controls/forms/TextArea';
import { HTTP_CODE_422 } from '../../../../constants/system/code';
import reactToastify from '../../../../plugin/react-toastify';
import { responseErrorToString } from '../../../../utils/array';
import { processErrorFormik } from '../../../../utils/axios';
import { classList } from '../../../../utils/DOM/class';
import { setError } from '../../../../utils/formik';
import { getUserByToken, getUserInfoByInviteToken, login, updatePasswordByToken } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

const initialValues = {
  passwordReplace: "",
  password: "",
};

function ChangeEmailCallbackPage(props) {
  const location = useLocation() || {};
  const history = useHistory();
  const query = new URLSearchParams(location?.search);
  const authToken = useSelector(state => state?.auth?.authToken);
  const userIdCurrent = useSelector(state => state?.auth?.user?.id);

  const token = useMemo(() => {
    return query?.get('token');
  }, [query]);
  const error = useMemo(() => {
    return query?.get('error');
  }, [query]);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (token) {
      setLoadingCheck(true);
      setUser(null);
      getUserInfoByInviteToken(token)
      .then((data) => {
        setLoadingCheck(false);
        setUser(data);
        props.login(token);
      })
      .catch((error) => {
        setLoadingCheck(false);
        if (error?.response?.status === HTTP_CODE_422) {
          setErrorMessage(responseErrorToString(error.response.data.errors))
        } else if (!error.response) {
          setErrorMessage(error?.data?.message);
        } else {
          setErrorMessage(error?.response?.data?.message);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (authToken === token && user?.id === userIdCurrent && userIdCurrent) {
      history.push('/')
    }
  }, [authToken, token, user, userIdCurrent])

  const toHome = () => {
    history.push('/');
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div
            className="content order-1 order-lg-2 d-flex flex-column w-100 h-100 pb-0"
            style={{backgroundColor: '#B1DCED'}}
          >
            <div className="d-flex flex-column justify-content-center text-center pt-lg-24 pt-md-8 pt-sm-8 px-lg-0 pt-18 px-7">
              {
                loadingCheck &&
                (
                  <Card className="container max-w-800 pt-8 min-h-150px">
                    <p className="font-size-h2-md font-size-lg text-dark opacity-70">
                      <div className={classList("overlay", "overlay-block")}>
                        <div className="overlay-layer zindex-2 bg-transparent flex-wrap">
                          <p className="w-100">
                            Đang kiểm tra mã cung cấp...
                          </p>
                          <div className="spinner-border text-success" />
                        </div>
                      </div>
                    </p>
                  </Card>
                )
              }
              {
                ((!loadingCheck &&
                !user) || error) &&
                (
                  <Card className="container max-w-800">
                    <h3
                      className="display4 font-weight-normal my-7 text-dark"
                    >
                      {error ? "Lỗi" : "Mã không có hiệu lực"}
                    </h3>
                    <p className="font-size-h2-md font-size-lg text-danger opacity-70">
                      {errorMessage || error}
                    </p>
                    <p>
                      <a href="/" className="btn btn-primary font-size-h4 mt-3 mb-2">Trang chủ</a>
                    </p>
                  </Card>
                )
              }
              {
                !loadingCheck &&
                user &&
                !error &&
                (
                  <Card className="container max-w-800">
                    <h3
                      className="display4 font-weight-normal mt-7 mb-3 text-dark"
                    >
                      Yêu cầu đổi email thành công
                    </h3>
                    <p className="font-size-h2-md font-size-lg text-dark mb-10">
                      Email hiện tại của bạn là <b style={{color: green[500]}}>{user?.email}</b>
                      <br />
                    </p>
                    <div className="login-form login-signin m-auto text-left">
                      <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                          type="submit"
                          className={`btn btn-primary font-weight-bold px-9 py-4 my-3 m-auto`}
                          onClick={toHome}
                        >
                          <span>Trang chủ</span>
                        </button>
                      </div>
                    </div>
                  </Card>
                )
              }
            </div>

            <div
              className="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{backgroundImage: 'url(/media/bg/demo3.svg)'}}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, auth.actions)(ChangeEmailCallbackPage);
