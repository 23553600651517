export const CODE_SUCCESS = 0;
export const HTTP_CODE_422 = 422;

export const TRUE = "1";
export const FALSE = "0";
export const PERMISSION_VIEW = "view";

export const USER_STATUS = {
  PENDING: "PENDING",
  VERIFIED: "VERIFIED",
  INVALID: "INVALID",
  ACTIVE: "ACTIVE",
  DELETED: "DELETED"
};

export const TRANSACTION_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED"
};
