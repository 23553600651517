import axios from "axios";
import reactToastify from '../../../../plugin/react-toastify';
import { processError } from '../../../../utils/axios';
import { isString } from '../../../../utils/typeof';

export const LOGIN_URL = "/api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "/api/auth/me";
export const USER_INVITE_URL = "/api/user-info";
export const UPDATE_PASSWORD_URL = "/api/user/update-password";
export const LOGOUT_URL = "/api/auth/logout";

export function login(email, password) {
  return axios.post(LOGIN_URL, { 'username': email, 'password': password })
    .then((response) => {
      return response;
    }).catch(error => {
      console.log(error);
      return [401];
    })
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token, isNoToastify) {
  const authorization = "Bearer " + token;

  const config = {};
  if (token && isString(token)) {
    config.headers = { 'Authorization': authorization };
  }

  return axios.post(ME_URL, {}, config)
    .then(response => {
      if (response?.data?.code === 0) {
        return response?.data?.data;
      } else if (response?.data?.message) {
        if (!isNoToastify) {
          reactToastify.error(response?.data?.message);
        }
      } else {
        if (!isNoToastify) {
          reactToastify.error('Lỗi không lấy được thông tin người dùng');
        }
      }
      return Promise.reject(response);
    }).catch(error => {
      if (!isNoToastify) {
        processError(error);
      }
      return error;
    })
}

export function getUserInfoByInviteToken(token) {
  const authorization = "Bearer " + token;

  return axios.get(USER_INVITE_URL, { headers: { 'Authorization': authorization } })
    .then(response => {
      if (response?.data?.code === 0) {
        return response?.data?.data;
      }
      return Promise.reject(response);
    });
}

export function updatePasswordByToken(token, password) {
  const authorization = "Bearer " + token;

  return axios.post(UPDATE_PASSWORD_URL, { password: password }, { headers: { 'Authorization': authorization } })
    .then(response => {
      if (response?.data?.code === 0) {
        return response?.data?.data;
      }
      return Promise.reject(response);
    })
}
