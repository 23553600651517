import { yellow } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import React from "react";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Roboto"].join(",")
    },

    palette: createPalette({
      warning: {
        // light: will be calculated from palette.primary.main,
        main: yellow.A700,
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#69b3ff",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3783e7",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f44336",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    }),

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
