import React, {useState, useMemo} from 'react';
import {truncate} from '../../../../../../../utils/string';
import {isEmpty} from '../../../../../../../utils/typeof';
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";

const DescriptionColumnFormatter = (props) => {

    const intl = useIntl();
    const messages = intl?.messages;

    const {
        shortNumWord,
        content
    } = props;

    const [isShowShortContent, setShowShortContent] = useState(true);
    const contentShow = useMemo(() => {
        if (isShowShortContent) {
            return truncate(content, shortNumWord);
        } else {
            return content;
        }
    }, [content, shortNumWord, isShowShortContent])

    const isShortContentAble = useMemo(() => {
        return content !== truncate(content, shortNumWord);
    }, [content, shortNumWord]);

    const showMoreContent = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowShortContent(false);
    }

    const showShortContent = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowShortContent(true);
    }

    if (isEmpty(content)) {
        return null;
    }

    return (
        <div>
      <pre>
      {contentShow}
          {
              isShortContentAble && isShowShortContent &&
              <span> <a href="#" className="stretched-link" onClick={showMoreContent}>
                  {messages[keyMessages["LOAD_MORE"]]}</a></span>
          }

          {
              isShortContentAble && !isShowShortContent &&
              <span> <a href="#" className="stretched-link" onClick={showShortContent}>
                  {messages[keyMessages["COLLAPSE"]]}</a></span>
          }
      </pre>
        </div>
    );
};

export default DescriptionColumnFormatter;
