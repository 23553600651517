import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { keyMessages } from '../../../../../../_theme/i18n';
import { isEmpty } from '../../../../../../utils/typeof';
import FilterItem from './FilterItem';

const DataTableFilterPopper = (props) => {
  const intl = useIntl();
  const messages = intl?.messages;

  const {
    isOpenPopperFilter,
    anchorElPopper,
    closePopper,
    filterSelected,
    onSubmit
  } = props;

  const { setValues, values } = useFormikContext();
  const [valueItem, setValueItem] = useState(values?.[filterSelected?.name]?.value);

  const onChangeValue = value => {
    setValueItem(value);
  }

  const cancel = () => {
    setValueItem(null);
    closePopper();
  }

  const handleSubmit = () => {
    let newValues = { ...values }
    if (filterSelected) {
      const {
        name,
        label,
        type,
      } = filterSelected;
      if (valueItem) {
        newValues = {
          ...values,
          [name]: {
            name: name,
            label,
            type,
            value: valueItem
          }
        };
      } else {
        delete newValues[name]
      }
    }
    setValues(newValues);
    setTimeout(() => {
      onSubmit(newValues);
    });
  }

  useEffect(() => {
    if (filterSelected?.name) {
      setValueItem(values?.[filterSelected?.name]?.value)
    } else {
      setValueItem(null);
    }
  }, [filterSelected])

  if (isEmpty(filterSelected)) return null;

  const {
    name: filterName
  } = filterSelected;

  return (
    <Popper
      id="popper-content-filter"
      open={isOpenPopperFilter}
      anchorEl={anchorElPopper}
      placement="bottom-start"
      transition
      style={{ zIndex: 1, marginTop: "4px" }}
      modifiers={{
        offset: {
          enabled: true,
          offset: `0, 0`
        }
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            <Card className="pa-4 card-filter">
              <CardHeader
                title={messages[keyMessages['DATATABLE.TOOLBAR.FILTER']]}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#3699ff",
                  color: "#fff"
                }}
                classes={{
                  action: "card-action-center color-white"
                }}
                action={
                  <i
                    className="fas fa-times color-white pointer"
                    onClick={cancel}
                  />
                }
              />
              <CardContent className="py-3 mt-1">
                <FilterItem
                  key={`filter-item-${filterName}`}
                  item={filterSelected}
                  onChange={onChangeValue}
                  value={valueItem}
                />
              </CardContent>
              <CardActions className="justify-end py-0 mb-2">
                <Button
                  className="ml-auto"
                  size="large"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {messages[keyMessages['DATATABLE.TOOLBAR.APPLY']]}
                </Button>
              </CardActions>
            </Card>
          </div>
        </Fade>
      )}
    </Popper>
  );
};

export default DataTableFilterPopper;
