// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {debounce} from "lodash";
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import * as shortid from 'shortid';
import {
  getHandlerTableChange,
  getSelectRow,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../../../../_theme/_helpers";
import {Pagination} from "../../../../../../_theme/_partials/controls";
import {processError} from '../../../../../../utils/axios';
import {classList} from "../../../../../../utils/DOM/class";
import AnimateLoading from "../../../../../components/AnimateLoading";
import {usePermissionContext} from '../../../../../components/PermissionContext';
import {useDataTableContext} from "../DataTableContext";
import {useDataTableUIContext} from "../DataTableUIContext";
import * as uiHelpers from "../DataTableUIHelpers";
import {MobileColumnFormatter} from './column-formatters/MobileColumnFormatter';
import DataIndication from './DataIndication';
import DataTableAction from './DataTableAction';
import DataTableSettingColumn from './DataTableSettingColumn';

export function DataTableCustom() {
  const intl = useIntl();
  const messages = intl?.messages;

  // Products UI Context
  const dataTableUIContext = useDataTableUIContext();
  const dataTableContext = useDataTableContext();
  const permissionContext = usePermissionContext();
  const [tableId, setTableId] = React.useState(shortid.generate());
  const fetchId = useRef();
  const timeout = useRef();
  const isMobileScreen = useSelector(state => state.ui?.isMobileScreen);

  const dataTableProps = useMemo(() => {
    return {
      pageName: dataTableContext.pageName,
      state: dataTableContext.state,
      reducers: dataTableContext.reducers,
      action: dataTableContext.action,
      startListLoading: dataTableContext.startListLoading,
      endListLoading: dataTableContext.endListLoading,
      catchError: dataTableContext.catchError
    };
  }, [dataTableContext]);

  const dataTableUIProps = useMemo(() => {
    return {
      ids: dataTableUIContext.ids,
      isShowIndex: dataTableUIContext.isShowIndex,
      headers: dataTableUIContext.headers || [],
      isSelectRow: dataTableUIContext.isSelectRow,
      setIds: dataTableUIContext.setIds,
      queryParams: dataTableUIContext.queryParams,
      sizePerPageList: dataTableUIContext.sizePerPageList,
      setQueryParams: dataTableUIContext.setQueryParams,
      fetchData: dataTableUIContext.fetchData,
      clickEditItem: dataTableUIContext.clickEditItem,
      clickDeleteItem: dataTableUIContext.clickDeleteItem,
      clickViewDetailItem: dataTableUIContext.clickViewDetailItem
    };
  }, [dataTableUIContext]);

  const indexN = (cellContent, row, rowIndex, dataExtract) => {
    return <div>{row._index}</div>;
  };

  const { totalCount, entities, listLoading } = dataTableProps.state;
  const fetchDataFromServer = () => {
    fetchId.current = (fetchId.current || 0) + 1;
    const fetchIdCurr = fetchId.current;
    // clear selections list
    dataTableUIProps.setIds([]);
    // server call by queryParams
    dataTableProps.startListLoading();
    dataTableUIProps
      .fetchData(dataTableUIProps.queryParams)
      .then(response => {
        if (fetchIdCurr === fetchId.current) {
          const page = dataTableUIProps.queryParams?.page || 1;
          const pageSize = dataTableUIProps.queryParams?.page_size || 10;
          const { total, data } = response.data;
          dataTableProps.action(dataTableProps.reducers.entitiesFetched, {
            payload: { totalCount: total, entities: data, page, pageSize }
          });
        }
      })
      .catch(error => {
        if (fetchId === fetchId.current) {
          processError(error);
        }
      });
  };
  const debouncedFunction = useCallback(debounce(fetchDataFromServer, 300), [
    dataTableUIProps.queryParams
  ]);

  useEffect(() => {
    debouncedFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableUIProps.queryParams]);
  const [columns, setColumns] = React.useState([{
    dataField: "#",
    text: "#",
    headerStyle: (column, colIndex) => {
      return { minWidth: "50px", maxWidth: "50px" };
    },
    formatter: indexN
  }]);
  const pageName = dataTableProps.pageName;
  const permissionPages = permissionContext.permissionPages;
  const permissions = useMemo(() => {
    if (pageName) {
      return permissionPages[pageName] || [];
    }
    return [];
  }, [pageName, permissionPages])
  // Table columns
  useEffect(() => {
    let columnsNew = dataTableUIProps.headers.map(header => {
      if (header.dataField === "action") {
        let actions = header.actions || [];
        if (dataTableProps.pageName) {
          actions = actions.filter((action) => {
            const {
              actionName
            } = action;

            if (actionName) {
              return permissionContext.checkCanPage(dataTableProps.pageName, actionName);
            }
            return true;
          })
        }

        if (!actions || actions.length === 0) {
          return null;
        }

        return {
          ...header,
          formatter: (...props) => <DataTableAction {...props} actions={actions} />
        };
      }

      if (header.formatter) {
        // Thêm data vào các function formatter
        return {
          ...header,
          formatter: (cellContent, row, rowIndex, dataExtract) =>
            header.formatter(cellContent, row, rowIndex, {
              ...dataExtract,
              dataTableContext
            }),
          sortCaret: header.sort ? sortCaret : undefined
        };
      }

      return {
        ...header,
        sortCaret: header.sort ? sortCaret : undefined
      };
    }).filter(h => !!h);

    if (dataTableUIProps.isShowIndex) {
      columnsNew.unshift({
        dataField: "#",
        text: "#",
        headerStyle: (column, colIndex) => {
          return { minWidth: "62px", maxWidth: "62px" };
        },
        formatter: indexN
      });
    }

    if (isMobileScreen) {
      columnsNew = columnsNew.map(header => {
        header.formatterTemp = header.formatter;
        return {
          ...header,
          formatter: (cellContent, row, rowIndex, dataExtract) => {
            return (
              <MobileColumnFormatter
                formatter={header.formatterTemp}
                column={header}
                row={row}
                rowIndex={rowIndex}
                dataExtract={dataExtract}
              />
            )
          }
        }
      })
    }

    setColumns(columnsNew);
    setTableId(shortid.generate());
  }, [permissions, dataTableUIProps.isShowIndex, isMobileScreen]);

  const displayColumn = (idx, status) => {
    const columnSelected = columns[idx];
    setColumns([
      ...columns.slice(0, idx),
      {
        ...columnSelected,
        hidden: status
      },
      ...columns.slice(idx + 1),
    ])
    setTableId(shortid.generate());
  }

  const isExistColumnAction = columns.find(
    column => column.dataField === "action"
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    paginationSize: Math.ceil(
      totalCount / dataTableUIProps.queryParams.page_size
    ),
    columns: columns,
    sizePerPage: dataTableUIProps.queryParams.page_size,
    sizePerPageList: dataTableUIProps.sizePerPageList,
    pageSize: dataTableUIProps.queryParams.page_size,
    page: dataTableUIProps.queryParams.page
  };
  return (
    <>
      <PaginationProvider key={`pagination-provider-${tableId}`} pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <AnimateLoading isActive={listLoading} />
              <BootstrapTable
                wrapperClasses={classList(
                  isExistColumnAction && "v-data-table-with-action",
                  dataTableUIProps.isSelectRow && "v-data-table-select-row",
                  "table-responsive v-data-table-fixed-left"
                )}
                classes="table table-head-custom table-vertical-center table-bordered"
                bootstrap4
                bordered={false}
                noDataIndication={<DataIndication listLoading={listLoading} entities={entities} />}
                remote
                key={tableId}
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  dataTableUIProps.setQueryParams
                )}
                selectRow={dataTableUIProps.isSelectRow ? getSelectRow({
                  entities,
                  ids: dataTableUIProps.ids,
                  setIds: dataTableUIProps.setIds
                }) : undefined}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <DataTableSettingColumn columns={columns} displayColumn={displayColumn} />
    </>
  );
}
