import React, { useEffect, useMemo, useState } from 'react';
import {Tab, Tabs, Nav, Col, Row} from "react-bootstrap";
import * as shortid from "shortid";
import PropTypes from 'prop-types';
import { isEmpty } from '../../../utils/typeof';

const TabsCustom = (props) => {
  const {
    tabs,
    append,
    idActive=0
  } = props;
  const [activeKey, setActiveKey] = React.useState(tabs[idActive]?.key);

  useEffect(() => {
    if (tabs[idActive]?.key !== activeKey) {
      setActiveKey(tabs[idActive]?.key);
    }
  }, [idActive]);

  const idTab = useMemo(() => {
    return shortid.generate();
  }, []);

  if (isEmpty(tabs)) return null;

  const onSelectTab = (eventKey, e) => {
    setActiveKey(eventKey);
  }

  return (
    <Tabs className="mx-0 my-0 w-100 border-bottom" activeKey={activeKey} onSelect={onSelectTab} transition={false} defaultActiveKey={tabs[idActive]?.key} id={idTab}>
      {
        tabs.map((tab, index) => {
          const {
            id,
            key,
            title,
            content
          } = tab;
          return (
            <Tab key={`tab-item-${idTab}-${index}-${id}`} eventKey={key} title={title}>
              {content}
            </Tab>
          )
        })
      }
      {
        append
      }
    </Tabs>
  );
};

TabsCustom.propTypes = {};

TabsCustom.defaultProps = {};

export default TabsCustom;
