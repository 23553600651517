/* eslint-disable no-restricted-imports */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { useIntl } from 'react-intl';
import {useSelector} from "react-redux";
import objectPath from "object-path";
import { keyMessages } from '../../../i18n';
import {useHtmlClassService} from "../../_core/ThemeLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import axios from "axios";
import {USER_SYSTEM_RESOURCE} from "../../../../constants/system/resource";
import {toAbsoluteUrl} from "../../../_helpers";

export function QuickUserToggler() {
  const [userProfile, setUserProfile] = useState({});
  const intl = useIntl();
  const messages = intl?.messages;
  useEffect(() => {
    axios.get(`${USER_SYSTEM_RESOURCE}/profile`)
      .then(res => {
        setUserProfile(res.data.data);
      })
      .catch(err => console.log(err));
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (<>
      {layoutProps.offcanvas && (<OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="quick-user-tooltip">{messages[keyMessages['TOPBAR.USER.TOOLTIP']]}</Tooltip>}
      >
        <div className="topbar-item">
          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
               id="kt_quick_user_toggle">
            <>
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"/>
              <img src={toAbsoluteUrl(userProfile?.avatar || "/media/users/default.jpg")} className="symbol-label" style={{
                borderRadius: '50%',
                height: "25px",
                width:"25px"
              }}/>
              <span className="symbol symbol-35 symbol-light-success ml-3">
                    <span className="text-dark font-weight-bold ">{userProfile?.full_name}</span>
                </span>
            </>
          </div>
        </div>
      </OverlayTrigger>)}

      {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
    </>
  );
}
