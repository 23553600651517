import React, { useState } from "react";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import { useIntl } from 'react-intl';
import { keyMessages } from '../../../../../../_theme/i18n';
import FilterItem from "./FilterItem";
import ToolBar from "./ToolBar";

export function DataTableFilterMobile(props) {
  const {
    filters,
    submitFilter
  } = props;
  const intl = useIntl();
  const messages = intl?.messages;

  const [searchText, setSearchText] = useState("");

  const onChangeTextSearch = (e) => {
    setSearchText(e.target.value);
  }

  const applyFilter = (values) => {
    submitFilter({
      searchText,
      filters: values
    })
  }

  return (
    <div className="d-block d-md-none px-4 py-3">
      <Formik
        initialValues={{}}
        onSubmit={(values, formikHelpers) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          setValues
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="row mx-0">
              <div className="d-block w-full">
                {filters.map((item, index) => {
                  const {
                    label,
                    type,
                    name: filterName
                  } = item;
                  return (
                    <div
                      key={`filter-wrapper-${index}`}
                      className="py-1 px-1 col-sm-12 mb-2"
                    >
                      <FilterItem
                        item={item}
                        isClearable
                        onChange={value => {
                          if (value) {
                            setValues({
                              ...values,
                              [filterName]: {
                                name: filterName,
                                label,
                                type,
                                value: value
                              }
                            });
                          } else {
                            const newValues = { ...values };
                            delete newValues[filterName];
                            setValues(filterName);
                          }
                        }}
                        onBlur={handleBlur}
                        value={values[filterName]?.value}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className="d-inline-flex align-items-center px-1 py-1 py-md-0 w-100"
                style={{ marginLeft: "auto" }}
              >
                <div className="input-icon w-100">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={messages[keyMessages['DATATABLE.TOOLBAR.SEARCH']]}
                    value={searchText}
                    onChange={onChangeTextSearch}
                  />
                  <span>
                      <i className="flaticon2-search-1 text-muted" />
                    </span>
                </div>
              </div>
              <div
                className="d-flex align-items-center px-1 py-1 py-md-0 w-100 mt-2"
              >
                <Button
                  className="w-100"
                  size="large"
                  variant="primary"
                  onClick={handleSubmit}
                >
                  {messages[keyMessages['DATATABLE.TOOLBAR.APPLY']]}
                </Button>
              </div>
              <ToolBar />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
