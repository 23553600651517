// Icon in /public/media/svg/icons

import {
  ADD,
  APPROVE,
  ASSIGN_PERMISSION, CONSULT,
  CREATE_ACCOUNT,
  DELETE, DETAIL,
  DISABLE_ACCOUNT,
  EDIT, ENROLLMENT_TEST, EXPORT,
  IMPORT, PAY_FEE, REGISTER, RESULT_EXAM,
  VIEW
} from './permission';

export const ASIDE_MENU = [
  {
    title: "Dashboard",
    group: "apps",
    icon: "Design/Layers",
    href: "/dashboard",
    name: "dashboard",
    actions: [VIEW]
  },
  {
    title: "Quản trị",
    group: "admin",
    icon: "General/User",
    href: "/admin",
    name: "admin",
    items: [
      {
        title: "Quản lý người dùng",
        href: "/admin/user",
        name: "user",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Quản lý vai trò",
        href: "/admin/role",
        name: "role",
        actions: [VIEW, ADD, DELETE, EDIT, ASSIGN_PERMISSION]
      },
      {
        title: "Quản lý quyền",
        href: "/admin/permission",
        name: "permission",
        actions: [VIEW, ADD, DELETE, EDIT]
      }
    ]
  },
  {
    title: "Quản lý học sinh",
    group: "student",
    icon: "General/User-graduate",
    href: "/student",
    name: "student",
    items: [
      {
        title: "Dữ liệu thô",
        href: "/student/raw",
        name: "raw",
        actions: [VIEW, ADD, DELETE, EDIT, IMPORT]
      },
      {
        title: "Ứng viên tiềm năng",
        href: "/student/candidate",
        name: "candidate",
        actions: [VIEW, ADD, DELETE, EDIT, CONSULT, RESULT_EXAM, ENROLLMENT_TEST, REGISTER]
      },
      {
        title: "Học sinh đang theo học",
        href: "/student/studying",
        name: "studying",
        actions: [VIEW, PAY_FEE]
      },
      {
        title: "Học sinh cũ",
        href: "/student/old",
        name: "old",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Học sinh nợ học phí",
        href: "/student/lack-tuition-fees",
        name: "lack-tuition-fees",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Nguồn dữ liệu",
        href: "/student/data-source",
        name: "data-source",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Danh sách file",
        href: "/student/file-import",
        name: "file-import",
        actions: [VIEW, ADD, DELETE, EDIT, EXPORT]
      }
    ]
  },
  {
    title: "Quản lý lớp học",
    group: "class",
    icon: "General/Class",
    href: "/class",
    name: "class",
    items: [
      {
        title: "Danh sách lớp học",
        href: "/class/list",
        name: "list",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Buổi học cần duyệt",
        href: "/class/lesson-checkin",
        name: "lesson-checkin",
        actions: [VIEW, APPROVE]
      }
    ]
  },
  {
    title: "Quản lý khóa học",
    group: "course",
    icon: "General/Course",
    href: "/course",
    name: "course",
    items: [
      {
        title: "Danh sách khóa học",
        href: "/course/list",
        name: "list",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Danh sách đề thi đầu vào",
        href: "/course/exam-input",
        name: "exam-input",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Danh sách điểm thi đầu vào",
        href: "/course/result-exam-input",
        name: "result-exam-input",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Kỹ năng",
        href: "/course/skill",
        name: "skill",
        actions: [VIEW, ADD, DELETE, EDIT]
      }
    ]
  },
  {
    title: "Quản lý giáo viên",
    group: "course",
    icon: "General/User",
    href: "/teacher",
    name: "teacher",
    items: [
      {
        title: "Danh sách Giáo viên",
        href: "/teacher/list",
        name: "list",
        actions: [VIEW, ADD, DELETE, EDIT, CREATE_ACCOUNT, DISABLE_ACCOUNT]
      },
      {
        title: "Lịch dạy",
        href: "/teacher/schedule",
        name: "schedule",
        actions: [VIEW, ADD, DELETE, EDIT]
      },
      {
        title: "Thống kê công",
        href: "/teacher/statistic-checkin",
        name: "statistic-checkin",
        actions: [VIEW, DETAIL]
      },
      {
        title: "Lớp được phân công",
        href: "/teacher/class",
        name: "class",
        actions: [VIEW, DETAIL]
      }
    ]
  },
  {
    title: "Quản lý cơ sở",
    group: "location-branch",
    icon: "General/Archway",
    href: "/location-branch/list",
    name: "location-branch-list",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Chương trình khuyến mại",
    group: "manage-promotion",
    icon: "Shopping/Ticket",
    href: "/manage-promotion/list",
    name: "manage-promotion",
    actions: [VIEW, ADD, DELETE, EDIT]
  },
  {
    title: "Cài đặt hệ thống",
    group: "system",
    icon: "General/Cog",
    href: "/system",
    name: "system",
    items: [
      {
        name: "notification-pattern",
        title: "Định dạng thông báo",
        href: "/system/notification-pattern",
        actions: [VIEW, ADD, DELETE, EDIT]
      }
    ]
  },
];
