import { deepPurple, grey } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { Field } from 'formik';
import React, { useState, useEffect, useRef } from "react";
import {Modal} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { SelectionCheckbox } from '../../../../../../../_theme/_helpers';
import { Select } from '../../../../../../../_theme/_partials/controls';
import { CODE_SUCCESS } from '../../../../../../../constants/system/code';
import { TEMPLATE_EMAIL } from '../../../../../../../constants/system/dataSelect';
import { CLASS_SCHEDULE_RESOURCE } from '../../../../../../../constants/system/resource';
import reactToastify from '../../../../../../../plugin/react-toastify';
import { processError } from '../../../../../../../utils/axios';
import * as requestFromServer from '../../../../../common/DataTable/_redux/entitiesCrud';

export function MailDetailForm({item, onHide}) {
  return (
    <div>
      <Modal.Body>
        {
          item?.title &&
          (<div className="form-group row mx-0">
            <div className="col-xs-12">
              <span style={{ fontWeight: 500 }}>
                Tiêu đề:&nbsp;
              </span>
              <span>
                {item?.title}
              </span>
            </div>
          </div>)
        }

        {
          item?.title &&
          (<div className="form-group row mx-0">
            <div className="col-xs-12">
              <span style={{ fontWeight: 500 }}>
                Sender:&nbsp;
              </span>
              <span>
                {(item?.sender || []).join(", ")}
              </span>
            </div>
          </div>)
        }
        {
          item?.title &&
          (<div className="form-group row mx-0">
            <div className="col-xs-12">
              <span style={{ fontWeight: 500 }}>
                To:&nbsp;
              </span>
              <span>
                {(item?.receiver || []).join(", ")}
              </span>
            </div>
          </div>)
        }
        {
          item?.title &&
          (<div className="form-group row mx-0">
            <div className="col-xs-12">
              <span style={{ fontWeight: 500 }}>
                CC:&nbsp;
              </span>
              <span>
                {(item?.cc || []).join(", ")}
              </span>
            </div>
          </div>)
        }
        {
          item?.title &&
          (<div className="form-group row mx-0">
            <div className="col-xs-12">
              <span style={{ fontWeight: 500 }}>
                BCC:&nbsp;
              </span>
              <span>
                {(item?.bcc || []).join(", ")}
              </span>
            </div>
          </div>)
        }
        <div className="form-group row mx-0">
          <div className="col-xs-12">
            <span style={{ fontWeight: 500 }}>
              Trạng thái:&nbsp;
            </span>
            <span>
              {item?.status}
            </span>
          </div>
        </div>
        {
          item?.error_message &&
          (<div className="form-group row mx-0">
            <div className="col-xs-12">
              <span style={{ fontWeight: 500 }}>
                Lỗi:&nbsp;
              </span>
              <span>
                {item?.error_message}
              </span>
            </div>
          </div>)
        }
        <div className="form-group row mx-0">
          <div className="col-xs-12">
            <span style={{ fontWeight: 500 }}>
              Loại email:&nbsp;
            </span>
            <span>
              {item?.type}
            </span>
          </div>
        </div>
        <div className="form-group row mx-0 w-100">
          <div className="col-xs-12 w-100">
            {
              item?.content &&
              (<>
                <p style={{ fontWeight: 500 }}>
                  Nội dung email
                </p>
                <div style={{
                  border: `1px solid ${grey[200]}`,
                  padding: '1rem',
                }}>
                  <div dangerouslySetInnerHTML={{__html: item?.content}}/>
                </div>
              </>)
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Đóng
        </button>
      </Modal.Footer>
    </div>
  );
}
