import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../../utils/DOM/class';

import './style.scss';

const FullScreenWrapper = React.forwardRef((props, ref) => {
  const {
    children,
    isFullScreen
  } = props;

  return (
    <span className={classList('full-screen-wrapper', isFullScreen && 'full-screen')}>
      {children}
    </span>
  );
});

FullScreenWrapper.propTypes = {};

FullScreenWrapper.defaultProps = {};

export default FullScreenWrapper;
