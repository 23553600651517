import React, {useState} from 'react';
import {
  CLASS_RESOURCE,
  EXAMINATION_RESOURCE,
  LOCATION_BRANCH_RESOURCE,
  PROGRAM_RESOURCE
} from "../../../../../../../constants/system/resource";
import {DataTableProvider} from "../../../../../common/DataTable/pages/DataTableContext";
import {DataTableDeleteDialog} from '../../../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog';
import {LoadingDialog} from "../../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {EditDialog} from "../../../../../common/DataTable/pages/edit-dialog/EditDialog";
import {DataTableCard} from "../../../../../common/DataTable/pages/DataTableCard";
import {DataTableUIProvider} from "../../../../../common/DataTable/pages/DataTableUIContext";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import {SELECT, SELECT_SOURCE, TEXT} from "../../../../../../../constants/filterType";
import {COUNTRY} from "../../../../../../../constants/country";
import {ExamEditForm} from "./ExamEditForm";
import {ExamResultEditForm} from "./ExamResultEditForm";
import {DateTimeFormatter} from "../../../../../common/DataTable/pages/table/column-formatters/DateTimeColumnFormatter";
import {BadgeColumnFormatter} from "../../../../../common/DataTable/pages/table/column-formatters/BadgeColumnFormatter";
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";

const ListExamOfClass = (props) => {

  const intl = useIntl();
  const messages = intl?.messages;

  const {
    classId
  } = props;

  const [students, setStudents] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [showDialogDelete, setDialogDelete] = useState(false);
  const [showDialogForm, setDialogForm] = useState(false);
  const [showDialogExamResult, setShowDialogExamResult] = useState(false);
  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };
  const openExamResult = (data) => {
    setSelectedData(data);
    setShowDialogExamResult(true);
  }
  const closeExamResult = () => {
    setSelectedData(null);
    setShowDialogExamResult(false);
  }

  const openDialogDelete = () => setDialogDelete(true);
  const closeDialogDelete = () => {
    setSelectedData(null);
    setDialogDelete(false);
  };

  const uiEvents = {
    clickNewItem: () => {
      setSelectedData(null);
      openDialogForm(true);
    },
    clickEditItem: dataEdit => {
      openDialogForm();

      dataEdit = {
        ...dataEdit,
        lesson: {
          id: dataEdit.class_schedule_id,
          start_time: dataEdit.start_time
        }
      };

      setSelectedData(dataEdit);
    },
    clickViewDetailItem: data => {

    },
    clickDeleteItem: dataDelete => {
      openDialogDelete();
      setSelectedData(dataDelete);
    },
    clickViewExamResult: data => {
      openExamResult();
      setSelectedData(data);
    },
    fetchData: query => {
      const url = CLASS_RESOURCE + `/${classId}/examination`;
      return requestFromServer.findEntities(url, query);
    },
    newData: data => {
      return requestFromServer.createEntity(EXAMINATION_RESOURCE, data);
    },
    editData: data => {
      return requestFromServer.updateEntity(EXAMINATION_RESOURCE, data);
    },
    deleteData: id => {
      return requestFromServer.deleteEntity(EXAMINATION_RESOURCE, id);
    }
  };

  const headers = [
    {
      dataField: "name",
      text: messages[keyMessages["TEACHER.CLASS.EXAM.NAME"]],
      sort: true
    },
    {
      dataField: "class_schedule.start_time",
      text: messages[keyMessages["TEACHER.CLASS.EXAM.DATE"]],
      sort: true,
      formatter: (cellContent) => {
        return DateTimeFormatter(cellContent, "HH:mm DD/MM/YYYY")
      },
    },
    {
      dataField: "teacher.name",
      text: messages[keyMessages["TEACHER.CLASS.EXAM.TEACHER"]]
    },
    {
      dataField: "skills",
      text: messages[keyMessages["TEACHER.CLASS.EXAM.SKILL"]],
      formatter: (
        cellContent,
        row,
        rowIndex,
        { openLockDialog, openEditDialog }
      ) => {
        return <BadgeColumnFormatter data={cellContent} field="name" />;
      },
    },

    {
      dataField: "action",
      text: "Action",
      headerStyle: (column, colIndex) => {
        return {width: "120px", textAlign: "right"};
      },
      actions: [
        {
          color: "primary",
          icon: "spellcheck",
          label: messages[keyMessages["TEACHER.CLASS.EXAM.MARK"]],
          event: uiEvents.clickViewExamResult
        },
        {
          color: "primary",
          icon: "edit",
          label: messages[keyMessages["DATATABLE.COLUMN.EDIT"]],
          event: uiEvents.clickEditItem
        },
        {
          color: "error",
          icon: "delete",
          label: messages[keyMessages["DATATABLE.COLUMN.DELETE"]],
          event: uiEvents.clickDeleteItem
        }
      ]
    }
  ];

  const filters = [
    {
      type: SELECT_SOURCE,
      label: "Chi nhánh",
      name: "location_branch_id",
      resource: LOCATION_BRANCH_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
    {
      type: TEXT,
      label: "Sĩ số",
      name: "num_student"
    },
    {
      type: SELECT,
      label: "Trạng thái",
      name: "status",
      options: COUNTRY,
      propertyValue: "value",
      propertyName: "label"
    },
    {
      type: SELECT_SOURCE,
      label: "Khóa học",
      name: "program_id",
      resource: PROGRAM_RESOURCE,
      propertyName: "name",
      propertyValue: "id",
    },
  ];

  const initData = {
    id: null,
    name: "",
    teacher: "",
    skills: "",
    lesson: "",
    type: "unit",
    num_question: 1,
    file_url: null
  };

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      initData={initData}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <EditDialog
          size={"xl"}
          id={selectedData && selectedData.id}
          show={showDialogForm}
          onHide={closeDialogForm}
          item={{
            ...selectedData,
            input_url: selectedData?.media?.join("\n")
          }}
          title={
            selectedData
              ? `Sửa bài kiểm tra '${selectedData && selectedData.name}'`
              : "Tạo mới bài kiểm tra"
          }
          ComponentForm={ExamEditForm}
        />
        <EditDialog
          size={"lg"}
          id={selectedData && selectedData.id}
          show={showDialogExamResult}
          onHide={closeExamResult}
          item={selectedData}
          title={messages[keyMessages["TEACHER.EXAM_FORM.MARK_TEST"]]}
          ComponentForm={ExamResultEditForm}
        />
        <DataTableDeleteDialog
          id={selectedData && selectedData.id}
          show={showDialogDelete}
          onHide={closeDialogDelete}
          title="Xóa bài kiểm tra"
          content={
            <div>
              Bạn có muốn xóa bài kiểm tra&nbsp;
              <span className="text-danger">
                {selectedData && selectedData.name}
              </span>
            </div>
          }
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
}

ListExamOfClass.propTypes = {};

ListExamOfClass.defaultProps = {};

export default ListExamOfClass;
