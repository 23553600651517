import { Formik, useFormikContext } from "formik";
import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { isEmpty } from '../../../../../../utils/typeof';
import DataTableFilterDetail from "./DataTableFilterDetail";
import DataTableFilterPopper from "./DataTableFilterPopper";
import DataTableFilterShort from "./DataTableFilterShort";

export function FilterDesktopContent(props) {
  const {
    filters,
    valueFilter,
    queryFilter,
    isOpenDetailFilter,
    searchText,
    isOpenPopperFilter,
    anchorElPopper,

    openDetailFilter,
    setAnchorElPopper,
    setFilterSelected,
    openPopperFilter,
    onChangeTextSearch,
    closeDetailFilter,
    resetFilter,
    closePopperFilter,
    filterSelected,
    applyFilter,

  } = props;

  const { values, handleSubmit } = useFormikContext();

  useEffect(() => {
    applyFilter(values || {});
  }, [values])

  return (
    <form onSubmit={handleSubmit} className="form form-label-right">
      <DataTableFilterShort
        filters={filters}
        valueFilter={valueFilter}
        queryFilter={queryFilter}
        isOpenDetailFilter={isOpenDetailFilter}
        searchText={searchText}
        openDetailFilter={openDetailFilter}
        setAnchorElPopper={setAnchorElPopper}
        setFilterSelected={setFilterSelected}
        openPopperFilter={openPopperFilter}
        onChangeTextSearch={onChangeTextSearch}
      />
      <DataTableFilterDetail
        filters={filters}
        valueFilter={valueFilter}
        queryFilter={queryFilter}
        isOpenDetailFilter={isOpenDetailFilter}
        closeDetailFilter={closeDetailFilter}
        setAnchorElPopper={setAnchorElPopper}
        setFilterSelected={setFilterSelected}
        openPopperFilter={openPopperFilter}
        resetFilter={resetFilter}
        applyFilter={applyFilter}
      />
      <DataTableFilterPopper
        isOpenPopperFilter={isOpenPopperFilter}
        anchorElPopper={anchorElPopper}
        closePopper={closePopperFilter}
        filterSelected={filterSelected}
        onSubmit={applyFilter}
      />
    </form>
  );
}

export function DataTableFilterDesktop(props) {
  const {
    filters,
    queryFilter = [],
    submitFilter
  } = props;

  const [searchText, setSearchText] = useState("");
  const [isOpenDetailFilter, setOpenDetailFilter] = useState(false);
  const [filterSelected, setFilterSelected] = useState({});
  const [valueFilter, setValueFilter] = useState({});
  const refTimeoutSubmitFilter = useRef(0);
  const [anchorElPopper, setAnchorElPopper] = React.useState(null);
  const [isOpenPopperFilter, setOpenPopperFilter] = React.useState(false);
  const openDetailFilter = () => setOpenDetailFilter(true);
  const closeDetailFilter = () => setOpenDetailFilter(false);

  const openPopperFilter = () => setOpenPopperFilter(true);
  const closePopperFilter = () => {
    setOpenPopperFilter(false);
    setFilterSelected(null);
  }

  const onChangeTextSearch = e => {
    setSearchText(e.target.value);
  };

  const applyFilter = (values) => {
    setValueFilter(values);
    closePopperFilter();
  };

  const resetFilter = () => {
    setValueFilter({});
  };

  useEffect(() => {
    clearTimeout(refTimeoutSubmitFilter.current);
    refTimeoutSubmitFilter.current = setTimeout(() => {
      submitFilter({
        searchText,
        filters: valueFilter
      });
    }, 300);
  }, [searchText, valueFilter])

  useEffect(() => {
    if (isEmpty(valueFilter) || isEmpty(queryFilter)) {
      closeDetailFilter();
    }
  }, [queryFilter, valueFilter])

  return (
    <div className="d-none d-md-block px-4 py-3 mb-1">
      <Formik
        initialValues={{}}
        onSubmit={(values, formikHelpers) => {
          applyFilter(values);
        }}
      >
        <FilterDesktopContent
          filters={filters}
          valueFilter={valueFilter}
          queryFilter={queryFilter}
          isOpenDetailFilter={isOpenDetailFilter}
          searchText={searchText}
          isOpenPopperFilter={isOpenPopperFilter}
          anchorElPopper={anchorElPopper}
          filterSelected={filterSelected}

          openDetailFilter={openDetailFilter}
          setAnchorElPopper={setAnchorElPopper}
          setFilterSelected={setFilterSelected}
          openPopperFilter={openPopperFilter}
          onChangeTextSearch={onChangeTextSearch}
          closeDetailFilter={closeDetailFilter}
          resetFilter={resetFilter}
          closePopperFilter={closePopperFilter}
          applyFilter={applyFilter}
        />
      </Formik>
    </div>
  );
}
