import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilterDefault, sizePerPageListDefault } from "./DataTableUIHelpers";

const DataTableUIContext = createContext();

export function useDataTableUIContext() {
  return useContext(DataTableUIContext);
}

export const DataTableUIConsumer = DataTableUIContext.Consumer;

export function DataTableUIProvider(props) {
  const { dataTableUIEvents, headers, filters = [], toolbars = [], children, initData, buttons, isShowIndex = true, isSelectRow = false, initialFilter = initialFilterDefault, sizePerPageList = sizePerPageListDefault } = props;
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [isOpenSettingColumns, setOpenSettingColumns] = useState(false);
  const [ids, setIds] = useState([]);

  const reloadData = () => {
    dataTableUIEvents.fetchData(queryParams);
  }

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    toolbars,
    queryParams,
    sizePerPageList,
    setQueryParamsBase,
    ids,
    setIds,
    initData,
    setQueryParams,
    headers,
    filters,
    buttons,
    isOpenSettingColumns,
    isShowIndex,
    isSelectRow,

    //Call event button click
    setOpenSettingColumns: setOpenSettingColumns,
    dataTableUIEvents,
    clickNewItem: dataTableUIEvents.clickNewItem,
    clickEditItem: dataTableUIEvents.clickEditItem,
    clickDeleteItem: dataTableUIEvents.clickDeleteItem,
    clickImportData: dataTableUIEvents.clickImportData,
    clickExportData: dataTableUIEvents.clickExportData,
    clickViewDetailItem: dataTableUIEvents.clickViewDetailItem,

    //Call data event
    reloadData,
    fetchData: dataTableUIEvents.fetchData,
    getDetail: dataTableUIEvents.getDetail,
    newData: dataTableUIEvents.newData,
    editData: dataTableUIEvents.editData,
    deleteData: dataTableUIEvents.deleteData,
    signUp2Learn: dataTableUIEvents.signUp2Learn,
  };

  return (
    <DataTableUIContext.Provider value={value}>
      {children}
    </DataTableUIContext.Provider>
  );
}
