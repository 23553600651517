import React from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {keyMessages} from "../../../../../_theme/i18n";
import {toDateSrt, toTimeSrt} from "../../../../../utils/filter";
import {Button} from "@material-ui/core";

export function ScheduleShortView({editable, data, overlay, messages}) {

    const isSendMail = () => {
        return data.is_notification_email_to_teacher !== 0;
    }
    return <OverlayTrigger overlay={overlay} placement="auto"
                           trigger={['hover', 'focus']}>
        <div className="fc-content" style={isSendMail() ? {} : {backgroundColor: "#ddd"}}>
            <span className="fc-time d-block w-100">From: {toTimeSrt(data.start_time)}
                <br/>
                To: {toTimeSrt(data.end_time)}</span>
            <div>
                <b>
                    {messages[keyMessages['SIGN_UP.FORM.CLASS.NAME']]}:&nbsp;
                </b>
                {data?.class?.name || ''}
            </div>
            <div className="text-truncate">
                <b>
                    {messages[keyMessages['LOCATION_BRANCH']]}:&nbsp;
                </b>
                {data?.location_branch?.name || ''}
            </div>
            <div className="text-truncate">
                <b>
                    {messages[keyMessages['TEACHER']]}:&nbsp;
                </b>
                {data?.teacher?.name || ''}
            </div>

        </div>
    </OverlayTrigger>
}


export const createProverItem = (data, messages) => {
    return <Popover
        id={`popper-id-${data?.id}`}
        style={{width: '300px'}}
    >
        <Popover.Title
            as="h3">{messages[keyMessages['TEACHER.SCHEDULE.TITLE_TEACHING_SCHEDULE']]}</Popover.Title>
        <Popover.Content>
            <div>
                <b>
                    {messages[keyMessages['TEACHER.SCHEDULE.TIME']]}:&nbsp;
                </b>
                {toTimeSrt(data.start_time)} - {toTimeSrt(data.end_time)} {toDateSrt(data.start_time)}
            </div>
            <div>
                <b>
                    {messages[keyMessages['SIGN_UP.FORM.CLASS.NAME']]}:&nbsp;
                </b>
                {data?.class?.name || '-'}
            </div>
            <div>
                <b>
                    {messages[keyMessages['LOCATION_BRANCH']]}:&nbsp;
                </b>
                {data?.location_branch?.name || '-'}
            </div>
            <div>
                <b>
                    {messages[keyMessages['ROOM.NAME']]}:&nbsp;
                </b>
                {data?.room?.name || '-'}
            </div>
            <div>
                <b>
                    {messages[keyMessages['TEACHER']]}:&nbsp;
                </b>
                {data?.teacher?.name || '-'}
            </div>
            <div>
                <b>
                    {messages[keyMessages['LESSON.CONTENT']]}:&nbsp;
                </b>
                {data.lesson_content || '-'}
            </div>

        </Popover.Content>
    </Popover>;
}
