import React, {Suspense} from "react";
import {ContentRoute, LayoutSplashScreen} from "../../../_theme/layout";
import {Redirect, Switch} from "react-router-dom";
import ListTeacherPage from "../Teacher/pages/list/ListTeacherPage";
import ScheduleTeacher from "../Teacher/pages/schedule";
import UserProfile from "./UserProfile";

export default function UserPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      <Switch>
        {
          /* Redirect from Admin root URL to /customers */
          <Redirect
            exact={true}
            from="/user"
            to="/user/profile"
          />
        }
        <ContentRoute path="/user/profile" component={UserProfile}/>
        <Redirect to="/error/error-404"/>
      </Switch>
    </Suspense>
  );
}