import React, {useEffect, useMemo, useState} from 'react';
import {
  ADVISORY_RESOURCE,
  CLASS_RESOURCE,
  CLASS_STUDENT_RESOURCE,
  USER_SYSTEM_RESOURCE, TEACHER_UPDATE_STUDENT_RESOURCE
} from "../../../../../../../constants/system/resource";
import {processHeaderTable} from '../../../../../../../utils/table';
import {isEmpty} from '../../../../../../../utils/typeof';
import { post } from '../../../../../common/DataTable/_redux/entitiesCrud';
import {DataTableProvider} from "../../../../../common/DataTable/pages/DataTableContext";
import {DataTableDeleteDialog} from '../../../../../common/DataTable/pages/delete-dialog/DataTableDeleteDialog';
import {LoadingDialog} from "../../../../../common/DataTable/pages/loading-dialog/LoadingDialog";
import {EditDialog} from "../../../../../common/DataTable/pages/edit-dialog/EditDialog";
import {DataTableCard} from "../../../../../common/DataTable/pages/DataTableCard";
import {DataTableUIProvider} from "../../../../../common/DataTable/pages/DataTableUIContext";
import {Link} from "react-router-dom";
import * as columnFormatters from "../../../../../common/DataTable/pages/table/column-formatters";
import {PriceColumnFormatter} from "../../../../../common/DataTable/pages/table/column-formatters";
import * as requestFromServer from "../../../../../common/DataTable/_redux/entitiesCrud";
import moment from "moment";
import {MMDDYYYY} from "../../../../../../../constants/system/dateformat";
import $ from "jquery";
import DescriptionColumnFormatter
  from '../../../../../common/DataTable/pages/table/column-formatters/DescriptionColumnFormatter';
import {SignUpLearnForm} from '../../../../../Student/pages/candidate/SignUpLearnForm';
import { StudentEditNameESForm } from '../../../../../Student/pages/candidate/StudentEditNameESForm';
import {ConfirmSendEmailDialog} from './ConfirmSendEmailDialog';
import {StudentPayFeeForm} from "./StudentPayFeeForm";
import {FormDialog} from "../../../../../common/DataTable/pages/form-dialog/FormDialog";
import {PickStudent2GrowUpClassForm} from "./PickStudent2GrowUpClassForm";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {keyMessages} from "../../../../../../../_theme/i18n";
import {AdvisoryForm} from "../../../../../Student/pages/candidate/AdvisoryForm";
import {useIntl} from "react-intl";
import {CONSULT} from "../../../../../../../constants/system/permission";

const ListStudentViewOfClass = (props) => {

  const intl = useIntl();
  const messages = intl?.messages;
  const {
    actions,
    renderCell,
    classId,
    classInformation
  } = props;

  const [selectedData, setSelectedData] = useState();
  const [showDialogForm, setDialogForm] = useState(false);

  const openDialogForm = () => setDialogForm(true);
  const closeDialogForm = () => {
    setSelectedData(null);
    setDialogForm(false);
  };

  const updateNameInfoStudent = ({ data }) => {
    return requestFromServer.post(TEACHER_UPDATE_STUDENT_RESOURCE, '', {
      id: data?.id,
      english_name: data?.english_name,
    });
  }

  const uiEvents = {
    clickEditItem: dataEdit => {
      openDialogForm();
      console.log(dataEdit)
      setSelectedData({
        id: dataEdit?.student?.id,
        name: dataEdit?.student?.name,
        english_name: dataEdit?.student?.english_name,
        email: dataEdit?.student?.email,
        phone: dataEdit?.student?.phone,
      });
    },
    fetchData: query => {
      const url = CLASS_RESOURCE + `/${classId}/student`;
      return requestFromServer.findEntities(url, query);
    },
    editData: data => {
      return requestFromServer.updateEntity(CLASS_STUDENT_RESOURCE, data);
    },
  };

  let headers = useMemo(() => {
    let headersRaw = [
      {
        dataField: "student.name",
        text: messages[keyMessages['USER_CANDIDATE.STUDENT']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "250px" };
        },
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div className="d-flex align-items-center" style={{"width": "200px"}}>
              <div className="symbol symbol-40 symbol-sm flex-shrink-0">
                <img src={row?.student?.avatar_url || '/media/users/blank.png'} className="border rounded-circle" alt={`avatar-of-${cellContent}`} />
              </div>
              <div className="ml-4">
                <span className="text-dark-75 font-weight-bolder font-size-lg mb-0">
                  {cellContent}
                </span>
              </div>
            </div>
          );
        }
      },
      {
        dataField: "student.english_name",
        text: messages[keyMessages['USER_CANDIDATE.FULL_NAME_ENGLISH']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "200px" };
        },
      },
      {
        dataField: "student.birthday",
        text: messages[keyMessages['USER_CANDIDATE.BIRTHDAY']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "150px" };
        },
        formatter: columnFormatters.DateTimeColumnFormatter
      },
      {
        dataField: "student.email",
        text: messages[keyMessages['USER_CANDIDATE.EMAIL']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "250px" };
        },
      },
      {
        dataField: "student.phone",
        text: messages[keyMessages['USER_CANDIDATE.PHONE']],
        headerStyle: (column, colIndex) => {
          return { minWidth: "150px" };
        },
      },
      {
        dataField: "action",
        text: messages[keyMessages['DATATABLE.COLUMN.ACTION']],
        headerStyle: (column, colIndex) => {
          return {width: "120px", textAlign: "right"};
        },
        actions: [
          {
            color: "primary",
            icon: "edit",
            label: messages[keyMessages['DATATABLE.COLUMN.EDIT']],
            event: uiEvents.clickEditItem
          }
        ]
      }
    ];

    headersRaw = processHeaderTable(headersRaw, {
      actions,
      renderCell
    })

    return headersRaw;
  }, [actions, renderCell])

  useEffect(() => {
    if (classId) {
      // loadStudents();

      $(".tab-content").addClass("col-md-12")
    }
  }, [classId])

  return (
    <DataTableUIProvider
      headers={headers}
      dataTableUIEvents={uiEvents}
      isSelectRow
      initialFilter={{
        filters: [],
        sorts: [],
        page: 1,
        page_size: -1
      }}
      sizePerPageList={[
        { text: "Tất cả", value: -1 },
      ]}
    >
      <DataTableProvider>
        <LoadingDialog/>
        <FormDialog
            show={showDialogForm}
            size="md"
            title={messages[keyMessages['USER_CANDIDATE.FORM.TITLE_EDIT']]?.create(selectedData)}
            messageSuccess={messages[keyMessages['USER_CANDIDATE.FORM.MESSAGE_UPDATE_SUCCESS']]}
            item={selectedData}
            onHide={closeDialogForm}
            onSubmit={updateNameInfoStudent}
            ComponentForm={StudentEditNameESForm}
        />
        <DataTableCard/>
      </DataTableProvider>
    </DataTableUIProvider>
  );
}

ListStudentViewOfClass.propTypes = {};

ListStudentViewOfClass.defaultProps = {};

export default ListStudentViewOfClass;
