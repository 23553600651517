import { CircularProgress } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import { AccountCircle } from "@material-ui/icons";
import React, { useMemo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { keyMessages } from '../../../_theme/i18n';
import { CODE_SUCCESS } from '../../../constants/system/code';
import { CLASS_RESOURCE } from '../../../constants/system/resource';
import { Admin, PROGRAM_DESIGNER, STAFF } from '../../../constants/system/role';
import reactToastify from '../../../plugin/react-toastify';
import { processErrorFormik } from '../../../utils/axios';
import { classList } from "../../../utils/DOM/class";
import { toEnglish } from "../../../utils/filter";
import * as requestFromServer from '../../modules/common/DataTable/_redux/entitiesCrud';
import { LoadingDialog } from '../../modules/common/DataTable/pages/loading-dialog/LoadingDialog';

import { usePermissionContext } from "../PermissionContext";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

export default function InputSearchClass(props) {
  const intl = useIntl();
  const messages = intl?.messages;
  const history = useHistory();
  const indexRef = React.useRef(0);
  const perfectScrollbarRef = React.useRef();
  const [textSearch, setTextSearch] = useState("");
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const fetchData = (keyword) => {
    const query = {
      filters: [],
      keyword,
      page: 1,
      page_size: 20,
      sorts: []
    }
    return requestFromServer.findEntities(CLASS_RESOURCE, query);
  }

  const scrollToTop = () => {
    const _ps = perfectScrollbarRef.current?._ps;
    if (_ps) {
      if (_ps.element) {
        _ps.element.scrollTop = 0;
        _ps.update();
      }
    }
  };

  const getClassesByTextSearch = async (text) => {
    try {
      indexRef.current++;
      const indexCurrent = indexRef.current;
      setLoading(true);
      const response = await fetchData(text);
      if (indexCurrent === indexRef.current) {
        setLoading(false);
        if (response.data?.code === CODE_SUCCESS) {
          const { total, data } = response.data;
          setItems(data);
          scrollToTop();
        } else {
          reactToastify.error(response.data?.message);
        }
      }
    } catch (error) {
      setLoading(false);
      reactToastify.error(error.response.data?.message)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      return getClassesByTextSearch(textSearch)
    }, 300);
    return () => {
      clearTimeout(timeout);
    }
  }, [textSearch]);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <Paper className={classList(classes.root, "my-1")}>
        <InputBase
          ref={anchorRef}
          value={textSearch}
          onChange={e => {
            setTextSearch(e.target.value);
            setOpen(true);
          }}
          className={classes.input}
          placeholder={messages[keyMessages['TOPBAR.SEARCH_CLASS.PLACEHOLDER']]}
          inputProps={{ "aria-label": messages[keyMessages['TOPBAR.SEARCH_CLASS.PLACEHOLDER']] }}
        />
        <IconButton
          className={classList(classes.iconButton, "px-1 py-1")}
          aria-label="search"
          disabled
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <Popper
        open={open}
        className={"zindex-2"}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => {
          return(
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "start top" : "start bottom"
              }}
            >
              <Paper className="min-w-300">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList onKeyDown={handleListKeyDown}>
                    <PerfectScrollbar
                      ref={perfectScrollbarRef}
                      options={perfectScrollbarOptions}
                      className="scroll"
                      style={{ maxHeight: "20rem", position: "relative" }}
                    >
                      {
                        isLoading && (
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="overlay-layer zindex-2 bg-transparent min-h-130px d-flex align-items-center justify-content-center m-auto">
                              <div className="spinner-border text-success" />
                            </div>
                          </div>
                        )
                      }
                      {
                        !isLoading && items.length === 0 && (
                          <MenuItem disabled>
                            {messages[keyMessages['TOPBAR.SEARCH_CLASS.NOT_FOUND']]}
                          </MenuItem>
                        )
                      }
                      {!isLoading && items.length > 0 && items.map((item, index) => {
                        return (
                          <MenuItem
                            key={`menu-search-${index}`}
                            onClick={e => {
                              handleClose(e);
                              setTextSearch(item?.name);
                              history.push(`/class/${item.id}`);
                            }}
                          >
                            {item?.name}
                            {
                              item?.location_branch?.name && (<Chip className="ml-2" label={item?.location_branch?.name} size="small"/>)
                            }
                          </MenuItem>
                        );
                      })}
                    </PerfectScrollbar>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }}
      </Popper>
    </>
  );
}
