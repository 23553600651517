import { toast } from "react-toastify";

const reactToastify = {
  error: (content, options = {}) => {
    console.log(content);
    toast.error(content, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options
    });
  },
  tooManyAction: function() {
    this.error("Thực hiện quá nhiều hành động, vui lòng thử lại sau ít phút.");
  },
  noPermission: function() {
    this.error("Bạn không có quyền thực hiện hành động này.");
  },
  actionFail: function() {
    this.error("Thực hiện thất bại, vui lòng thử lại sau.");
  },
  addSuccess: function(item) {
    this.success(`Thêm mới${item ? ` ${item}` : ''} thành công.`);
  },
  editSuccess: function(item) {
    this.success(`Cập nhật${item ? ` ${item}` : ''} thành công.`);
  },
  deleteSuccess: function(item) {
    this.success(`Xóa${item ? ` ${item}` : ''} thành công.`);
  },
  info: (content, options = {}) => {
    toast.info(content, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options
    });
  },
  success: (content, options = {}) => {
    toast.success(content, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options
    });
  },
  warn: (content, options = {}) => {
    toast.warn(content, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options
    });
  },
  default: (content, options = {}) => {
    toast(content, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options
    });
  }
};

export default reactToastify;
