export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const USER_NAME_REGEX = /^[A-Za-z][A-Za-z0-9_.@]{0,}[A-Za-z0-9]$/;
export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const URL_IMAGE_REGEX = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|png|gif|webp|psd|raw)/i;
export const URL_VIDEO_REGEX = /(http(s?):)([/|.|\w|\s|-])*\.(?:mp4|m4p|m4v|3gp|avi|webm|mov)/i;
export const URL_AUDIO_REGEX = /(http(s?):)([/|.|\w|\s|-])*\.(?:mp3|wma|wav|flac|aac|ogg|aif)/i;
export const PASSWORD_REGEX = /(?=.*[a-z])+(?=.*[A-Z])+(?=.*\d)+(?=.*[$~!#^()@$!%*?&])[A-Za-z\d$~!#^()@$!%*?&]{8,}/;
export const PERMISSION_REGEX = /^[^&|]*$/;
export const PHONE_REGEX = /^(0)+([0-9]{9})/;
export const DECIMAL_TYPING = /[\d]+\.[0]{0,}$/;
