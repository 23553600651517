export const VIEW = "view";
export const ADD = "add";
export const EDIT = "edit";
export const DELETE = "delete";
export const SORT = "sort";
export const IMPORT = "import";
export const CONSULT = "consult";
export const EDIT_CONSULT = "edit-consult";
export const RESULT_EXAM = "result-exam";
export const RESULT_EXAM_INPUT = "result-exam-input";
export const RESULT_EXAM_LESSON = "result-exam-lesson";
export const ENROLLMENT_TEST = "enrollment-test";
export const REGISTER = "register";
export const ASSIGNMENT = "assignment";
export const SETTING_SCHEDULE = "setting-schedule";
export const TO_CANDIDATE = "to-candidate";
export const REGISTER_TRY = "register-try";
export const EXPORT = "export";
export const ASSIGN_PERMISSION = "assign-permission";
export const ASSIGN_COURSE_SKILL = "assign-course-skill";
export const CREATE_ACCOUNT = "create-account";
export const DISABLE_ACCOUNT = "disable-account";
export const APPROVE = "approve";
export const DETAIL = "detail";
export const PAY_FEE = "pay_fee";
