import axios from "axios";
import * as requestFromServer from "./entitiesCrud";
import {UPLOAD_RESOURCE} from "../../../../../constants/system/resource";

export const dowloadFile = (url) => {
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.headers["content-disposition"].split("=")[1]);
    document.body.appendChild(link);
    link.click();
  }).catch(err => console.log(err));
};

export const uploadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return await requestFromServer.post(UPLOAD_RESOURCE, "file", formData);
}