import React from "react";
import moment from "moment";
import {DDMMYYYY} from "../../../../../../../constants/system/dateformat";

export const DateTimeColumnFormatter = (longTime, row) => {
  const convertLong2DateTime = (longTime) => {
    return longTime ? moment(new Date(longTime)).format(DDMMYYYY) : "";
  }

  return (
    <span>
      {
        convertLong2DateTime(longTime)
      }
    </span>
  );
}

export const DateTimeFormatter = (longTime, formatPattern) => {
  formatPattern = formatPattern !== undefined ? formatPattern : DDMMYYYY

  const convertLong2DateTime = (longTime) => {
    return longTime ? moment(new Date(longTime)).format(formatPattern) : "";
  }

  return (
    <span>
      {
        convertLong2DateTime(longTime)
      }
    </span>
  );
}

export const DateTimeFormatterNoneHtml = (longTime, formatPattern) => {
  formatPattern = formatPattern !== undefined ? formatPattern : DDMMYYYY

  const convertLong2DateTime = (longTime) => {
    return longTime ? moment(new Date(longTime)).format(formatPattern) : "";
  }

  return (
    convertLong2DateTime(longTime)
  );
}

