import Tooltip from '@material-ui/core/Tooltip';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty } from '../../../../../../../utils/typeof';
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../../../_theme/i18n";

const ExamStudent = (props) => {

  const intl = useIntl();
  const messages = intl?.messages;

  const {
    index,
    isReadOnly,
    skills = [],
    studentResult,
    onUploadFileResult,
    onRemoveFileResult,
    onChangeResult
  } = props;
  const studentId = studentResult?.student?.id;
  let inputFile = React.createRef();

  const total = skills?.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(studentResult[currentValue.code] || 0);
  }, 0) || 0;

  const totalQuestion = skills?.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.num_question || 0);
  }, 0) || 0;

  const openUploadFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  }

  const uploadFile = (e) => {
    onUploadFileResult(studentId, e)
  }

  const removeFile = (e) => {
    onRemoveFileResult(studentId, e)
  }

  const onChangeNote = (e) => {
    onChangeResult(studentId, e, "note")
  }

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{studentResult?.student?.name}</td>
      <td>{studentResult?.student?.english_name}</td>
      {
        !isEmpty(skills) &&
        skills.map((s, indexSkill) => {
          return (
            <td key={`skill-${studentId}-${s?.id}-${indexSkill}`} className="text-right">
              {
                isReadOnly &&
                (
                  <label
                    className="m-1"
                    style={{width: "36px", border: "1px solid lightgray"}}
                  >
                    {studentResult[s.code]}
                  </label>
                )
              }
              {
                !isReadOnly &&
                (
                  <input
                    className="m-1"
                    style={{width: "36px", border: "1px solid lightgray"}}
                    value={studentResult[s.code] || ""}
                    onChange={(e) => {
                      onChangeResult(studentId, e, s.code)
                    }}
                  />
                )
              }
              <span className="font-size-lg">/ {s.num_question}</span>
            </td>
          );
        })
      }
      <td>
        {
          isReadOnly &&
          (
            <label className="w-100 border-lightgray">
              {studentResult?.note}
            </label>
          )
        }
        {
          !isReadOnly &&
          (
            <textarea
              rows={2}
              className="w-100 border-lightgray"
              value={studentResult?.note || ""}
              onChange={onChangeNote}
            />
          )
        }
      </td>
      <td className="text-right">
        {total}/{totalQuestion}
      </td>
      <td className="text-right">
        {
          studentResult.mediaUploading &&
          <div className="loading-wrapper">
            <Spinner animation="border" variant="primary" size="sm" />
          </div>
        }
        {
          !studentResult.mediaUploading &&
          !isEmpty(studentResult.media) &&
          <div>
            <Tooltip title="Download attachments">
              <div className="d-inline-block pointer px-1">
                <a href={studentResult.media} target="_blank">{messages[keyMessages["TEACHER.EXAM_FORM.ATTACH"]]}</a>
              </div>
            </Tooltip>
            {
              !isReadOnly &&
              (
                <Tooltip title="Xóa file đính kèm">
                  <span
                    className="m-1 btn-link cursor-pointer"
                    onClick={removeFile}
                  >
                    <BackspaceIcon fontSize="small" color="error"/>
                  </span>
                </Tooltip>
              )
            }
          </div>
        }
        {
          !studentResult.mediaUploading &&
          !isReadOnly &&
          isEmpty(studentResult.media) &&
          <Tooltip title="Tải lên file đính kèm">
            <div className="d-inline-block pointer px-1">
              <input
                ref={inputFile}
                type="file"
                style={{display: "none"}}
                onChange={uploadFile}
              />
              <span onClick={openUploadFile}><CloudUploadIcon/></span>
            </div>
          </Tooltip>
        }
      </td>
    </tr>
  );
};

export default ExamStudent;
