import React, {useMemo} from "react";
import {Modal} from "react-bootstrap";
import {Formik} from "formik";
import {useSelector} from 'react-redux';
import * as Yup from "yup";
import {Admin, PROGRAM_DESIGNER} from '../../../../../constants/system/role';
import {classList} from "../../../../../utils/DOM/class";
import {usePermissionContext} from '../../../../components/PermissionContext';
import {useIntl} from "react-intl";
import {keyMessages} from "../../../../../_theme/i18n";
import axios from "axios";
import reactToastify from "../../../../../plugin/react-toastify";
import {processError} from "../../../../../utils/axios";

// Validation schema
const EditSchema = Yup.object().shape({
    class: Yup.object()
        .nullable()
        .required("Lớp là bắt buộc"),
    location_branch: Yup.object()
        .nullable(),
    room: Yup.object()
        .nullable()
});

export function EmailSendingForm({save, item, actionsLoading, onHide}) {
    const intl = useIntl();
    const messages = intl?.messages;
    const roles = useSelector(state => state.auth?.user?.roles || []);
    const permissionContext = usePermissionContext();
    const isNotProgramDesigner = useMemo(() => {
        return !permissionContext.checkRole([PROGRAM_DESIGNER, Admin])
    }, [roles])

    const sendWeekEmail = () => {
        axios.post(`/api/teacher/notify-email-week-schedule`, {
            "id": 0,
            "start_time": item[0].value,
            "end_time": item[1].value,
        }).then(rs => {
            if (rs?.data?.code === 0) {
                reactToastify.success("Gửi mail thành công !!!");
                onHide()
            } else {
                reactToastify.actionFail();
            }
        }).catch(e => {
            processError(e)
        })
    }

    const generateConfirmBody = () => {
        return <div>Lịch học tuần này bắt đầu từ <b>{convertLongToDate(item[0].value)}</b> đến <b>{convertLongToDate(item[1].value)}</b>.
            Bạn có chắc chắn muốn gửi mail ?</div>

    }

    const convertLongToDate = (time) => {
        const d = new Date(time);
        return d.getDate()  + "/" + (d.getMonth()+1) + "/" + d.getFullYear();
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={item}
                validationSchema={EditSchema}
                onSubmit={(value, formik) => {
                    save(value, formik);
                }}
            >
                {({handleSubmit}) => (
                    <>
                        <Modal.Body className={classList("overlay", actionsLoading && "overlay-block")}>
                            {generateConfirmBody()}
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                {messages[keyMessages["FORM.CANCEL"]]}
                            </button>
                            <> </>
                            {!isNotProgramDesigner && <button
                                type="submit"
                                disabled={actionsLoading}
                                onClick={() => sendWeekEmail()}
                                className="btn btn-danger btn-elevate"
                            >
                                Send Email
                            </button>}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}
