/* eslint-disable no-unused-vars */
import { Divider } from '@material-ui/core';
import axios from 'axios';
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useRef } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip, NavDropdown } from "react-bootstrap";
import { useIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as requestFromServer from '../../../../../app/modules/common/DataTable/_redux/entitiesCrud';
import { onMessageListener } from '../../../../../app/modules/Firebase/firebaseInit';
import { NOTIFICATION_RESOURCE } from '../../../../../constants/system/resource';
import { NOTI_NOT_READ_YET, NOTI_NOT_VIEWED_YET, NOTI_READ } from '../../../../../constants/system/status';
import reactToastify from '../../../../../plugin/react-toastify';
import { getUnique } from '../../../../../utils/array';
import { classList } from '../../../../../utils/DOM/class';
import { toArray, toFromNow, toTimestamp } from '../../../../../utils/filter';
import { convertMessageToHTML } from '../../../../../utils/string';
import { isArray, isFunction } from '../../../../../utils/typeof';
import { keyMessages } from '../../../../i18n';
import { useHtmlClassService } from "../../../_core/ThemeLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const intl = useIntl();
  const messagesLang = intl?.messages;
  const refDropdown = useRef();
  const [key, setKey] = useState("Events");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const [isOpenDialog, setOpenDialog] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [requesting, setRequesting] = React.useState(false);
  const [page, setNumPage] = React.useState(1);
  const [numUnread, setNumUnread] = React.useState(0);
  const [numUnView, setNumUnView] = React.useState(0);
  const [nextNumUnView, setNextNumUnView] = React.useState(0);
  const [loadMoreAble, setLoadMoreAble] = React.useState(true);

  React.useEffect(() => {
    fetchData(1);
  }, []);

  const onClickNotification = (item, index) => {
    if (isOpenDialog && refDropdown.current) {
      refDropdown.current.click();
    }

    if ([NOTI_NOT_VIEWED_YET, NOTI_NOT_READ_YET].includes(item.status)) {
      requestFromServer.post(NOTIFICATION_RESOURCE, 'click', {
        id: item?.id
      }).then(res => {
        if (res?.status === 200) {
          setNumUnread(numUnread - 1);
          setMessages([
            ...messages.slice(0, index),
            {
              ...item,
              status: NOTI_READ
            },
            ...messages.slice(index + 1),
          ])
        }
      }).catch(e => {
        console.log(e);
      });
    }
  }

  const fetchData = (pageLoad = page) => {
    if (requesting || !loadMoreAble || ((pageLoad !== 1) && !isOpenDialog)) {
      return false;
    }
    setRequesting(true);
    requestFromServer.get(NOTIFICATION_RESOURCE, 'all', {
      page: pageLoad,
      page_size: 15
    }).then(res => {
      setRequesting(false);
      if (res?.status === 200 && res?.data?.code === 0) {
        const {
          load_more_able,
          num_un_view,
          num_unread,
          page,
          data
        } = res?.data?.data || {};
        if (pageLoad === 1) {
          setMessages(data || []);
        } else {
          setMessages(getUnique([
            ...messages,
            ...(data || [])
          ], 'id'));
        }
        setNumUnView(num_un_view);
        setNextNumUnView(num_un_view);
        setNumUnread(num_unread);
        setNumPage(parseInt(page, 10) + 1);
        setLoadMoreAble(load_more_able);
      } else {
        reactToastify.error(res?.data?.message)
      }
    }).catch(e => {
      setRequesting(false);
      reactToastify.error('Lỗi không lấy được danh sách thông báo')
    });
  };

  const viewAllNoti = () => {
    requestFromServer.post(NOTIFICATION_RESOURCE, 'mark-as-viewed').then(res => {
      if (res?.status === 200) {
        setNextNumUnView(0);
      }
    }).catch(e => {
      setNextNumUnView(0);
      console.log(e);
    });
  }

  if (isFunction(onMessageListener)) {
    onMessageListener()
      .then(payload => {
        console.log(payload);
        const {
          data,
          notification
        } = payload;

        const {
          title,
          body
        } = notification || {};
        const {
          id,
          redirect,
          icon,
          messages: content,
          status,
          created_at
        } = data || {};
        const newContent = toArray(content);
        if (isArray(newContent)) {
          setMessages([
            {
              ...data,
              messages: newContent
            },
            ...messages
          ])
          setNumUnView(numUnView + 1);
          setNextNumUnView(nextNumUnView + 1);
          reactToastify.default(
            <div className="d-flex flex-column font-weight-bold px-3">
              <a href={redirect} className="navi-item">
                <div className="navi-link d-flex align-items-center">
                  <div className="navi-icon mr-4">
                    <img
                      style={{
                        width: '2rem',
                        height: '2rem',
                        objectFit: 'contain'
                      }}
                      className="text-primary"
                      src={icon || '/media/svg/icons/General/Notification2.svg'}
                    />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold text-secondary">
                      {title}
                    </div>
                    <div className="text-muted">{body}</div>
                  </div>
                </div>
              </a>
            </div>
            , {
              position: "bottom-right"
            })
        }
      })
      .catch(err => {
        console.log(JSON.stringify(err));
      });
  }

  const onYReachEndPage = () => {
    if (isOpenDialog) {
      fetchData();
    }
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const onToggleDropdown = (isOpen, event, metadata) => {
    setOpenDialog(isOpen);
    if (isOpen) {
      viewAllNoti();
    } else {
      if (numUnView !== nextNumUnView) {
        setNumUnView(nextNumUnView);
      }
    }
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className={classList("btn btn-icon btn-clean btn-lg mr-1", numUnView > 0 && "pulse pulse-primary")}
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          onToggle={onToggleDropdown}
          drop="down"
          alignRight
        >
          <Dropdown.Toggle
            ref={refDropdown}
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  {messagesLang[keyMessages['TOPBAR.NOTIFICATION.TITLE']]}
                </Tooltip>
              }
            >
              <div
                className={classList("btn btn-icon btn-clean btn-lg mr-1", numUnView > 0 && "pulse pulse-primary")}
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")}
                  />
                </span>
                <span className="pulse-ring" />
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
            {
              numUnView > 0 &&
              (
                <span className="btn btn-text btn-danger notification-mark btn-sm font-weight-bold btn-font-md ml-2">
                        {numUnView > 99 ? '99+' : numUnView}
                      </span>
              )
            }
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
          >
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-4 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top pb-2">
                  <span className="text-white">{messagesLang[keyMessages['TOPBAR.NOTIFICATION.TITLE']]}</span>
                </h4>

                <Tab.Container defaultActiveKey={key} className="px-0">
                  <Tab.Content className="tab-content">
                    <Tab.Pane
                      eventKey="Events"
                      id="topbar_notifications_events"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll"
                        style={{ maxHeight: "300px", position: "relative" }}
                        onYReachEnd={onYReachEndPage}
                      >
                        {
                          !requesting &&
                          messages.length === 0 &&
                          (
                            <div className="d-flex flex-center text-center text-muted min-h-200px">
                              {messagesLang[keyMessages['TOPBAR.NOTIFICATION.EMPTY']]}
                            </div>
                          )
                        }
                        {
                          messages.map((message, index) => {
                            const {
                              id,
                              redirect,
                              icon,
                              messages: content,
                              status,
                              created_at
                            } = message || {};
                            const contentNotification = (content || []).map(message => convertMessageToHTML(message)).join(' ');
                            return (
                              <Link
                                key={`notification-${index}-${id}`}
                                to={redirect}
                                className={classList('navi-item', [NOTI_NOT_VIEWED_YET, NOTI_NOT_READ_YET].includes(status) && 'bg-light-primary')}
                                onClick={(e) => onClickNotification(message, index)}
                              >
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <img
                                      style={{
                                        width: '2rem',
                                        height: '2rem',
                                        objectFit: 'contain'
                                      }}
                                      className="text-primary"
                                      src={icon || '/media/svg/icons/General/Notification2.svg'}
                                    />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: contentNotification }} />
                                    <div className="text-muted">{toFromNow(created_at)}</div>
                                  </div>
                                </div>
                                <Divider light />
                              </Link>
                            )
                          })
                        }
                        {requesting && (
                          <div className="overlay-layer zindex-2 bg-transparent d-flex justify-content-center">
                            <div className="spinner-border text-success" />
                          </div>
                        )}
                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                      <div className="d-flex flex-center text-center text-muted min-h-200px">
                        All caught up!
                        <br />
                        No new notifications.
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
