import React from "react";
import { Link } from 'react-router-dom';
import TableListClass from '../table';

const TeacherListClassPage = ({history}) => {
  const renderCell = {
    name: (cellContent, row) => {
      return(
        <Link to={"/teacher-class/" + row.id}>{cellContent}</Link>
      )
    }
  }
  return <TableListClass actions={[]} renderCell={renderCell}/>
};

TeacherListClassPage.propTypes = {};

TeacherListClassPage.defaultProps = {};

export default TeacherListClassPage;
