import { grey } from '@material-ui/core/colors';
import { Formik, Field, useFormikContext } from "formik";
import { Modal, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Table from "react-bootstrap/Table";
import { SelectionCheckbox } from '../../../../../../../_theme/_helpers';
import { Input, Select } from '../../../../../../../_theme/_partials/controls';
import { union, remove } from 'lodash';
import React, { useEffect, useState } from "react";
import { CODE_SUCCESS } from '../../../../../../../constants/system/code';
import { SEX_SELECT, TEMPLATE_EMAIL } from '../../../../../../../constants/system/dataSelect';
import { CLASS_RESOURCE, CLASS_SCHEDULE_RESOURCE } from '../../../../../../../constants/system/resource';
import reactToastify from '../../../../../../../plugin/react-toastify';
import { classList } from '../../../../../../../utils/DOM/class';
import { isFunction } from '../../../../../../../utils/typeof';
import * as requestFromServer from '../../../../../common/DataTable/_redux/entitiesCrud';
import { useDataTableContext } from '../../../../../common/DataTable/pages/DataTableContext';

const editSchemaMail = Yup.object().shape({
  mail_type: Yup.string()
    .nullable()
    .required("Bạn chưa chọn mẫu email"),
  parent_emails: Yup.array()
    .nullable()
    .min(1, 'Bạn chưa chọn phụ huynh gửi email')
    .required('Bạn chưa chọn phụ huynh gửi email'),
});

const FormContent = (props) => {
  const formikContext = useFormikContext();
  const {
    values,
    errors,
    submitForm,
    setFieldValue,
    setErrors,
    setFieldTouched
  } = formikContext;

  const {
    parents
  } = props;

  const [mailPreview, setMailPreview] = useState(null);
  const [emailParentsSelect, setEmailParentsSelect] = useState([]);

  useEffect(() => {
    if (values?.mail_type) {
      requestFromServer.post(
        CLASS_SCHEDULE_RESOURCE,
        'mail-preview',
        {
          "lesson_id": values?.lesson_id,
          "mail_type": values?.mail_type
        }
      ).then(rs => {
        if (rs.data?.code === CODE_SUCCESS) {
          setMailPreview(rs.data?.data?.[0]);
        } else {
          reactToastify.error(rs.data.message)
        }
      })
    }
  }, [values?.mail_type])

  const selectAllParent = (e) => {
    if (e.target.checked) {
      setEmailParentsSelect(parents.map(parent => parent?.email))
    } else {
      setEmailParentsSelect([])
    }
  }

  const selectParent = (email, status) => {
    if (status) {
      setEmailParentsSelect(union([
        ...emailParentsSelect,
        email
      ]))
    } else {
      setEmailParentsSelect(emailParentsSelect.filter(item => item !== email))
    }
  }

  useEffect(() => {
    setFieldValue('parent_emails', emailParentsSelect, false);
  }, [emailParentsSelect])

  return (
    <div className="form-group row">
      <div className="col-xs-12 col-md-7">
        {
          errors.parent_emails &&
          <div
            style={{
              width: '100%',
              fontSize: '0.9rem',
              color: '#F64E60'
            }}
          >
            {errors.parent_emails}
          </div>
        }
        <div className="overflow-auto border-secondary border" style={{maxHeight: "550px"}}>
          <Table bordered hover className="table-sticky-local">
            <thead>
            <tr>
              <th style={{ minWidth: '60px', maxWidth: '60px' }}>
                <SelectionCheckbox
                  style={{ display: 'flex', justifyContent: 'center' }}
                  onChange={selectAllParent}
                  isSelected={emailParentsSelect?.length === parents?.length}
                />
              </th>
              <th style={{ minWidth: '170px', maxWidth: '170px' }}>Tên phụ huynh</th>
              <th style={{ minWidth: '170px', maxWidth: '170px' }}>Email</th>
              <th style={{ minWidth: '170px', maxWidth: '170px' }}>Học sinh phụ trách</th>
            </tr>
            </thead>
            <tbody>
            {
              parents.map((parent, index) => {
                return (
                  <tr key={`parent-${index}`}>
                    <td style={{ minWidth: '60px', maxWidth: '60px' }}>
                      <SelectionCheckbox
                        style={{ display: 'flex', justifyContent: 'center' }}
                        onChange={e => {
                          selectParent(parent?.email, e.target.checked);
                        }}
                        isSelected={emailParentsSelect.indexOf(parent?.email) !== -1}
                      />
                    </td>
                    <td>
                      {parent?.name}
                    </td>
                    <td>
                      {parent?.email}
                    </td>
                    <td>
                      {
                        parent.students.map((student, studentIndex) => {
                          if (index === parent.students?.length - 1) {
                            return <Link
                              key={`parent-${index}-student-${studentIndex}`}
                              to={`/student/${student?.student_id}`}
                              target="_blank"
                            >
                              {student?.student_name}
                            </Link>
                          } else {
                            return <Link
                              key={`parent-${index}-student-${studentIndex}`}
                              to={`/student/${student?.student_id}`}
                              target="_blank"
                            >
                              {student?.student_name},&nbsp;
                            </Link>
                          }
                        })
                      }
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="col-xs-12 col-md-5">
        <div className="form-group row">
          <div className="col-xs-12 col-md-12">
            <Field
              name="mail_type"
              component={Select}
              placeholder="Chọn mẫu email"
              label="Mẫu Email"
              require
              options={TEMPLATE_EMAIL}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-xs-12 col-md-12">
            {
              mailPreview?.content &&
              (<>
                <p style={{ fontWeight: 500 }}>
                  Email xem trước mẫu
                </p>
                <div style={{
                  border: `1px solid ${grey[200]}`,
                  padding: '1rem',
                  maxHeight: '500px',
                  overflowY: 'auto'
                }}>
                  <div dangerouslySetInnerHTML={{__html: mailPreview?.content}}/>
                </div>
              </>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export function SendMailScheduleOfClass({save, classId, item, actionsLoading, onHide, onSuccess}) {
  const dataTableContext = useDataTableContext();
  const [parents, setParents] = useState([]);

  const fetchData = () => {
    const url = CLASS_RESOURCE + `/${classId}/student`;
    requestFromServer.findEntities(url, {
      page: 1,
      page_size: -1
    }).then(rs => {
      if (rs.data?.code === CODE_SUCCESS) {
        const parentNews = [];

        (rs.data?.data || []).forEach(student => {
          if (student.father_email) {
            const fatherCurrent = parentNews.find(parent => {
              return parent.email === student.father_email;
            })

            if (fatherCurrent && parentNews.students) {
              fatherCurrent.students.push(student);
            } else {
              parentNews.push({
                name: student.father_name,
                email: student.father_email,
                phone: student.father_phone,
                students: [student]
              });
            }
          }

          if (student.mother_email) {
            const motherCurrent = parentNews.find(parent => {
              return parent.email === student.mother_email;
            })

            if (motherCurrent) {
              motherCurrent.students.push(student);
            } else {
              parentNews.push({
                name: student.mother_name,
                email: student.mother_email,
                phone: student.mother_phone,
                students: [student]
              });
            }
          }
        })

        setParents(parentNews);
      }
    });
  }

  useEffect(() => {
    if (classId) {
      fetchData();
    }
  }, [classId])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item}
        validationSchema={editSchemaMail}
        onSubmit={(values, formik) => {

          save(values, formik).then(() => {
            if (isFunction(dataTableContext?.reloadPage)) {
              dataTableContext.reloadPage();
            }
          });
        }}
      >
        {({handleSubmit}) => (
          <>
            <Modal.Body className={classList("overlay")}>
              <FormContent parents={parents}/>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Hủy
              </button>
              <> </>
              <button
                type="submit"
                disabled={actionsLoading}
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
              >
                {actionsLoading ? "Đang gửi..." : "Gửi email"}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
